export const getRootElement = selector => {
  if (document.querySelector(selector)) {
    return document.querySelector(selector);
  }
  const rootEl = document.createElement('div');
  if (selector.startsWith('#')) {
    rootEl.id = selector;
  }
  if (selector.startsWith('.')) {
    rootEl.classList.add(selector);
  }
  document.body.appendChild(rootEl);
  return rootEl;
};

export const getQueryParam = key => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const isValidWebsite = url => {
  // eslint-disable-next-line max-len
  const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  return regex.test(url);
};
