import styled from 'styled-components';
import ScHeaderContainer from './ScHeaderContainer';

const ScHeader = styled.div`
  pointer-events: none; // for ssr landings(handle broken submenu before hydration)
  position: ${props => props.menuStyles.headerPosition || 'relative'};
  font-family: Circular, -apple-system, BlinkMacSystemFont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell, "Helvetica Neue", sans-serif;
  width: 100%;
  z-index: 100;
  background: var(--jf-header-bg);
  transition: background-color 0.2s ease;
  &.jfRHeader--dark-mode {
    --jf-header-bg: ${props => props.menuStyles.headerBackground || '#0a1551'};
    --jf-header-bg-sticky: ${props => props.menuStyles.headerBackgroundSticky || 'rgba(10, 21, 81, 0.9)'};
    --jf-header-logo-text: ${props => props.menuStyles.logoTextColor || '#fff'};
    --jf-header-nav-menu: ${props => props.menuStyles.navMenuColor || '#fff'};
    --jf-header-nav-menu-active: ${props => props.menuStyles.navMenuActiveColor || '#0075E3'};
    --jf-header-nav-menu-hover: ${props => props.menuStyles.navMenuHoverColor || '#0075E3'};
    --jf-hamburger-menu: ${props => props.menuStyles.hamburgerMenuColor || '#fff'};
    --jf-app-picker-border: ${props => props.menuStyles.appPickerBorderColor || '#252d5b'};
    --jf-header-size: ${props => props.menuStyles.headerHeight || '64px'};
    @media screen and (max-width: 1024px) {
      --jf-header-size: 45px;
    }
    ${ScHeaderContainer} {
      &.isMobileMenuActive {
        background: var(--jf-header-bg);
      }
    }
    @media screen and (max-width: 1024px) {
      --jf-header-nav-menu: #0a1551;
    }
  }
  &.jfRHeader--gray-mode {
    --jf-header-bg: ${props => props.menuStyles.headerBackground || '#F3F3FE'};
    --jf-header-bg-sticky: ${props => props.menuStyles.headerBackgroundSticky || 'rgba(244, 244, 244, 0.9)'};
    --jf-header-logo-text: ${props => props.menuStyles.logoTextColor || '#0a1551'};
    --jf-header-nav-menu: ${props => props.menuStyles.navMenuColor || '#0a1551'};
    --jf-header-nav-menu-active: ${props => props.menuStyles.navMenuActiveColor || '#0075E3'};
    --jf-header-nav-menu-hover: ${props => props.menuStyles.navMenuHoverColor || '#0075E3'};
    --jf-hamburger-menu: ${props => props.menuStyles.hamburgerMenuColor || '#333'};
    --jf-app-picker-border: ${props => props.menuStyles.appPickerBorderColor || '#e0e0e0'};
    --jf-header-size: ${props => props.menuStyles.headerHeight || '64px'};
    @media screen and (max-width: 1024px) {
      --jf-header-size: 45px;
    }
    ${ScHeaderContainer} {
      &.isMobileMenuActive {
        background: var(--jf-header-bg);
      }
    }
    @media screen and (max-width: 1024px) {
      --jf-header-nav-menu: #0a1551;
    }
  }
  &.isSticky {
    backdrop-filter: saturate(180%) blur(20px);
    background: var(--jf-header-bg-sticky) !important; // stylelint-disable-line
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 8000;
  }
  &.jfRHeader--full-width {
    ${ScHeaderContainer} {
      @media screen and (min-width: 480px) {
        max-width: 100%;
      }
    }
  }
`;

export default ScHeader;
