import { openLoginFlow } from './openLoginFlow';

export const showLoginFlowModal = (
  page = 's1',
  tracking,
  // eslint-disable-next-line no-unused-vars
  hideClose = false,
  greetingMessage,
  greetingDescription,
  // eslint-disable-next-line no-unused-vars
  callbacks = {},
  extraModalProps = {}
) => {
  openLoginFlow({
    page,
    tracking,
    greetingDescription,
    greetingMessage,
    extraModalProps
  });
};

global.window.showModal = showLoginFlowModal;
