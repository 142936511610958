import {
  arrayOf, number, object, oneOf, shape, string
} from 'prop-types';
import { PRICING_TABLE_SOURCE_PAGES, SEGMENTED_MODAL_TYPES } from './constants';

const accountType = shape({
  name: oneOf(['GUEST', 'FREE', 'STORAGE', 'PREMIUM', 'BRONZE', 'ECONOMY', 'SILVER', 'OLDPREMIUM', 'PROFESSIONAL', 'GOLD', 'DIAMOND',
    'PARTNER', 'ENTERPRISE', 'PLATINUM', 'ADMIN', 'SUPPORT', 'INTERN', 'HELPDESK'])
});

export const userPropType = shape({
  username: string.isRequired,
  name: string,
  email: string,
  jobTitle: string,
  industry: string,
  company: string,
  account_type: accountType.isRequired,
  language: string
});

export const segmentedModalPropType = oneOf([
  SEGMENTED_MODAL_TYPES.GOVERNMENT,
  SEGMENTED_MODAL_TYPES.HEALTHCARE,
  SEGMENTED_MODAL_TYPES.EDUCATION,
  SEGMENTED_MODAL_TYPES.FIELD_SERVICES_MANAGEMENT,
  SEGMENTED_MODAL_TYPES.NONPROFIT
]);

export const gaAttributesPropType = shape({
  'data-ga': string,
  'data-id': string
});

const gaFormTrackingItem = shape({
  selector: string,
  payload: object
});

export const planDetailsProps = shape({
  currency: string.isRequired,
  prices: shape({ monthly: number.isRequired }).isRequired,
  onSale: shape({ nonProfit: shape({ monthly: number.isRequired }) }).isRequired
});

export const planListPropType = shape({
  BRONZE: planDetailsProps.isRequired,
  SILVER: planDetailsProps.isRequired,
  GOLD: planDetailsProps.isRequired
});

const { EDUCATION, NONPROFIT } = PRICING_TABLE_SOURCE_PAGES;

export const sourcePagePropType = oneOf([EDUCATION, NONPROFIT]);
export const sourcePageDefaultProp = EDUCATION;

export const gaFormTrackingItemsPropType = arrayOf(gaFormTrackingItem);
