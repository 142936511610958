import { LOGIN_FLOW_ACTIONS_PROJECT_NAME } from '../../constants';
import { ActionManager } from '../ActionManager';
import { createSocialTermsFlow } from './createSocialTermsFlow';
import { customRedirectionOnLogIn, customRedirectionOnSignUp } from './customRedirects';
import { toggleSignupLoaders } from './toggleSignupLoaders';

export const openSocialLoginFlow = type => {
  const handlerMap = {
    google: 'handleGoogleClick',
    facebook: 'handleFBClick',
    microsoft: 'handleMSClick'
  };

  const trackLoginFlow = new ActionManager({ user: global.window.user, projectName: LOGIN_FLOW_ACTIONS_PROJECT_NAME }).registerJotformAction;

  const activeMethod = handlerMap[type];

  if (!activeMethod) {
    console.error(`Unsupported social login type: ${type}`);
    return;
  }

  trackLoginFlow({ action: 'open', target: type });

  global.window.loginFlowHelper[activeMethod](
    {
      showLogoOnSignupOptions: false,
      includeConfirm: true,
      forceUser: true,
      renderValidationStepsAsModal: true,
      buttonNames: {
        google: 'google-homepage-hero',
        facebook: 'facebook-homepage-hero'
      },
      onUserLogin: (user, { newUser }) => {
        trackLoginFlow(newUser ? 'signup' : 'login', type);
        toggleSignupLoaders('hide');
        return newUser ? customRedirectionOnSignUp() : customRedirectionOnLogIn();
      },
      ...createSocialTermsFlow(global.window.user)
    },
    // 2fa response callback
    f => f,
    // error callback
    error => {
      console.error(error);
      toggleSignupLoaders('hide');
    },
    // email auth callback
    f => f
  );
};
