/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';
import { array, bool, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import NavItemBadge from '../Navigation/NavItemBadge';

const TemplatesMenuType = ({
  menuItems,
  isOpen,
  menuTitle,
  showAiAgentBanner
}) => {
  const TemplatesMenuTypeClasses = cx('jfRHeader--nav-sub-menu jfTemplates', {
    isOpen
  });

  return (
    <>
      {menuItems.length > 0 && (
        <ul className={TemplatesMenuTypeClasses}>
          <li className="jfRHeader--nav-sub-menu-text">{t(menuTitle)}</li>
          {menuItems.map(({
            name, title, url, icon, badge
          }) => (
            <li key={name} className='jfRHeader--nav-sub-menu-item'>
              <a href={url} className="jfRHeader--nav-sub-menu-item-link">
                {icon && <img src={icon} alt={t(title)} className="jfRHeader--nav-sub-menu-item-icon" />}
                <span>{t(title)}</span>
                {badge && <NavItemBadge text={badge.text} />}
              </a>
            </li>
          ))}
          {showAiAgentBanner && (
            <li className="jfRHeader--nav-sub-menu-item jfHeader-agentTemplates">
              <a
                target="_blank"
                data-text-name="agentTemplates"
                href="/agent-directory/?utm_source=jotform.com&utm_campaign=agentTemplates&utm_medium=header&utm_content=cta_agentTemplates&campaign=header&adgroup=jotform.com&creative=cta_agentTemplates"
                className="jfRHeader--nav-sub-menu-item-link"
              >
                <img
                  height="56"
                  width="135"
                  alt="Jotform Agents"
                  src="https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/jotform-agents.png?v=1.1"
                  className="jfHeader-discover-img"
                />
                <span className="jfHeader-discover-text">{t('Meet your AI Agents')}</span>
                <span className="jfHeader-discover-cta">{t('Discover Now')}</span>
              </a>
            </li>
          )}
        </ul>
      )}
    </>
  );
};

TemplatesMenuType.propTypes = {
  menuItems: array.isRequired,
  isOpen: bool.isRequired,
  menuTitle: string.isRequired,
  showAiAgentBanner: bool.isRequired
};

export default TemplatesMenuType;
