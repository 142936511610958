export const THEME_MAP = {
  LIGHT: 'light',
  DARK: 'dark',
  GRAY: 'gray'
};

export const THEME_CLASSNAME_MAP = {
  [THEME_MAP.DARK]: 'isDark',
  [THEME_MAP.LIGHT]: 'isLight',
  [THEME_MAP.GRAY]: 'isGray'
};

export const USER_TYPES = {
  USER: 'USER',
  FORM_USER: 'FORM_USER'
};

// A/B Test: contactSalesButtonOnHeader
export const CONTACT_SALES_AB_TEST_VARIATIONS = {
  CONTROL_VARIANT: '28922',
  CONTACT_SALES_AS_CTA: '28932',
  CONTACT_SALES_AS_ITEM: '28941',
  NO_INTEGRATIONS_MENU: '28942'
};

export const BADGE_TYPES = {
  GOVERNMENT: 'government',
  HIPAA: 'hipaa'
};

export const RENDER_AS_TYPES = {
  LINK: 'link',
  BUTTON: 'button'
};
