import { renderAdvertisementLeadFlow } from '@jotforminc/enterprise-promotions';
import { getEnterprisePromotionByAssetName } from '@jotforminc/ep-utils';
import { BLOG_POSTS } from './blogPosts';

export const initEpAdLeadFlowBlogPosts = async user => {
  const currentPage = global.window.location.pathname;
  const blogPost = BLOG_POSTS[currentPage];
  if (!blogPost) return;

  const params = new URLSearchParams(document.location.search);
  const hasParam = params.has(blogPost.param);
  if (!hasParam) return;

  try {
    const asset = await getEnterprisePromotionByAssetName('advertisementLeadFlow');
    if (!asset.show) return;

    renderAdvertisementLeadFlow({
      user,
      isExitIntentModalAvailable: asset.details?.isAdLeadFlowExitIntentModalAvailable,
      seenAtKey: asset.seenAtKey
    });
  } catch (error) {
    console.log(`Error on initializing epAdLeadFlow for blog posts: ${error}`);
  }
};
