export const CAMPAIGN = {
  EARNED_BADGES_MODAL: 'earnedBadgesModal',
  ETT_EDUCATION_GATED_CONTENT_MODAL: 'ettEducationGatedCotentModal',
  ETT_EDUCATION_EXIT_INTENT_MODAL: 'ettEducationExitIntentModal',
  FOMO_MODAL: 'fomoModal',
  FQHC_MODAL: 'fqhcModal',
  HEALTHCARE_REPORT_BANNER: 'healthcareReportBanner',
  HEALTHCARE_REPORT_MODAL: 'healthcareReportModal',
  NHS_MODAL: 'nhsModal'
};
