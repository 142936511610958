/* eslint-disable no-undef */
function yieldToMain(callback = () => {}) {
  if ('scheduler' in global.window && 'yield' in scheduler) {
    return scheduler.yield().then(callback);
  }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
      callback();
    }, 0);
  });
}

global.window.yieldToMain = yieldToMain;

export default yieldToMain;
