export const CASE_STUDIES = {
  '/blog/case-study-electrical-testing-ltd/': {
    param: 'csetl',
    badgeText: 'FREE GUIDE',
    bannerTitle: 'Mastering your digital healthcare toolkit',
    bannerDescription: 'How to integrate solutions for a better operation',
    bannerVisualUrl: 'https://cdn.jotfor.ms/assets/img/enterprise/blog-download-banner/healthcare.png',
    bannerItems: ['Streamline workflows', 'Improve service delivery', 'Ensure data security'],
    pdfUrl: 'https://cdn.jotfor.ms/assets/pdf/whitepapers/How_field_service_management_solutions_create_safe_sustainable_operations_UTM.pdf',
    modalProps: {
      iconUrl: 'https://cdn.jotfor.ms/assets/img/enterprise/blog-download-banner/healthcare-icon.png',
      title: 'Transform your digital healthcare toolkit with this free guide',
      description: 'Fill the form and get “Mastering your digital healthcare toolkit” via email.'
    }
  },
  '/blog/case-study-university-of-michigan-jotform-enterprise/': {
    param: 'csumje',
    badgeText: 'FREE GUIDE',
    bannerTitle: 'The educator’s technology toolkit',
    bannerDescription: 'Tuning up for efficiency and savings',
    bannerVisualUrl: 'https://cdn.jotfor.ms/assets/img/enterprise/blog-download-banner/education.png',
    bannerItems: ['Streamline workflows', 'Eliminate paper dependency', 'Improve student experience'],
    pdfUrl: 'https://cdn.jotfor.ms/assets/pdf/whitepapers/The_Educators_Technology_Toolkit_Cover.pdf',
    modalProps: {
      iconUrl: 'https://cdn.jotfor.ms/assets/img/enterprise/blog-download-banner/healthcare-icon.png',
      title: "Build an efficient Educator's Technology Toolkit for your school with this free guide",
      description: 'Fill the form and get “The educator’s technology toolkit: Tuning up for efficiency and savings” via email'
    }
  },
  '/blog/case-study-honor-health-network/': {
    param: 'cshhn',
    badgeText: 'FREE GUIDE',
    bannerTitle: 'Mastering your digital healthcare toolkit',
    bannerDescription: 'How to integrate solutions for a better operation',
    bannerVisualUrl: 'https://cdn.jotfor.ms/assets/img/enterprise/blog-download-banner/fsm.png',
    bannerItems: ['Automate scheduling ', 'Meet safety standards', 'Improve service delivery '],
    pdfUrl: 'https://cdn.jotfor.ms/assets/pdf/whitepapers/Mastering_Your_Digital_Healthcare_Toolkit.pdf',
    modalProps: {
      iconUrl: 'https://cdn.jotfor.ms/assets/img/enterprise/blog-download-banner/healthcare-icon.png',
      title: 'Transform your digital healthcare toolkit with this free guide',
      description: 'Fill the form and get “Mastering your digital healthcare toolkit” via email'
    }
  }
};
