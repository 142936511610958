import styled from 'styled-components';

const ScLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    padding-inline-end: 16px;
    transform: translateY(-.09rem);
  }
  // Mobile Nav
  .jfRHeader--mobile-nav {
    margin-left: 0;
    display: none;
    &-signup-btn {
      padding: 0 8px;
      border-radius: 4px;
      font-weight: 700;
      letter-spacing: -0.5px;
      cursor: pointer;
      background-color: #17255b;
      color: #fff;
      margin-left: 4px;
      margin-right: 4px;
      text-align: center;
      line-height: 24px;
      font-size: 12px;
      outline: none;
      border: 0;
      white-space: nowrap;
    }
    @media screen and (max-width: 1024px) {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
  }
  // Hamburger Menu
  .jfRHeader--hamburger {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--jf-hamburger-menu);
    border: 0;
    outline: none;
    padding: 0;
    margin-inline-end: -8px;
    svg {
      width: 16px;
    }
    &.isActive {
      svg {
        width: 24px;
      }
    }
  }
  .jfRHeader--logo-link {
    width: 120px;
    height: auto;
    display: flex;
    svg {
      max-width: 100%;
      max-height: 100%;
      height: 30px;
      width: auto;
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 30px;
    }
    @media screen and (min-width: 1280px) {
      width: 148px;
      height: auto;
    }
    &:focus {
      outline: thin dotted #d5daff;
    }
  }
  .jfRHeader--logo-divider {
    background-color: #d3d6ec;
    width: 1px;
    height: 24px;
    margin-left: 16px;
    margin-right: 16px;
    @media screen and (max-width: 1280px) {
      height: 18px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .jfRHeader--logo-text {
    font-size: 20px;
    font-weight: 500;
    color: var(--jf-header-logo-text);
    text-decoration: none;
    margin-inline-start: -2px;
    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (max-width: 480px) {
      font-size: 12px;
      max-width: 100px;
    }
  }
  .jfRHeader--logo-custom {
    svg {
      width: auto;
      height: 20px;
      display: block;
    }
    @media screen and (max-width: 1024px) {
      svg {
        height: 16px;
      }
    }
  }
  .jfRHeader--badge {
    display: flex; 
    align-items: center;
    &:before {
      content: "";
      background-color: #d3d6ec;
      width: 1px;
      height: 24px;
      margin-left: 16px;
      margin-right: 16px;
      @media screen and (max-width: 1024px) {
        height: 18px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    &-logo {
      width: 26px;
      height: auto;
      color: var(--jf-header-logo-text);
      @media screen and (max-width: 1024px) {
        width: 20px;
      }
    }
    &-text {
      margin-left: 8px;
      width: 96px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: var(--jf-header-logo-text);
      html[dir='rtl'] & {
        margin-left: 0;
        margin-right: 8px;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
`;

export default ScLogoWrapper;
