import React from 'react';
import {
  bool, string, func, oneOf, node
} from 'prop-types';
import { MENU_TYPES } from '@jotforminc/constants';
import { a11yClickHandler } from '../../utils';
import { RENDER_AS_TYPES } from '../../constants';
import ArrowIcon from '../../assets/svg/arrow.svg';

const NavLink = ({
  url,
  target,
  renderAs,
  classNames,
  menuType,
  showRedDot,
  children,
  onClick
}) => (
  <a
    tabIndex={0}
    className={classNames}
    onClick={onClick}
    onKeyDown={event => a11yClickHandler(event, onClick)}
    {...(menuType && { 'aria-haspopup': 'listbox', role: 'button' })}
    {...(renderAs === RENDER_AS_TYPES.LINK && { href: url, target: target })}
  >
    {children}
    {showRedDot && <span className="jfRHeader--nav-menu-item-dot" />}
    {menuType && <ArrowIcon />}
  </a>
);

NavLink.propTypes = {
  children: node.isRequired,
  url: string.isRequired,
  target: string.isRequired,
  title: string.isRequired,
  classNames: string.isRequired,
  menuType: oneOf([...Object.values(MENU_TYPES)]),
  renderAs: oneOf([...Object.values(RENDER_AS_TYPES)]),
  onClick: func.isRequired,
  showRedDot: bool.isRequired
};

NavLink.defaultProps = {
  renderAs: RENDER_AS_TYPES.LINK
};

export default NavLink;
