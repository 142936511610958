import {
  arrayOf, bool, element, oneOf, shape, string
} from 'prop-types';
import { MENU_TYPES } from '@jotforminc/constants';
import { RENDER_AS_TYPES, THEME_MAP, USER_TYPES } from '../constants';

export const themePropType = oneOf(Object.values(THEME_MAP));
export const stickyModeThemePropType = oneOf([...Object.values(THEME_MAP), '']);

const accountType = shape({
  name: oneOf(['GUEST', 'FREE', 'STORAGE', 'PREMIUM', 'BRONZE', 'ECONOMY', 'SILVER', 'OLDPREMIUM', 'PROFESSIONAL', 'GOLD', 'DIAMOND',
    'PARTNER', 'ENTERPRISE', 'PLATINUM', 'ADMIN', 'SUPPORT', 'INTERN', 'HELPDESK'])
});

export const userPropType = shape({
  username: string.isRequired,
  email: string,
  account_type: accountType.isRequired
});

export const userTypePropType = oneOf([USER_TYPES.USER, USER_TYPES.FORM_USER]);

export const logoPropType = shape({
  logoUrl: string,
  logoFullLight: bool,
  enterpriseLogoSrc: string,
  customLogoAsReactElement: element,
  customLogoAsReactElementOnMobileMenuOpen: element,
  customLogoAsHtmlString: string,
  customLogoAsHtmlStringOnMobileMenuOpen: string
});

export const logoDefaultProps = {
  logoUrl: '/',
  logoFullLight: false,
  enterpriseLogoSrc: '',
  customLogoAsReactElement: null,
  customLogoAsHtmlString: '',
  customLogoAsReactElementOnMobileMenuOpen: null,
  customLogoAsHtmlStringOnMobileMenuOpen: ''
};

export const logoSideLinkPropType = shape({
  sideLinkUrl: string,
  sideLinkText: string,
  sideLinkTextColor: string,
  sideLinkClassName: string,
  dividerColor: string,
  sideLinkLogoAsHtmlString: string
});

export const logoSideLinkDefaultProps = {
  sideLinkUrl: '#',
  sideLinkText: '',
  sideLinkTextColor: '',
  sideLinkClassName: '',
  dividerColor: '',
  sideLinkLogoAsHtmlString: ''
};

const badgePropType = shape({ text: string });

const menuItemSubLinkPropType = shape({
  name: string,
  title: string,
  url: string,
  icon: string
});

const menuItemPropType = shape({
  name: string,
  title: string,
  subTitle: string,
  url: string,
  icon: string,
  target: string,
  badge: badgePropType,
  seeMore: shape({
    title: string,
    url: string
  }),
  subLinks: arrayOf(menuItemSubLinkPropType),
  loginRequired: bool
});

export const linkPropType = shape({
  title: string,
  url: string,
  icon: string,
  className: string,
  target: string,
  menuType: oneOf(Object.values(MENU_TYPES)),
  renderAs: oneOf(Object.values(RENDER_AS_TYPES)),
  badge: badgePropType,
  selected: bool
});

export const menuPropType = shape({
  name: string,
  menuTitle: string,
  menuItems: arrayOf(menuItemPropType).isRequired,
  sideMenuTitle: string,
  sideMenuItems: arrayOf(menuItemPropType),
  sideMenuSeeMoreTitle: string,
  sideMenuSeeMoreUrl: string,
  seeAll: shape({
    title: string,
    url: string
  })
});

export const navItemPropType = shape({
  name: string,
  linkProps: linkPropType,
  menuProps: menuPropType
});

export const navItemsPropType = arrayOf(navItemPropType);

export const menuStylesPropType = shape({
  headerBackground: string,
  headerBackgroundSticky: string,
  headerHeight: string,
  logoTextColor: string,
  navMenuColor: string,
  navMenuActiveColor: string,
  hamburgerMenuColor: string,
  appPickerBorderColor: string,
  navMenuHoverColor: string
});
