import React from 'react';
import { IconArrowRight } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import '../../../styles/professionalServicesBanner.scss';

const ProfessionalServicesBanner = () => {
  return (
    <a
      className="jfRHeader--professional-services" href="/enterprise/professional-services/" tabIndex="0"
    >
      <span className="jfRHeader--professional-services-description-link">{t('Professional Services')}</span>
      <div className="jfRHeader--professional-services-explore-link-wrapper">
        <span className="jfRHeader--professional-services-explore-link">{t('Explore')}</span>
        <IconArrowRight className='arrow-icon' />
      </div>
    </a>
  );
};

export default ProfessionalServicesBanner;
