import { cva } from 'class-variance-authority';
import { CVAType } from '../../types';
import { DropdownProps } from './dropdown.types';
import { primary } from '../../tokens/colors';
import { DropdownGroupContextType } from '../../contexts';

type DropdownOptionHelperTypes = {
  isSelected: boolean,
  isActive: boolean,
  as: 'title' | 'option'
};

type DropdownOptionCVAType = CVAType<Pick<DropdownProps, 'size' | 'disabled' | 'theme'> & DropdownOptionHelperTypes & Pick<DropdownGroupContextType, 'indent'>>
type DropdownSizeCVAType = CVAType<Pick<DropdownProps, 'size'>>
type DropdownThemeCVAType = CVAType<Pick<DropdownProps, 'theme'>>

export const dropdownOptionCVA = cva<DropdownOptionCVAType>('flex items-center w-full', {
  variants: {
    size: {
      small: 'h-8',
      medium: 'h-10',
      large: 'h-12'
    },
    isSelected: {},
    disabled: {},
    isActive: {},
    theme: {},
    as: {
      title: 'font-bold'
    },
    indent: {}
  },
  compoundVariants: [
    {
      indent: false,
      size: 'small',
      className: 'px-2'
    },
    {
      indent: false,
      size: ['medium', 'large'],
      className: 'px-3'
    },
    {
      indent: true,
      as: 'option',
      size: 'small',
      className: 'pl-6 pr-2'
    },
    {
      indent: true,
      as: 'option',
      size: 'medium',
      className: 'pl-8 pr-3'
    },
    {
      indent: true,
      as: 'option',
      size: 'large',
      className: 'pl-10 pr-3'
    },
    {
      theme: 'light',
      as: 'option',
      className: 'color-navy-700'
    },
    {
      theme: 'dark',
      as: 'option',
      className: 'color-white'
    },
    {
      size: ['small', 'medium'],
      className: 'text-sm'
    },
    {
      size: 'large',
      className: 'text-md'
    },
    {
      disabled: true,
      className: 'opacity-40 cursor-not-allowed'
    },
    {
      disabled: false,
      isSelected: false,
      isActive: true,
      theme: 'light',
      className: 'bg-navy-25'
    },
    {
      disabled: false,
      isSelected: true,
      theme: 'light',
      className: 'bg-blue-100'
    },
    {
      disabled: false,
      isSelected: false,
      isActive: true,
      theme: 'dark',
      className: 'bg-gray-600'
    },
    {
      disabled: false,
      isSelected: true,
      theme: 'dark',
      className: 'bg-gray-700'
    },
    {
      as: 'title',
      theme: 'light',
      className: primary.text.medium.base
    },
    {
      as: 'title',
      theme: 'dark',
      className: 'color-gray-100'
    }
  ]
});

export const dropdownOptionIconCVA = cva<DropdownSizeCVAType>('shrink-0', {
  variants: {
    size: {
      small: 'w-4 h-4 mr-2',
      medium: 'w-5 h-5 mr-3',
      large: 'w-6 h-6 mr-3'
    }
  }
});

export const dropdownListCVA = cva<DropdownThemeCVAType>('radius flex flex-col', {
  variants: {
    theme: {
      light: 'bg-white shadow-md border border-navy-50',
      dark: 'bg-gray-500 shadow-dark-md'
    }
  }
});

export const dropdownSearchInputCVA = cva<DropdownThemeCVAType>('shrink-0 border-b', {
  variants: {
    theme: {
      light: 'border-b-navy-50',
      dark: 'border-b-gray-400'
    }
  }
});

export const dropdownResultNotFoundCVA = cva<DropdownThemeCVAType & DropdownSizeCVAType>('text-center',
  {
    variants: {
      size: {
        small: 'p-2 text-sm',
        medium: 'p-3 text-sm',
        large: 'p-3.5 text-md'
      },
      theme: {
        light: [primary.text.medium.base, primary.background.lightest.base],
        dark: 'color-white'
      }
    }
  }
);
