import React, { Fragment } from 'react';
import {
  oneOfType, shape, string, node, elementType, bool
} from 'prop-types';

import GrowthAssetRenderer from './GrowthAssetRenderer';

const GrowthAssetManager = ({
  campaignInfo = global.window?.campaignInfo,
  assetProps,
  assetType,
  Fallback
}) => {
  return (
    <GrowthAssetRenderer
      campaignInfo={campaignInfo}
      assetProps={assetProps}
      assetType={assetType}
      Fallback={Fallback}
    />
  );
};

GrowthAssetManager.propTypes = {
  assetType: string.isRequired,
  assetProps: shape({}),
  campaignInfo: shape({
    status: bool,
    type: string,
    assetsVersion: string,
    assetsAvailable: bool,
    assetYear: string
  }),
  Fallback: oneOfType([
    node,
    elementType
  ])
};

GrowthAssetManager.defaultProps = {
  campaignInfo: global.window?.campaignInfo,
  assetProps: {},
  Fallback: Fragment
};

export default GrowthAssetManager;
