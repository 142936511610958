import { useEffect, useState } from 'react';

const useLazyLoginGroup = () => {
  const [event, setEvent] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const handleEvent = e => {
      if (!event) {
        setEvent(e);
        setLoad(true);
      }

      global.window.removeEventListener('touchstart', handleEvent);
      global.window.removeEventListener('pointermove', handleEvent);
    };

    global.window.addEventListener('touchstart', handleEvent);
    global.window.addEventListener('pointermove', handleEvent);

    return () => {
      global.window.removeEventListener('touchstart', handleEvent);
      global.window.removeEventListener('pointermove', handleEvent);
    };
  }, []);

  return [load, event];
};

export default useLazyLoginGroup;
