/* eslint-disable max-len */
export const CTA_URLS = {
  ADD_AND_MANAGE_USERS: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=jotform.com&utm_content=collaboration_enterprise_accountbox_admin_console_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/multiple-users/?utm_medium=referral&utm_source=jotform.com&utm_content=collaboration_enterprise_accountbox_admin_console_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ADMIN_CONSOLE_PROMOTION: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=jotform.com&utm_content=enterprise_accountbox_admin_console_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/multiple-users/?utm_medium=referral&utm_source=jotform.com&utm_content=enterprise_accountbox_admin_console_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  UPGRADE_TO_ENTERPRISE_FULL_PAGE: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=myaccount_users&utm_content=contact_sales_button&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/multiple-users/?utm_medium=referral&utm_source=myaccount_users&utm_content=learn_more_button&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ASSIGN_TO_EVERYONE_PROMOTION: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=form_builder&utm_content=assign_everyone_toggle&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/multiple-users/?utm_medium=referral&utm_source=form_builder&utm_content=assign_everyone_toggle&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ASSIGN_TO_EVERYONE_BUTTON: {
    URL: '/enterprise/multiple-users/?utm_medium=referral&utm_source=assign_everyone_button&utm_content=assign_everyone_button_link&utm_campaign=enterprise_cta'
  },
  ASSIGN_FORM_BANNER: {
    LEARN_MORE_URL: '/teams/?utm_medium=referral&utm_source=form_builder&utm_content=assign_form_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ENTERPRISE_WHITE_LABELING_BANNER: { // a/b test
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=form_builder&utm_content=mini_designer_white_labeling&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/white-label/?utm_medium=referral&utm_source=form_builder&utm_content=mini_designer_white_labeling&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  WHITE_LABELING_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=myaccount_settings&utm_content=white_labeling_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/white-label/?utm_medium=referral&utm_source=myaccount_settings&utm_content=white_labeling_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  APPROVAL_WHITE_LABELING_BANNER: {
    UPGRADE_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=approval&utm_content=white_labeling_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=upgrade_link',
    LEARN_MORE_URL: '/enterprise/white-label/?utm_medium=referral&utm_source=approval&utm_content=white_labeling_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ENTERPRISE_GOVERNMENT_MODAL: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=my_forms&utm_content=enterprise_government_modal&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/government-solution/?utm_medium=referral&utm_source=my_forms&utm_content=enterprise_government_modal&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ENTERPRISE_ACCOUNTBOX_PROMOTION: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=account_box&utm_content=inline_alert&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=account_box&utm_content=inline_alert&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ENTERPRISE_PROMO_IN_BILLING_URL: '/enterprise/?utm_medium=referral&utm_source=myaccount_upgrade&utm_content=check_out_jotform_enterprise&utm_campaign=enterprise_cta&utm_enterprise_actions_target=check_out_jotform_enterprise',
  FULLY_CUSTOMIZE_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=form_builder&utm_content=webhook_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=form_builder&utm_content=webhook_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  IP_RESTRICTION_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source={{app_name}}&utm_content=ip_restriction_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    DISCOVER_MORE_URL: '/enterprise/?utm_medium=referral&utm_source={{app_name}}&utm_content=ip_restriction_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=discover_now_link'
  },
  CUSTOM_DOMAIN_API_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=myaccount_api&utm_content=myaccount_api_custom_domain_contact_sales&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    ENTERPRISE_URL: '/enterprise/?utm_medium=referral&utm_source=myaccount_api&utm_content=myaccount_api_custom_domain_enterprise&utm_campaign=enterprise_cta&utm_enterprise_actions_target=enterprise_link'
  },
  MY_ACCOUNT_STORE_DATA_IN_AU_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=myaccount_data&utm_content=store_data_in_au_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    ENTERPRISE_URL: '/enterprise/?utm_medium=referral&utm_source=myaccount_data&utm_content=store_data_in_au_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=enterprise_link'
  },
  DEDICATED_SUPPORT_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=form_builder_help_menu&utm_content=dedicated_support&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link'
  },
  DEDICATED_SUPPORT_SECTION: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=jotform.com&utm_content=dedicated_support_contact_sales_cta&utm_campaign=enterprise_common_header'
  },
  WHITE_LABEL_SECTION: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=form_builder&utm_content=white-label-section&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=form_builder&utm_content=white-label-section&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ACCESS_CONTROL_SECTION: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=form_builder&utm_content=access-control-section&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=form_builder&utm_content=access-control-section&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  CUSTOM_DOMAIN_TOOLTIP: {
    WHITE_LABEL_URL_FORM_BUILDER: '/enterprise/white-label/?utm_medium=referral&utm_source=form_builder&utm_content=publish_quick_share_enterprise_cta&utm_campaign=enterprise_cta&utm_enterprise_actions_target=white_label_link',
    WHITE_LABEL_URL_PORTAL: '/enterprise/white-label/?utm_medium=referral&utm_source=portal&utm_content=publish_quick_share_enterprise_cta&utm_campaign=enterprise_cta&utm_enterprise_actions_target=white_label_link'
  },
  SHARE_PANEL_BOTTOM_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=share_panel&utm_content=collab_bottom_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=share_panel&utm_content=collab_bottom_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  PUSH_DAY_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=my_forms&utm_content=push_day_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link'
  },
  PROFESSIONAL_SOLUTIONS_BANNER: {
    CONTACT_SALES_URL_INBOX: '/enterprise/contact-sales/?utm_medium=referral&utm_source=inbox&utm_content=professional_solutions_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    CONTACT_SALES_URL_TABLES: '/enterprise/contact-sales/?utm_medium=referral&utm_source=tables&utm_content=professional_solutions_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link'
  },
  ADMIN_CONSOLE_SLIDER_MODAL: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=my_forms_more_menu&utm_content=admin_console_slider_modal&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=my_forms_more_menu&utm_content=admin_console_slider_modal&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  MYACCOUNT_USAGE_INLINE_LIMIT_ALERT: {
    CONTACT_SALES_URL: '?utm_medium=referral&utm_source=myaccount_usage&utm_content=inline_limit_alert_upgrade_button&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link'
  },
  FORM_ANALYTICS_ADMIN_CONSOLE_BANNER: {
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=form_analytics&utm_content=admin_console_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  MYFORMS_WHITE_LABELING_TOOLTIP_BANNER: {
    LEARN_MORE_URL: '/enterprise/white-label/?utm_medium=referral&utm_source=my_forms&utm_content=white_labeling_tooltip&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  MY_FORMS_AU_DATA_RESIDENCY_MODAL: {
    DISCOVER_URL: '/enterprise/local-data-residency/?utm_medium=referral&utm_source=my_forms?&utm_content=australian_data_residency_modal&utm_campaign=enterprise_cta&utm_enterprise_actions_target=discover_link'
  },
  MY_FORMS_LUNCH_AND_LEARN_BANNER: {
    SEE_DETAILS: 'https://jotform.com/enterprise/lunch-and-learn-sessions/?utm_medium=referral&utm_source=my_forms&utm_content=lunch_and_learn_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=see_details_link#sessions',
    SAVE_YOUR_SEAT: 'https://jotform.com/enterprise/lunch-and-learn-sessions/?utm_medium=referral&utm_source=my_forms&utm_content=lunch_and_learn_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=save_your_seat_link',
    JOIN_NOW: 'https://us06web.zoom.us/w/82258346077'
  },
  COST_CUTTING_WEBINAR_MODAL: {
    LEARN_MORE_URL: '/blog/webinar-cutting-costs-from-business-processes/?utm_medium=referral&utm_source=my_forms&utm_content=cost_cutting_webinar_modal&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  COST_CUTTING_WEBINAR_BANNER: {
    LEARN_MORE_URL: '/blog/webinar-cutting-costs-from-business-processes/?utm_medium=referral&utm_source=my_forms&utm_content=cost_cutting_webinar_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  WORKFLOW_GROUP_APPROVAL_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=workflow&utm_content=group_approval_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/teams/?utm_medium=referral&utm_source=workflow&utm_content=group_approval_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  EXPANDABLE_ADMIN_CONSOLE_BUTTON: {
    LEARN_MORE_URL: '/enterprise/admin-console/?utm_medium=referral&utm_source=my_forms&utm_content=expandable_admin_console_button&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ADD_COLLABORATOR_BANNER: {
    CONTACT_SALES_URL: '/enterprise/multiple-users/?utm_medium=referral&utm_source=form_builder&utm_content=add_collaborator_contact_sales_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/multiple-users/?utm_medium=referral&utm_source=form_builder&utm_content=add_collaborator_learn_more_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  ENTERPRISE_SECURITY_ITEMS_SECTION: {
    LEARN_MORE_URL: '/enterprise/security/?utm_medium=referral&utm_source=my_account&utm_content=enterprise-security-items&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  BLOG_CONTENT_BOOST_BANNER: {
    GET_CUSTOM_QUOTE_URL: utmSource => `/enterprise/contact-sales/?utm_medium=referral&utm_source=${utmSource}&utm_content=boost_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=get_custom_quote_link`
  },
  BLOG_CONTENT_SPEED_UP_BANNER: {
    GET_CUSTOM_QUOTE_URL: utmSource => `/enterprise/contact-sales/?utm_medium=referral&utm_source=${utmSource}&utm_content=speed_up_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=get_custom_quote_link`
  },
  USER_GUIDE_PROFESSIONAL_SERVICES: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=user_guide_home&utm_content=prof_services_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/professional-services/?utm_medium=referral&utm_source=user_guide_home&utm_content=prof_services_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  GENERAL_FAQ_PROFESSIONAL_SERVICES: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=faq_home&utm_content=prof_services_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/professional-services/?utm_medium=referral&utm_source=faq_home&utm_content=prof_services_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  SESSIONS_ACCOUNT_SHARING_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=my_account_security&utm_content=sessions_account_sharing&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link'
  },
  SSO_ACCESS_BANNER: {
    CONTACT_SALES_URL: '/enterprise/contact-sales/?utm_medium=referral&utm_source=form_builder&utm_content=publish_sso_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE_URL: '/enterprise/single-sign-on/?utm_medium=referral&utm_source=form_builder&utm_content=publish_sso_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  SSO_ACCESS_LEGACY_BANNER: {
    JOTFORM_ENTERPRISE: '/enterprise/single-sign-on/?utm_medium=referral&utm_source=form_builder&utm_content=publish_sso_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=jotform_enterprise_link'
  },
  CUSTOM_DOMAIN_TOOLTIP_LEAGACY: {
    TRY_JOTFORM_ENTERPRISE_URL: '/enterprise/white-label/?utm_medium=referral&utm_source=form_builder&utm_content=publish_edit_custom_domain&utm_campaign=enterprise_cta&utm_enterprise_actions_target=try_jotform_enterprise_link'
  },
  SSO_PREFILL_LEGACY_BANNER: {
    UPGRADE_TO_ENTERPRISE: '/enterprise/single-sign-on/?utm_medium=referral&utm_source=form_builder&utm_content=enable_sso_prefill&utm_campaign=enterprise_cta&utm_enterprise_actions_target=upgrade_to_enterprise_link'
  },
  LOCAL_DATA_RESIDENCY_BANNER: {
    UPGRADE_TO_ENTERPRISE: '/enterprise/local-data-residency/?utm_medium=referral&utm_source=myaccount_data_section&utm_content=local_data_residency_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=upgrade_to_enterprise_link'
  },
  LOCAL_DATA_RESIDENCY_ALT_BANNER: {
    CONTACT_SALES: '/enterprise/contact-sales/?utm_medium=referral&utm_source=myaccount_data_section&utm_content=local_data_residency_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=contact_sales_link',
    LEARN_MORE: '/enterprise/local-data-residency/?utm_medium=referral&utm_source=myaccount_data_section&utm_content=local_data_residency_banner&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  TEAMS_CUSTOM_DOMAIN_LEGACY_TOOLTIP: {
    TRY_JOTFORM_ENTERPRISE_URL: '/enterprise/white-label/?utm_medium=referral&utm_source=team_url_section&utm_content=edit_custom_domain&utm_campaign=teams&utm_enterprise_actions_target=try_jotform_enterprise_link'
  },
  SSO_LEGACY_TOOLTIP: {
    JOTFORM_ENTERPRISE_URL: '/enterprise/single-sign-on/?utm_medium=referral&utm_source=form_builder&utm_content=require_sso_toggle&utm_campaign=enterprise_cta&utm_enterprise_actions_target=jotform_enterprise_link'
  },
  TEAMS_MODAL: {
    LEARN_MORE_URL: '/enterprise/teams/?utm_medium=referral&utm_source=teams_modal&utm_content=teams_modal_learn_more_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  PROFESSIONAL_SERVICES_CONTACT_BANNER: {
    DISCOVER_NOW_URL: '/enterprise/professional-services/?utm_medium=referral&utm_source=professional_services_contact_banner&utm_content=pscb_discover_more_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=discover_now_link'
  },
  SCHEDULE_ZOOM_MODAL: {
    LEARN_MORE_URL: '/enterprise/?utm_medium=referral&utm_source=schedule_zoom_modal&utm_content=schedule_zoom_modal_learn_more_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  },
  SOC2_COMPLIANCE_BANNER: {
    LEARN_MORE_URL: '/enterprise/soc-2-compliance/?utm_medium=referral&utm_source=soc2_compliance_banner&utm_content=soc2_compliance_banner_learn_more_link&utm_campaign=enterprise_cta&utm_enterprise_actions_target=learn_more_link'
  }
};
