export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue}; ${expires}; domain=.${global.location.host.split('.').splice(1).join('.')}; path=/; Secure; SameSite=None`;
};

export const readCookie = key => {
  const values = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return values ? values.pop() : undefined;
};
