export const getBodyEl = () => document.querySelector('body');

// Disable scroll when mobile menu is activated
export const toggleDocumentOverflowProperty = isHidden => {
  const overflow = isHidden ? 'hidden' : 'auto';
  if (isHidden) {
    document.documentElement.style.setProperty('overflow', overflow);
    document.documentElement.style.setProperty('height', '100%');
    document.documentElement.style.setProperty('width', '100%');
  }
};

export const getHeaderRootEl = () => {
  const headerHostEl = global.window.document.getElementById('jf-common-header-host');
  return headerHostEl?.shadowRoot || global.window.document;
};

export const addEventListenerOnce = (node, type, listener) => {
  const listenerWrapper = event => {
    node.removeEventListener(type, listenerWrapper);
    listener(event);
  };
  node.addEventListener(type, listenerWrapper);
};

export const createScriptElement = (url, callback, attr) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  if (callback) script.onload = callback;
  if (attr) script.setAttribute(attr, attr);
  script.src = url;
  document.head.appendChild(script);
  console.log('Loaded JS:', url);
};

export const createLinkElement = (url, attr) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  if (attr) link.setAttribute(attr, attr);
  document.head.appendChild(link);
  console.log('Loaded CSS:', url);
};

export const addRootElement = (rootId = '', classNames = '') => {
  let rootEl = global.window.document.querySelector(`#${rootId}`);
  if (!rootEl) {
    rootEl = global.window.document.createElement('div');
    rootEl.setAttribute('id', rootId);
    if (classNames) {
      rootEl.setAttribute('class', classNames);
    }
    global.window.document.querySelector('body').appendChild(rootEl);
  }
  return rootEl;
};

export const useLockBodyScroll = () => {
  const unlockScroll = () => {
    const bodyEl = global.window.document.body;
    const htmlEl = global.window.document.querySelector('html');

    if (bodyEl) {
      bodyEl.style.overflow = '';
    }

    if (htmlEl) {
      htmlEl.style.overflow = '';
      htmlEl.style.paddingInlineEnd = '';
    }
  };

  const lockScroll = () => {
    const bodyEl = global.window.document.body;
    const htmlEl = global.window.document.querySelector('html');

    if (bodyEl) {
      bodyEl.style.overflow = 'hidden';
    }

    if (htmlEl) {
      htmlEl.style.overflow = 'hidden';
      htmlEl.style.paddingInlineEnd = '14px';
    }
  };

  return {
    lockScroll,
    unlockScroll
  };
};

export const insertAfter = (referenceNode, newNode) => {
  referenceNode?.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};
