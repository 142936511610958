import { myFormsNavItem } from './myFromsNavItem';
import { templatesNavItem } from './templatesNavItem';
import { integrationsNavItem } from './integrationsNavItem';
import { productsNavItem } from './productsNavItem';
import { supportNavItem } from './supportNavItem';
import { enterpriseNavItem } from './enterpriseNavItem';
import { pricingsNavItem } from './pricingsNavItem';

import { signNavItem } from './signNavItem';
import { pdfEditorNavItem } from './pdfEditorNavItem';

export { MENU_TYPES } from './menuTypes';

export const DEFAULT_NAV_ITEMS = [
  myFormsNavItem,
  templatesNavItem,
  integrationsNavItem,
  productsNavItem,
  supportNavItem,
  enterpriseNavItem,
  pricingsNavItem
];

export const PDF_TEMPLATES_SUBCATEGORY_NAV_ITEMS = [
  signNavItem,
  pdfEditorNavItem
];
