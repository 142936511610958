import React from 'react';
import { bool, string } from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import User from '../assets/svg/user.svg';
import Email from '../assets/svg/email.svg';
import Organization from '../assets/svg/organization.svg';
import { TEXTS } from '../constants';
import { useEpOnboarding } from '../contexts';

const InfoCard = ({
  name, isTeam, isIndividual, isHalfVisible, isOrganizationStep, shadowImage
}) => {
  const { state: { onboardData: { usageTier, managementLevel, organizationName } } } = useEpOnboarding();

  return (
    <div style={{ position: 'relative' }}>

      {shadowImage && (
        <div className='jfOnboarding--info-card-shadow'>
          <img src={shadowImage} alt="shadow" />
        </div>
      )}

      <div className={cx('jfOnboarding--info-card', isHalfVisible && 'half', isOrganizationStep && 'half')}>
        {isOrganizationStep
          ? (
            <div className='jfOnboarding--info-card-organization'>
              <Organization className='icon' />
              <span className='name'>{organizationName || t('Your Organization')}</span>
            </div>
          )
          : (
            <div className='jfOnboarding--info-card-bar' />
          )}
        <div className='jfOnboarding--info-card-wrapper'>

          {!isOrganizationStep && (
          <div className='jfOnboarding--info-card-header'>
            <User className='icon' />
            <div className='content'>
              <span className='name'>{name || <span className='placeholder' /> }</span>
              <span className='email'>
                <Email />
                <span className='placeholder' />
              </span>
            </div>
          </div>
          )}

          {isHalfVisible || isOrganizationStep ? (
            <div className='jfOnboarding--placeholder-wrapper'>
              <div className='first-line'>
                <div className='item-1' />
                <div className='item-2' />
                <div className='item-3' />
              </div>
              <div className='second-line' />
              <div className='second-line' />
              <div className='second-line' />
            </div>
          ) : (
            <>
              <div className='jfOnboarding--info-tag-wrapper'>
                {isTeam && (
                <span className='tag team'>
                  {usageTier}
                </span>
                )}
                {isIndividual && managementLevel && (
                <span className='tag individual min-w-14'>
                  {managementLevel}
                </span>
                )}
              </div>
              <div className='jfOnboarding--info-details-wrapper'>
                <div className='line'>
                  <span className='text'>{TEXTS.INDUSTRY}</span>
                  <span className='placeholder' />
                </div>
                <div className='line'>
                  <span className='text'>{TEXTS.ORG_NAME}</span>
                  <span className='placeholder' />
                </div>
                <div className='line'>
                  <span className='text'>{TEXTS.ORG_SIZE}</span>
                  <span className='placeholder' />
                </div>
                <div className='line'>
                  <span className='text'>{TEXTS.JOB_ROLE}</span>
                  <span className='placeholder' />
                </div>
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  name: string,
  isTeam: bool,
  isIndividual: bool,
  isHalfVisible: bool,
  isOrganizationStep: bool,
  shadowImage: string
};

InfoCard.defaultProps = {
  name: null,
  isTeam: false,
  isIndividual: false,
  isHalfVisible: false,
  isOrganizationStep: false,
  shadowImage: null
};

export default InfoCard;
