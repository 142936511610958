export const TEXTS = {
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  DONT_HAVE_AN_ACCOUNT: 'Don’t have an account?',
  LOGIN: 'Login',
  SIGNUP: 'Signup',
  TRUSTED_BY_OVER_25_MILLION_USERS_AROUND_THE_WORLD: 'Trusted by over 25 million users around the world.',
  JOTFORM_FOOTER_TEXT: '© [1[2024]] Jotform Inc.',
  CREATE_YOUR_ACCOUNT: 'Create Your Account',
  HELP_US_UNDERSTAND_YOUR_NEEDS: 'Help Us Understand Your Needs',
  SKIP: 'Skip',
  FINISH: 'Finish',
  NEXT: 'Next',
  BACK: 'Back',
  TEAM: 'Team',
  INDIVIDUAL: 'Individual Contributor',
  INDUSTRY: 'Industry',
  ORG_NAME: 'Organization Name',
  ORG_SIZE: 'Organization Size',
  JOB_ROLE: 'Job role',
  MANAGEMENT_LEVEL: 'Management Level',
  SIZE_OF_ORGANIZATION: 'Size of Organization',
  ORGANIZATION_WEBSITE: 'Organization Website',
  SIZE_JUST_ME: 'Just Me',
  SIZE_LEVEL1: '2 - 99',
  SIZE_LEVEL2: '100 - 999',
  SIZE_LEVEL3: '1000+',
  MY_TEAM: 'My Team',
  MY_ORGANIZATION: 'My Organization',

  WHO_WILL_BE_USING: 'Who will be using Jotform?',
  WHO_WILL_BE_USING_DESCRIPTION: 'Your answer helps us recommend the best options for you.',

  TELL_US_ABOUT_YOUR_ROLE: 'Tell us about your job role',
  TELL_US_ABOUT_YOUR_ROLE_DESCRIPTION: 'Your answer helps us personalize your experience.',

  TELL_US_ABOUT_YOUR_ORGANIZATION: 'Tell us about your organization',
  TELL_US_ABOUT_YOUR_ORGANIZATION_DESCRIPTION: 'Your answer helps us recommend the right plan for your team.',
  TELL_US_ABOUT_YOUR_ORGANIZATION_PLACEHOLDER: 'Enter your organization’s name',

  ORGANIZATION_WEBSITE_PLACEHOLDER: 'Enter your organization’s website URL',
  ORGANIZATION_WEBSITE_VALIDATION: 'Enter a valid website address',

  SEE_OTHER_INDUSTRIES: 'See other industries',

  WHICH_INDUSTRY_YOU_WORK: 'Which industry do you work in?',
  WHICH_INDUSTRY_YOU_WORK_DESCRIPTION: 'Your answer helps us customize your journey.',
  WHICH_INDUSTRY_YOU_WORK_PLACEHOLDER: 'Enter the industry you work in',

  INDUSTRY_NONPROFIT: 'Nonprofit',
  INDUSTRY_HEALTHCARE: 'Healthcare',
  INDUSTRY_EDUCATION: 'Education',
  INDUSTRY_FINANCE: 'Finance',
  INDUSTRY_HUMAN_RESOURCES: 'Human Resources',
  INDUSTRY_IT_TECHNOLOGY: 'IT & Technology',
  INDUSTRY_RETAIL_ECOMMERCE: 'Retail & E-commerce',
  INDUSTRY_MARKETING: 'Marketing & Research',
  INDUSTRY_INSURANCE: 'Insurance',
  INDUSTRY_REAL_ESTATE: 'Real Estate & Leasing',
  INDUSTRY_SPORTS_ACTIVITIES: 'Sports & Activities',
  INDUSTRY_CONSTRUCTION: 'Construction',
  INDUSTRY_BEAUTY_PERSONAL_CARE: 'Beauty & Personal Care',
  INDUSTRY_TRANSPORTATION_TRAVEL: 'Transportation & Travel',
  INDUSTRY_ANIMAL_CARE: 'Animal Care',
  INDUSTRY_RESTAURANTS_FOOD: 'Restaurants & Food',
  INDUSTRY_EVENT_ORGANIZATION: 'Event Organization',
  INDUSTRY_VIDEO_PHOTOGRAPHY: 'Video & Photography',
  INDUSTRY_ART_DESIGN: 'Arts & Design',
  INDUSTRY_PUBLIC_ADMINISTRATION: 'Public Administration',
  INDUSTRY_OTHER: 'Other',

  MARKETING_OPS: 'Marketing Ops',
  ENGINEERING: 'Engineering',
  CUSTOMER_SUPPORT_OPS: 'Customer Support Ops',
  HR_RECRUITING_OPS: 'HR & Recruiting Ops',
  PROJECT_MANAGEMENT: 'Project Management',
  BUSINESS_OWNER: 'Business Owner',
  SALES_OPS: 'Sales Ops',
  OTHER: 'Other',

  INDIVIDUAL_CONTRIBUTOR: 'Individual Contributor',
  TEAM_LEAD: 'Team Lead',
  MANAGER: 'Manager',
  DIRECTOR: 'Director',
  CEO_FOUNDER: 'CEO / Founder'
};

// A/B Test: epSignupPageOnboarding
export const INDUSTRIES = [
  TEXTS.INDUSTRY_PUBLIC_ADMINISTRATION,
  TEXTS.INDUSTRY_NONPROFIT,
  TEXTS.INDUSTRY_HEALTHCARE,
  TEXTS.INDUSTRY_EDUCATION,
  TEXTS.INDUSTRY_FINANCE,
  TEXTS.INDUSTRY_HUMAN_RESOURCES,
  TEXTS.INDUSTRY_IT_TECHNOLOGY,
  TEXTS.INDUSTRY_RETAIL_ECOMMERCE,
  TEXTS.INDUSTRY_MARKETING,
  TEXTS.INDUSTRY_INSURANCE,
  TEXTS.INDUSTRY_REAL_ESTATE,
  TEXTS.INDUSTRY_SPORTS_ACTIVITIES,
  TEXTS.INDUSTRY_CONSTRUCTION,
  TEXTS.INDUSTRY_BEAUTY_PERSONAL_CARE,
  TEXTS.INDUSTRY_TRANSPORTATION_TRAVEL,
  TEXTS.INDUSTRY_ANIMAL_CARE,
  TEXTS.INDUSTRY_RESTAURANTS_FOOD,
  TEXTS.INDUSTRY_EVENT_ORGANIZATION,
  TEXTS.INDUSTRY_VIDEO_PHOTOGRAPHY,
  TEXTS.INDUSTRY_ART_DESIGN,
  TEXTS.INDUSTRY_OTHER
];
