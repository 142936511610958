/* eslint-disable complexity */
import React from 'react';
import {
  bool, string, func
} from 'prop-types';
import SideLink from './SideLink';
import Logo from './Logo';
import {
  logoPropType, logoDefaultProps, logoSideLinkPropType,
  logoSideLinkDefaultProps, themePropType, userPropType
} from '../../propTypes';

const LogoGroup = ({
  user,
  theme,
  isSticky,
  language,
  logoProps,
  logoSideLinkProps,
  isMobileMenuActive,
  logHeaderAction,
  isEnterprise,
  currentPath
}) => {
  return (
    <>
      <Logo
        theme={theme}
        isSticky={isSticky}
        language={language}
        logoProps={logoProps}
        isMobileMenuActive={isMobileMenuActive}
        logHeaderAction={logHeaderAction}
        isEnterprise={isEnterprise}
      />
      <SideLink
        user={user}
        theme={theme}
        isEnterprise={isEnterprise}
        logoSideLinkProps={logoSideLinkProps}
        isMobileMenuActive={isMobileMenuActive}
        currentPath={currentPath}
      />
    </>
  );
};

LogoGroup.propTypes = {
  isMobileMenuActive: bool,
  isSticky: bool,
  user: userPropType,
  theme: themePropType.isRequired,
  language: string,
  logoProps: logoPropType,
  logoSideLinkProps: logoSideLinkPropType,
  logHeaderAction: func,
  isEnterprise: bool.isRequired,
  currentPath: string.isRequired
};

LogoGroup.defaultProps = {
  isMobileMenuActive: false,
  isSticky: false,
  language: undefined,
  logoProps: logoDefaultProps,
  logoSideLinkProps: logoSideLinkDefaultProps,
  logHeaderAction: f => f
};

export default LogoGroup;

// make sure you test your changings on these pages
// https://www.jotform.com/myforms/
// https://www.jotform.com/form-templates/
// https://www.jotform.com/blog/
// https://www.jotform.com/terms/
// https://www.jotform.com/return-to-office-guidelines/
// https://www.jotform.com/data-filtering/
// https://www.jotform.com/photography/
// https://www.jotform.com/enterprise/
// https://www.jotform.com/online-payments/
// https://www.jotform.com/2checkout/
// https://www.jotform.com/products/sign/
// https://www.jotform.com/blog/announcing-jotform-group-approvals/
// https://www.jotform.com/wufoo-alternative/
