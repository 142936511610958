import { ComponentPropsWithoutRef } from 'react';
import { Icon, Size } from '../../constants/common.types';

export type TagProps = ComponentPropsWithoutRef<'span'> & {
  size?: Size,
  colorStyle?: 'orange' | 'red' | 'purple' | 'blue' | 'yellow' | 'green' | 'gray',
  icon?: Icon,
  disabled?: boolean
}

export const tagDefaultProps:Partial<TagProps> = {
  colorStyle: 'orange',
  size: 'medium'
};
