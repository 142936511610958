import { StorageHelper } from '@jotforminc/storage-helper';

import { getComponentName } from './namings';

import CampaignAssetList from '../components/assets/assetList/CampaignAssetList';
import PromotionAssetList from '../components/assets/assetList/PromotionAssetList';
import LaunchAssetList from '../components/assets/assetList/LaunchAssetList';
import AnnouncementAssetList from '../components/assets/assetList/AnnouncementAssetList';

const AssetList = {
  ...CampaignAssetList,
  ...PromotionAssetList,
  ...LaunchAssetList,
  ...AnnouncementAssetList
};

export const getAssetComponent = ({ assetInfo, assetType }) => {
  const componentName = getComponentName({ ...assetInfo, assetType });
  return componentName ? AssetList[componentName] : null;
};

export const getDismissStorageItemKey = ({
  assetType,
  assetsVersion,
  campaignType
}) => {
  return `GAM:${assetType}:${campaignType.toLowerCase()}:${assetsVersion}:dismiss`;
};

export const checkIsAssetDismissed = ({
  assetType,
  assetsVersion,
  campaignType
}) => {
  if (!assetType || !assetsVersion || !campaignType) {
    return false;
  }

  const key = getDismissStorageItemKey({
    assetType,
    assetsVersion,
    campaignType
  });

  return StorageHelper.getLocalStorageItem({ key }) === '1';
};
