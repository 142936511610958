import { useEffect, useState } from 'react';
import { isProd } from '../utils';

// demo purpose
const useHubspotTracker = ({ isEnabled = false, user = null } = {}) => {
  const [isTrackerLoaded, setIsTrackerLoaded] = useState(false);

  useEffect(() => {
    if (!isEnabled || !isProd() || !user || isTrackerLoaded) return;

    const handleScript = e => {
      if (e.type === 'load') {
        setIsTrackerLoaded(true);
      }
    };

    const scriptEl = document.createElement('script');
    scriptEl.id = 'hs-script-loader';
    scriptEl.type = 'application/javascript';
    scriptEl.src = '//js.hs-scripts.com/4773329.js';
    scriptEl.async = true;
    scriptEl.defer = true;
    document.body.appendChild(scriptEl);
    scriptEl.addEventListener('load', handleScript);
    scriptEl.addEventListener('error', handleScript);

    return () => {
      scriptEl.removeEventListener('load', handleScript);
      scriptEl.removeEventListener('error', handleScript);
      scriptEl.remove();
    };
  }, [user]);

  return [isTrackerLoaded];
};

export default useHubspotTracker;
