import { ABTestManager } from '@jotforminc/abtest-manager';
/* global HTMLElement */

import Moment from 'moment';
import utf8 from 'utf8';
import {
  COUNTDOWN_UNIT, lalbSession, LEAD_FORM_MAP, LUNCH_AND_LEARN_SESSIONS, PRICING_TABLE_SOURCE_PAGES
} from '../constants';

export const decodeUTF8 = text => {
  if (!text) return '';

  try {
    return utf8.decode(text);
  } catch (error) {
    console.log(error);
    return text;
  }
};

export const getCurrentDateWithoutTimeZone = () => {
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();
  const currentDateWithoutOffset = new Date(currentDate.getTime() - (offset * 60 * 1000));
  const formattedDate = currentDateWithoutOffset.toISOString().split('T')[0];
  return formattedDate;
};

export const getRootElement = id => {
  if (document.getElementById(id)) {
    return document.getElementById(id);
  }
  const rootEl = document.createElement('div');
  rootEl.id = id;
  document.body.appendChild(rootEl);
  return rootEl;
};

export const isHTMLElement = el => el instanceof HTMLElement;

// decimal seperator 7 70 700 7.000 70.000 etc.
export const formatNumber = num => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const getCountdownInfo = () => {
  const EVENT_DATE = LUNCH_AND_LEARN_SESSIONS[lalbSession - 1];
  const { ANNOUNCEMENT_DATE_TIME, EVENT_DATE_TIME, EVENT_DURATION } = EVENT_DATE;

  const eventDateTime = new Date(EVENT_DATE_TIME).getTime();
  const announcementDateTime = new Date(ANNOUNCEMENT_DATE_TIME).getTime();

  const campaignTimespan = eventDateTime - announcementDateTime;

  const currentDate = new Date();
  const currentDateTimeWithoutOffset = currentDate.getTime();

  const eventDurHours = EVENT_DURATION.split(':')[0] * 60 * 60 * 1000;
  const eventDurMinutes = EVENT_DURATION.split(':')[1] * 60 * 1000;
  const eventDuration = eventDurHours + eventDurMinutes;

  const eventEndsAt = eventDateTime + eventDuration;
  const isEventCompleted = currentDateTimeWithoutOffset > eventEndsAt;
  const remainingTimespan = eventDateTime - currentDateTimeWithoutOffset;
  const remainingPercentage = Math.floor((remainingTimespan / campaignTimespan) * 100);

  let unit;
  let remainingTime;

  const remainingTimeDays = Math.floor(Moment.duration(remainingTimespan, 'milliseconds').asDays());
  const remainingTimeHours = Math.floor(Moment.duration(remainingTimespan, 'milliseconds').asHours());
  const remainingTimeMinutes = Math.floor(Moment.duration(remainingTimespan, 'milliseconds').asMinutes());

  if (remainingTimeDays >= 1) {
    unit = COUNTDOWN_UNIT.DAYS;
    remainingTime = remainingTimeDays;
  } else if (remainingTimeHours >= 1) {
    unit = COUNTDOWN_UNIT.HOURS;
    remainingTime = remainingTimeHours;
  } else if (remainingTimeMinutes >= 1) {
    unit = COUNTDOWN_UNIT.MINUTES;
    remainingTime = remainingTimeMinutes;
  } else {
    unit = COUNTDOWN_UNIT.LIVE;
    remainingTime = 0;
  }

  return {
    unit,
    remainingTime,
    isEventCompleted,
    remainingPercentage
  };
};

export const getAppName = () => window.__jfrouter?.ACTIVE_APP?.replace('-', '_') || '';

export const getMandatoryPrefillValues = user => {
  const username = user?.username || '';
  const bsgEmail = user?.email || '';

  return {
    username: decodeUTF8(username),
    bsgEmail: decodeUTF8(bsgEmail)
  };
};

export const getUserPrefillValues = user => {
  const fullNameItems = user?.name?.split(' ') || [];
  const lastNameIndex = fullNameItems.length - 1;
  const nameFirst = fullNameItems.length === 1 ? fullNameItems[0] : fullNameItems.slice(0, -1).join(' ').trim();
  const nameLast = fullNameItems.length === 1 ? '' : (fullNameItems[lastNameIndex] || '');
  const email = user?.email || '';
  const jobTitle = '';
  const company = user?.company || '';
  const country = user?.location?.country_name || '';
  const username = user?.username || '';

  return {
    username: decodeUTF8(username),
    nameFirst: decodeUTF8(nameFirst),
    nameLast: decodeUTF8(nameLast),
    email: decodeUTF8(email),
    bsgEmail: decodeUTF8(email),
    jobTitle: decodeUTF8(jobTitle),
    company: decodeUTF8(company),
    country: decodeUTF8(country)
  };
};

export const shouldShowStepInfo = user => {
  const name = user?.name;
  const uemail = user?.email;
  const countryName = user?.location?.country_name;
  const requiredFields = [name, uemail, countryName];
  return requiredFields.some(field => !field);
};

export const isProd = () => window.JOTFORM_ENV === 'PRODUCTION';
export const isDevelopment = () => window.JOTFORM_ENV === 'DEVELOPMENT';

export const readMilestoneJotformFieldValues = iframeDocument => ({
  firstName: iframeDocument?.querySelector('#first_4')?.value || '',
  lastName: iframeDocument?.querySelector('#last_4')?.value || '',
  email: iframeDocument?.querySelector('#input_5')?.value || '',
  country: iframeDocument?.querySelector('#input_7')?.value || '',
  state: iframeDocument?.querySelector('#input_8')?.value || iframeDocument?.querySelector('#input_12')?.value || iframeDocument.querySelector('#input_13')?.value || ''
});

export const getSourceLandingPage = () => {
  const { EDUCATION, NONPROFIT } = PRICING_TABLE_SOURCE_PAGES;
  if (window.location.pathname.includes(EDUCATION)) return EDUCATION;
  if (window.location.pathname.includes(NONPROFIT)) return NONPROFIT;
  return '';
};
export const snakeizePathname = () => window.location.pathname.replaceAll('/', '_').replaceAll('-', '_').slice(1, -1);

export const isGuest = user => (user?.accountType || user?.account_type?.name) === 'GUEST';

export const isScrollCloseToElement = (targetEl, offset = 250) => {
  if (!targetEl) return false;
  return (targetEl.getBoundingClientRect().top + targetEl.offsetHeight) - window.innerHeight - offset <= 0;
};

export const getLiveFormID = (isTestVariant, target) => {
  return (isTestVariant
    ? LEAD_FORM_MAP[target].LIVE
    : LEAD_FORM_MAP.COMMON.LIVE);
};

export const getDevFormID = (isTestVariant, target) => {
  return isTestVariant
    ? LEAD_FORM_MAP[target].DEV
    : LEAD_FORM_MAP.COMMON.DEV;
};

export async function getIsEnterpriseAIAgentChatActive(user) {
  if (user.enterpriseAIAgentMyAccount === '27521') {
    return true;
  }

  if (window.JOTFORM_ENV === 'ENTERPRISE' || user.enterpriseAIAgentMyAccount) {
    return false;
  }

  const abTestManager = new ABTestManager({
    user: user,
    isTestEnabled: true,
    testName: 'enterpriseAIAgentMyAccount',
    testVariantCode: '27521',
    controlVariantCode: '27511'
  });

  return abTestManager.isTestVariant();
}

export const isGenericEmail = email => {
  if (!email) return '';
  // eslint-disable-next-line max-len
  const freeDomains = ['aol', 'bellsouth', 'bigpond', 'bright', 'cfl', 'citlink', 'comcast', 'cox', 'earthlink', 'email', 'emailboxi', 'embarqmail', 'free', 'frontiernet', 'gmail', 'googlemail', 'hotmail', 'icloud', 'iinet', 'libero', 'live', 'mac', 'mail', 'me', 'mindspring', 'msn', 'myyahoo', 'naver', 'netscape', 'online', 'optonline', 'orange', 'outlook', 'privaterelay', 'proton', 'protonmail', 'sbcglobal', 'shaw', 'skynet', 'telenet', 'tiscali', 'verizon', 'virginmedia', 'windowslive', 'yahoo', 'yandex', 'ymail'];

  return freeDomains.some(domain => email.includes(`@${domain}.`));
};
