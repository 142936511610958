import React from 'react';
import ReactDOM from 'react-dom';
import { BlogDownloadBanner } from '../components';

const renderBlogCaseStudies = (props, rootEl) => {
  if (!rootEl) return;

  ReactDOM.render(
    <BlogDownloadBanner
      {...props}
    />,
    rootEl
  );
};

export default renderBlogCaseStudies;
