import { ASSET_IDENTIFIER } from './assetIdentifier';

const { ADMIN_CONSOLE_DARK_MODAL, ENTERPRISE_TEAMS_MODAL } = ASSET_IDENTIFIER.PRODUCT;

export const LEAD_FORM_MAP = {
  COMMON: { // with labels and the rest of it will have no label
    LIVE: '230813613795964',
    DEV: '242061587960966',
    AD_FORM_LIVE: '203132649531955',
    AD_FORM_DEV: '242062244074953'
  },
  [ENTERPRISE_TEAMS_MODAL]: {
    LIVE: '233313413335950',
    DEV: '240032774763961'
  },
  [ADMIN_CONSOLE_DARK_MODAL]: {
    LIVE: '240023409519955',
    DEV: '240032556858964'
  }
};
