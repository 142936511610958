export const ACTION_DATA = {
  // login flow
  LOGIN_FLOW_SIGNUP_EMAIL_SEEN: { action: 'seen', target: 'loginFlowStep-signup-email' },
  LOGIN_FLOW_LOGIN_SEEN: { action: 'seen', target: 'loginFlowStep-login' },
  LOGIN_FLOW_SWITCH_LOGIN_CLICK: { action: 'click', target: 'loginFlowSwitchButton-login' },
  LOGIN_FLOW_SWITCH_SIGNUP_CLICK: { action: 'click', target: 'loginFlowSwitchButton-signup' },
  // social buttons
  LOGIN_FLOW_GOOGLE_CLICK: { action: 'click', target: 'loginFlowStep-signup-google' },
  LOGIN_FLOW_MICROSOFT_CLICK: { action: 'click', target: 'loginFlowStep-signup-microsoft' },
  LOGIN_FLOW_FACEBOOK_CLICK: { action: 'click', target: 'loginFlowStep-signup-facebook' },
  LOGIN_FLOW_APPLE_CLICK: { action: 'click', target: 'loginFlowStep-signup-apple' },
  LOGIN_FLOW_SALESFORCE_CLICK: { action: 'click', target: 'loginFlowStep-signup-salesforce' },
  // others
  INDUSTRY_STEP_SEEN: { action: 'seen', target: 'industryStep' },
  INDUSTRY_STEP_SKIP_CLICK: { action: 'click', target: 'industryStep-skipButton' },
  INDUSTRY_STEP_FINISH_CLICK: { action: 'click', target: 'industryStep-finishButton' },
  LOGO_CLICK: { action: 'click', target: 'logo' },
  LANGUAGE_CHANGE: { action: 'change', target: 'languageSelect' }
};

export const SOCIAL_ACTION_DATA = {
  google: ACTION_DATA.LOGIN_FLOW_GOOGLE_CLICK,
  microsoft: ACTION_DATA.LOGIN_FLOW_MICROSOFT_CLICK,
  facebook: ACTION_DATA.LOGIN_FLOW_FACEBOOK_CLICK,
  apple: ACTION_DATA.LOGIN_FLOW_APPLE_CLICK,
  salesforce: ACTION_DATA.LOGIN_FLOW_SALESFORCE_CLICK
};
