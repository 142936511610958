export const ONBOARDING_ROOT_EL = '#onboarding-root';

export const STEP_TYPES = {
  LOGINFLOW: 'LOGINFLOW',
  INDUSTRY: 'INDUSTRY'
};

export const LOGIN_FLOW_SCREEN_TYPES = {
  SIGNUP: 'signupWithSocialOpts',
  LOGIN: 'loginOptions'
};

// TODO: fetch languages from api
export const availableLangs = [
  { value: 'en-US', label: 'English' },
  { value: 'es-ES', label: 'Español' },
  { value: 'fr-FR', label: 'Français' },
  { value: 'it-IT', label: 'Italiano' },
  { value: 'pt-PT', label: 'Português' },
  { value: 'de-DE', label: 'Deutsch' },
  { value: 'tr-TR', label: 'Türkçe' },
  { value: 'nl-NL', label: 'Nederlands' },
  { value: 'fi-FI', label: 'Suomi' },
  { value: 'pl-PL', label: 'Polski' },
  { value: 'ru-RU', label: 'Pусский - Beta' },
  { value: 'ja-JP', label: '日本語' },
  { value: 'bg-BG', label: 'български' },
  { value: 'sr-BA', label: 'Српски' },
  { value: 'ka-GE', label: 'ქართველი' },
  { value: 'id-ID', label: 'Indonesia' },
  { value: 'ar-AR', label: 'العربية' },
  { value: 'ko-KR', label: '한국어' },
  { value: 'zh-HK', label: '繁體中文 - Beta' },
  { value: 'af-ZA', label: 'Afrikaan - Beta' },
  { value: 'he-IL', label: 'Hebrew - Beta' }
];

export const DEFAULT_LANG = { value: 'en-US', label: 'English' };

// A/B Test: epSignupPageOnboarding
export const EP_ONBOARDING_TEST = 'epSignupPageOnboarding';
