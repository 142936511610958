import { useEffect, useRef, useState } from 'react';
import { fetchUser } from '../utils';

const checkIsUserLoggedIn = user => {
  if (!user) return false;
  if (user?.username === 'guest_apiDemo') return false;
  if (user?.account_type?.name !== 'GUEST') {
    return true;
  }
  return false;
};

const setUserToWindow = user => {
  if (!global.window.user) {
    global.window.user = user;
  }
  const event = new Event('jfheader-user-ready');
  global.window.dispatchEvent(event);
};

const exceptionallyOverwriteUserDataForFormUser = (user, isFormUser) => {
  if (!isFormUser) return;
  const usr = user;
  usr.username = user.email; // Form users don't have a username
  usr.usage = {};
  usr.account_type = { limits: {}, name: 'FORM_USER' };
};

const isFormUser = userType => userType === 'FORM_USER';

const useUser = (userAsProp, userTypeAsProp, isLoggedInAsProp) => {
  const userTypeRef = useRef('USER');
  const [user, setUser] = useState(userAsProp);
  const [userType, setUserType] = useState(userTypeAsProp);
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInAsProp || checkIsUserLoggedIn(userAsProp));

  useEffect(() => {
    const initUser = async () => {
      if (userAsProp) {
        setUserToWindow(user);
        exceptionallyOverwriteUserDataForFormUser(user, isFormUser(userTypeAsProp));
        return;
      }

      try {
        const { type, credentials } = await fetchUser() || {};
        userTypeRef.current = type;
        setUserToWindow(credentials);
        exceptionallyOverwriteUserDataForFormUser(credentials, isFormUser(type));
        setUser(credentials);
      } catch (e) {
        console.log('combinedInfo error', e);
      }
    };

    initUser();
  }, []);

  useEffect(() => {
    setUserType(userTypeAsProp || userTypeRef.current);
  }, [user]);

  useEffect(() => {
    if (!user) return;
    setIsLoggedIn(checkIsUserLoggedIn(user));
  }, [user]);

  return [user, isLoggedIn, userType];
};

export default useUser;
