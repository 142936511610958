export const AB_TEST = {
  // modals
  ADD_COLLABORATOR_MODAL: 'addCollaboratorModal',
  ADD_COLLABORATOR_BANNER: 'addColabBanner',
  ADMIN_CONSOLE_SINGLE_MODAL: 'adminConsoleSingleModal',
  ADMIN_CONSOLE_SLIDER_MODAL: 'adminConsoleSliderModal',
  ADVERTISEMENT_LEAD_FLOW_MODAL: 'adLeadFlowModal',
  AD_BANNER_MODAL: 'adBannerModal',
  AD_FIRST_EXIT_INTENT_MODAL: 'adFirstExitIntentModal',
  AD_SECOND_EXIT_INTENT_MODAL: 'adSecondExitIntentModal',
  APPLY_REAL_TIME_SUPPORT_MODAL: 'applyRealTimeSupportModal',
  ASSIGN_FORM_MODAL: 'assignFormModal',
  BLOG_CONTENT_BANNER_MODAL: 'blogContentBannerModal',
  BSG_EDUCATION_DISCOUNT_MODAL: 'bsgEducationDiscountModal',
  BSG_NONPROFIT_DISCOUNT_MODAL: 'bsgNonprofitDiscountModal',
  CONTACT_SUPPORT_MODAL: 'contactSupportModal',
  DEDICATED_SUPPORT_MODAL: 'dedicatedSupportModal',
  SINGLE_COLUMN_DEDICATED_SUPPORT_MODAL: 'singleColumnDedicatedSupportModal',
  EDUCATION_BDM_FLOW_MODAL: 'educationBdmFlowModal',
  EDUCATION_DISCOUNT_MODAL: 'educationDiscountModal',
  EDUCATION_DISCOUNT_MODAL_FERPA_VIOLATION_EXAMPLES: 'educationDiscountModalFerpaViolationExamples',
  ENHANCE_COLLAB_MODAL: 'enhanceCollaborationModal',
  FASTER_SUPPORT_RESPONSE_MODAL: 'fasterSupportResponseModal',
  FEATURE_SLIDER_MODAL: 'featureSliderModal',
  LOCAL_DATA_RESIDENCY_MODAL: 'localDataResidencyModal',
  PRICING_TABLE_MODAL: 'pricingTableModal',
  PRICING_TABLE_ISOLATED_MODAL: 'pricingTableIsolatedModal',
  SHARE_FOLDER_MODAL: 'shareFolderModal',
  SOC2_COMPLIANCE_MODAL: 'soc2ComplianceModal',
  SSO_PREFILL_MODAL: 'ssoPrefillModal',
  SSO_PREFILL_DETAILED_MODAL: 'ssoPrefillDetailedModal',
  PROFESSIONAL_SERVICES_MODAL: 'professionalServicesModal',
  REQUIRE_SSO_MODAL: 'requireSsoModal',
  SECURITY_PROPERTIES_MODAL: 'securityPropertiesModal',
  SYSTEM_LOGS_MODAL: 'systemLogsModal',
  ZOOM_SUPPORT_MODAL: 'zoomSupportModal',
  APPEXCHANGE_MODAL: 'appExchangeModal',
  TEAMS_WHITE_LABELING_MODAL: 'teamsWhiteLabelingModal',
  SCHEDULE_ZOOM_MODAL: 'scheduleZoomModal',
  // bsge plan discount modals
  BRONZE_PLAN_DISCOUNT_MODAL: 'bronzePlanDiscountModal',
  SILVER_PLAN_DISCOUNT_MODAL: 'silverPlanDiscountModal',
  GOLD_PLAN_DISCOUNT_MODAL: 'goldPlanDiscountModal',
  ENTERPRISE_PLAN_DISCOUNT_MODAL: 'enterprisePlanDiscountModal',
  // banners
  ACTIVE_SUBMISSON_BANNER: 'activeSubmissionBanner',
  ADMIN_CONSOLE_BANNER: 'adminConsoleBanner',
  ADD_AND_MANAGE_USERS_BANNER_ALT: 'addAndManageUsersBannerAlt',
  ADVERTISEMENT_LEAD_FLOW_BANNER: 'advertisementLeadFlowBanner',
  APPLY_REAL_TIME_SUPPORT_BANNER: 'applyRealTimeSupportBanner',
  ASSIGN_FORM_BANNER: 'assignFormBanner',
  BLOG_CONTENT_BOOST_BANNER: 'blogContentBoostBanner',
  BLOG_CONTENT_SPEED_UP_BANNER: 'blogContentSpeedUpBanner',
  BLOG_EDUCATION_CONTENT_BANNER: 'blogEducationContentBanner',
  BLOG_CONTENT_SIDE_BANNER: 'blogContentSideBanner',
  CUSTOM_DOMAIN_TOOLTIP_BANNER: 'customDomainTooltip',
  EDUCATION_BDM_FLOW_BANNER: 'educationBdmFlowBanner',
  ENTERPRISE_BILLING_BANNER: 'enterpriseBillingBanner',
  ENTERPRISE_SECURITY_ITEMS_SECTION: 'enterpriseSecurityItemsSection',
  ENTERPRISE_WHITE_LABELING_BANNER: 'enterpriseWhiteLabelingBanner',
  ENTERPRISE_EDUCATION_DISCOUNT_MODAL: 'enterpriseEducationDiscountModal',
  ENTERPRISE_NONPROFIT_DISCOUNT_MODAL: 'enterpriseNonprofitDiscountModal',
  FASTER_SUPPORT_RESPONSE_BANNER: 'fasterSupportResponseBanner',
  FASTER_SUPPORT_RESPONSE_TOGGLE_BANNER: 'fasterSupportResponseToggleBanner',
  FULLY_CUSTOMIZE_BANNER: 'fullyCustomizeBanner',
  IP_RESTRICTION_BANNER: 'ipRestrictionBanner',
  LOCAL_DATA_RESIDENCY_ALT_BANNER: 'localDataResidencyAltBanner',
  MYFORMS_WHITE_LABELING_TOOLTIP_BANNER: 'whiteLabelingTooltipBanner',
  MY_ACCOUNT_STORE_DATA_IN_AU_BANNER: 'storeDataInAuBanner',
  MOBILE_FOOTER_CONTACT_BANNER: 'mobileFooterContactBanner',
  PUSH_DAY_BANNER: 'profSolutionsBanner',
  PROFESSIONAL_SERVICES_TOOLTIP: 'professionalServicesTooltip',
  PROFESSIONAL_SERVICES_CONTACT_BANNER: 'professionalServicesContactBanner',
  SHARE_PANEL_COLLAB_BANNER: 'sharePanelCollabBanner',
  SSO_ACCESS_BANNER: 'ssoAccessBanner',
  SSO_PREFILL_BANNER: 'ssoPrefillBanner', // live
  SYSTEM_LOGS_BANNER: 'systemLogsBanner',
  SYSTEM_LOGS_TINY_BANNER: 'systemLogsTinyBanner',
  DEDICATED_SUPPORT_BANNER_V2: 'dedicatedSupportBannerV2',
  SCHEDULE_ZOOM_BANNER: 'scheduleZoomBanner',
  SOC2_COMPLIANCE_BANNER: 'soc2ComplianceBanner',
  // sections
  ACCESS_CONTROL_SECTION: 'accessControlSection',
  ASSIGN_TO_EVERYONE_PROMOTION: 'assignToEveryonePromotionSection',
  ASSIGN_TO_EVERYONE_PROMOTION_ALT: 'assignToEveryonePromotionSectionAlt',
  ENTERPRISE_CHECKOUT_LIKE_SECTION: 'enterpriseCheckoutLikeSection', // live
  USERS_SECTION: 'usersSection',
  USERS_SECTION_ALT: 'usersSectionAlt',
  WHITE_LABELING_SECTION: 'whiteLabelingSection',
  // buttons
  EXPANDABLE_ADMIN_CONSOLE_BUTTON: 'expandableAdminConsoleButton',
  FASTER_SUPPORT_RESPONSE_TOGGLE_BUTTON: 'fasterSupportResponseToggleButton',
  ZOOM_MEETING_BUTTON: 'zoomMeetingButton',
  ASSIGN_TO_ORGANIZATION_BUTTON: 'assignToOrganizationButton',
  // badges
  ENTERPRISE_FEATURE_BADGE: 'enterpriseFeatureBadge'
};
