import React, { useEffect, useState } from 'react';
import {
  bool, func, number, string
} from 'prop-types';
import { DEFAULT_NAV_ITEMS } from '@jotforminc/constants';

import NavItem from './NavItem';
import { navItemsPropType, userPropType } from '../../propTypes';
import { CONTACT_SALES_AB_TEST_VARIATIONS } from '../../constants';
import { ensureTrailingSlash, isSamePath } from '../../utils';

const NavItems = ({
  user,
  navItems,
  logHeaderAction,
  currentPath,
  isLoggedIn,
  isMobileMenuActive,
  unreadSupportAnswerCount,
  showAiAgentBanner,
  contactSalesAbTestVariation,
  onMobileMenuClose,
  isEnterprise,
  isAcademyAppsCourseReleased
}) => {
  const [openedMenu, setOpenedMenu] = useState('');
  const { CONTACT_SALES_AS_ITEM, NO_INTEGRATIONS_MENU } = CONTACT_SALES_AB_TEST_VARIATIONS;

  const selectNavItems = () => {
    if (navItems === false) return [];
    if (typeof navItems === 'undefined' || navItems?.length === 0) return DEFAULT_NAV_ITEMS;
    return navItems;
  };

  let items = [...selectNavItems()];

  // A/B Test: contactSalesButtonOnHeader
  if ([CONTACT_SALES_AS_ITEM, NO_INTEGRATIONS_MENU].includes(contactSalesAbTestVariation)) {
    items = items.filter(item => item.name !== 'integrations');
  }
  const activePageMenuItem = items.find(({ menuProps }) => menuProps?.menuItems.some(({ url: itemUrl }) => isSamePath(itemUrl, currentPath)));

  useEffect(() => {
    if (activePageMenuItem?.linkProps?.title && isMobileMenuActive) {
      setOpenedMenu(activePageMenuItem.linkProps.title);
    }
  }, [isMobileMenuActive, activePageMenuItem]);

  const hasAnyItemMatchingWithCurrentPath = items.some(({ linkProps: { url = '' } } = {}) => {
    return ensureTrailingSlash(url) === ensureTrailingSlash(currentPath);
  });

  return (
    <>
      {items.map(({ name, linkProps, menuProps }) => (
        <NavItem
          key={name}
          user={user}
          linkProps={linkProps}
          menuProps={menuProps}
          isEnterprise={isEnterprise}
          isLoggedIn={isLoggedIn}
          openedMenu={openedMenu}
          setOpenedMenu={setOpenedMenu}
          currentPath={currentPath}
          isPricing={name === 'pricing'}
          logHeaderAction={logHeaderAction}
          showAiAgentBanner={showAiAgentBanner}
          isMobileMenuActive={isMobileMenuActive}
          unreadSupportAnswerCount={unreadSupportAnswerCount}
          hasAnyItemMatchingWithCurrentPath={hasAnyItemMatchingWithCurrentPath}
          onMobileMenuClose={onMobileMenuClose}
          isAcademyAppsCourseReleased={isAcademyAppsCourseReleased}
        />
      ))}
    </>
  );
};

NavItems.propTypes = {
  user: userPropType,
  navItems: navItemsPropType,
  logHeaderAction: func,
  currentPath: string.isRequired,
  isLoggedIn: bool,
  isMobileMenuActive: bool,
  unreadSupportAnswerCount: number,
  showAiAgentBanner: bool.isRequired,
  contactSalesAbTestVariation: string.isRequired,
  isEnterprise: bool.isRequired,
  onMobileMenuClose: func.isRequired,
  isAcademyAppsCourseReleased: string
};

NavItems.defaultProps = {
  user: undefined,
  navItems: DEFAULT_NAV_ITEMS,
  logHeaderAction: () => {},
  isLoggedIn: undefined,
  isMobileMenuActive: false,
  unreadSupportAnswerCount: 0,
  isAcademyAppsCourseReleased: ''
};

export default NavItems;
