import React, { useState } from 'react';
import { t } from '@jotforminc/translation';
import { InputText, Radio } from '@jotforminc/magnet';
import cx from 'classnames';
import { useEpOnboarding } from '../../contexts';
import { TEXTS } from '../../constants';
import { Questions } from './Questions';
import { isValidWebsite } from '../../utils';

export const OrganizationInfoStep = () => {
  const [websiteValidationError, setWebsitelValidationError] = useState('');
  const {
    state: { onboardData }, api: { setOnboardData, logAbTestAction }
  } = useEpOnboarding();

  const ORGANIZATION_SIZES = [TEXTS.SIZE_JUST_ME, TEXTS.SIZE_LEVEL1, TEXTS.SIZE_LEVEL2, TEXTS.SIZE_LEVEL3];

  const handleOrganizationInfoFill = e => {
    const { name, value } = e.target;

    if (name === 'organizationSize') {
      logAbTestAction({ action: 'select', target: `organizationSize-${value}` });
    }

    setOnboardData({ [name]: value });
  };

  const validateWebsite = () => {
    if (!isValidWebsite(onboardData.organizationWebsite)) {
      setWebsitelValidationError(t(TEXTS.ORGANIZATION_WEBSITE_VALIDATION));
    } else {
      setWebsitelValidationError('');
    }
  };

  return (
    <section>
      <Questions title={TEXTS.TELL_US_ABOUT_YOUR_ORGANIZATION} description={TEXTS.TELL_US_ABOUT_YOUR_ORGANIZATION_DESCRIPTION} />
      <div className='formItem'>
        <label className='label'>{t(TEXTS.ORG_NAME)}</label>
        <InputText
          id='organization-name'
          className={cx('max-w-96 mt-2', {
          })}
          placeholder={t(TEXTS.TELL_US_ABOUT_YOUR_ORGANIZATION_PLACEHOLDER)}
          value={onboardData.organizationName}
          onChange={handleOrganizationInfoFill}
          name='organizationName'
        />
      </div>

      <div className='formItem'>
        <label className='label'>{t(TEXTS.ORGANIZATION_WEBSITE)}</label>
        <InputText
          className={cx('max-w-96 mt-2', {
          })}
          placeholder={t(TEXTS.ORGANIZATION_WEBSITE_PLACEHOLDER)}
          value={onboardData.organizationWebsite}
          onChange={handleOrganizationInfoFill}
          name='organizationWebsite'
          onBlur={validateWebsite}
        />
        {websiteValidationError ? <p className="inline-flex w-max px-2 py-1 bg-red-400 color-white text-xs m-0 radius">{websiteValidationError}</p> : null}
      </div>

      <div className='formItem'>
        <label className='label'>{t(TEXTS.SIZE_OF_ORGANIZATION)}</label>
        <div className='options-wrapper'>
          <div className='narrow-radio-wrapper'>
            {ORGANIZATION_SIZES.map(size => (
              <Radio
                key={size}
                size='small'
                label={t(size)}
                value={size}
                name='organizationSize'
                checked={onboardData.organizationSize === size}
                onChange={handleOrganizationInfoFill}
                className={cx('radio-item', {
                  selected: onboardData.organizationSize === size
                })}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
