import { useEffect, useState } from 'react';
import { Hooks } from '@jotforminc/uikit';

const useStickyHeader = (
  activateBehaviour = false,
  scrollTreshold = 0,
  themeProp,
  stickyModeThemeProp
) => {
  const [isSticky, setIsSticky] = useState(false);
  const [theme, setTheme] = useState(themeProp);

  useEffect(() => {
    if (theme !== themeProp) {
      setTheme(themeProp);
    }
  }, [themeProp]);

  Hooks.useEffectIgnoreFirst(() => {
    if (!stickyModeThemeProp) return;
    if (isSticky) {
      setTheme(stickyModeThemeProp);
    } else {
      setTheme(themeProp);
    }
  }, [isSticky]);

  useEffect(() => {
    const rootEl = document.querySelector('html');
    const CLASS_NAME = 'jfHeader-sticky-on'; // use this className for backwards compatibility

    const handleScroll = () => {
      if (!activateBehaviour) return;
      const isTresholdExceeded = rootEl?.scrollTop > scrollTreshold;
      setIsSticky(isTresholdExceeded);

      if (isTresholdExceeded) {
        rootEl?.classList.add(CLASS_NAME);
      } else {
        rootEl?.classList.remove(CLASS_NAME);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [isSticky, theme];
};

export default useStickyHeader;
