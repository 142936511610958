import React from 'react';
import { createRoot } from 'react-dom/client';
import { getRootElement } from './utils/utils';
import { ONBOARDING_ROOT_EL } from './constants';
import OnboardingV2 from './OnboardingV2';

// A/B Test: epSignupPageOnboarding
export const initEpOnboarding = ({ selector = ONBOARDING_ROOT_EL, ...props } = {}) => {
  const rootEl = getRootElement(selector);
  const root = createRoot(rootEl);

  root.render(
    <OnboardingV2 {...props} />
  );
};

export default initEpOnboarding;
