import React from 'react';
import ReactDOM from 'react-dom';
import { getRootElement } from './utils';

const renderAsset = ({ Asset, rootElementId, ...props }) => {
  const rootEl = getRootElement(rootElementId);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(rootEl);
  };

  ReactDOM.render(
    <Asset onClose={handleClose} {...props} />,
    rootEl
  );
};

export default renderAsset;
