export const toggleSignupLoaders = (act = 'hide') => {
  document.querySelectorAll('.signup-loading').forEach(el => {
    const elRef = el;
    if (act === 'hide') {
      elRef.style.display = 'none';
      elRef.parentElement.classList.remove('socialbtn-loading-wrapper');
    } else if (act === 'show') {
      elRef.style.display = 'block';
      elRef.parentElement.classList.add('socialbtn-loading-wrapper');
    }
  });
};
