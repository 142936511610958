import React from 'react';
import { bool, string } from 'prop-types';
import { AccountBoxAvatar } from '../AccountBoxAvatar';
import LoginGroupPreLoader from './LoginGroupPreLoader';

const PreLoaders = ({
  isLoggedIn,
  username,
  avatarUrl,
  hideLoginButton,
  hideSignupButton,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL
}) => {
  return (
    <>
      {isLoggedIn ? (
        <AccountBoxAvatar username={username} avatarUrl={avatarUrl} />
      ) : (
        <LoginGroupPreLoader
          hideLoginButton={hideLoginButton}
          hideSignupButton={hideSignupButton}
          contactSalesAbTestVariation={contactSalesAbTestVariation}
          contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
        />
      )}
    </>
  );
};

PreLoaders.propTypes = {
  isLoggedIn: bool.isRequired,
  username: string,
  avatarUrl: string,
  hideLoginButton: bool.isRequired,
  hideSignupButton: bool.isRequired,
  contactSalesAbTestVariation: string.isRequired,
  contactSalesAbTestVariationURL: string.isRequired
};

export default PreLoaders;
