export const TEXTS = {
  ENTERPRISE: 'Enterprise',
  HIPAA: 'HIPAA',
  HIPAA_COMPLIANCE: 'HIPAA Compliance',
  GOVERNMENT: 'Government',
  SEE_100_INTEGRATIONS: 'See 100+ integrations',
  MY_FORMS: 'My Forms',
  PRICING: 'Pricing',
  SEE_MORE_FEATURES: 'See more features',
  SUPPORT: 'Support',
  ALL_TEAMS: 'All Teams'
};
