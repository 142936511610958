import React, {
  useContext, useEffect, useCallback, Suspense, lazy, useState, useMemo
} from 'react';
import {
  string, bool, object
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  HeaderBody, themePropType, stickyModeThemePropType, USER_TYPES, PreLoaders,
  logoPropType, logoSideLinkPropType, navItemsPropType, menuStylesPropType
} from '@jotforminc/header-body';
import { getUrlParameter } from '@jotforminc/utils';
import { GrowthAssetManager } from '@jotforminc/growth-asset-manager';
import { initLanguageSwitcherTest } from '../utils/abTests/index';
import {
  runFooterCustomFunction,
  toggleDocumentOverflowProperty,
  attachObsoleteSignupLoadingFunctionToWindow,
  addBrowserClassNames,
  exceptionallyOverwriteUserDataForFormUser,
  isAiAgentBannerAvailable,
  showLoginFlowModal,
  yieldToMain,
  initLoginFlowTrioButtons,
  listenAppSignInOnFailure,
  initGoogleOneTap,
  getUserAccountType
} from '../utils';

import {
  useStickyHeader, useEnterprisePromotions, useLazyIntegrationImages, useLazyLoginGroup
} from '../hooks';

import { ModeContext } from './Providers/ModeProvider';
import { UserContext } from './Providers/UserProvider';
import { MobileMenuContext } from './Providers/MobileMenuProvider';
import { JotformActionsContext } from './Providers/JotformActionsProvider';

import LanguageSwitcher from './LanguageSwitcher';
import DeletedFormNotification from './DeletedFormNotification';

import '../styles/header.scss';

const AppPickerBox = lazy(() => import(/* webpackChunkName: "AppPickerBoxComponent" */ './AppPickerBox'));
const LoginGroup = lazy(() => import(/* webpackChunkName: "LoginGroupComponent" */ './LoginGroup/LoginGroup'));

const Header = ({
  fullWidth,
  logoProps,
  logoSideLinkProps,
  customNavItems,
  appPickerProps,
  disableLoginFlow,
  hideLoginButton,
  hideSignupButton,
  theme: themeProp,
  stickyModeTheme: stickyModeThemeProp,
  bgColor,
  hideAccountBox,
  replaceAuthButtons,
  replaceAuthButtonsLocalized,
  language,
  currentPath,
  menuStyles,
  isEnterprise,
  languageSwitcher,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL,
  hostname,
  isAcademyAppsCourseReleased,
  ...props
}) => {
  const [loadLoginGroup, lazyLoadEvent] = useLazyLoginGroup();
  const [isSticky, theme] = useStickyHeader(useContext(ModeContext), 0, themeProp, stickyModeThemeProp);
  const [languageSwitcherVisible, setLanguageSwitcherVisible] = useState(false);

  const { logHeaderAction } = useContext(JotformActionsContext);
  const { user, userType, isLoggedIn } = useContext(UserContext);
  const { isMobileMenuActive, isMobileScreen, onMobileMenuButtonClick } = useContext(MobileMenuContext);

  const disableAccountBox = hideAccountBox || !isLoggedIn;
  const { isAppPickerEnabled = false, appPickerItems = [] } = appPickerProps;

  // FIXME: this is a temporary fix for showing flying to enterprise env. It has to be managed by GAM backend according by asset type.
  const customCampaignInfo = !global?.window?.campaignInfo && isEnterprise
    ? {
      launch: {
        status: true,
        type: 'WORKFLOWS',
        assetsVersion: 'v1',
        assetsAvailable: true,
        assetYear: '2024',
        expires: []
      }
    }
    : undefined;

  attachObsoleteSignupLoadingFunctionToWindow();
  useEnterprisePromotions(user);
  useLazyIntegrationImages();
  useEffect(() => {
    addBrowserClassNames();
  }, []);

  useEffect(() => {
    toggleDocumentOverflowProperty(isMobileMenuActive);
  }, [isMobileMenuActive]);

  useEffect(() => {
    if (!user || !loadLoginGroup) return;
    initLoginFlowTrioButtons();
    listenAppSignInOnFailure();
    if (!disableLoginFlow) {
      initGoogleOneTap(lazyLoadEvent, user);
    }
  }, [user, loadLoginGroup, lazyLoadEvent, disableLoginFlow]);

  useEffect(() => {
    if (!user) return;
    runFooterCustomFunction(user);
    exceptionallyOverwriteUserDataForFormUser(user, userType === USER_TYPES.FORM_USER);
    initLanguageSwitcherTest(user, languageSwitcher, setLanguageSwitcherVisible);
  }, [user]);

  const AppPickerRenderer = useCallback(() => {
    if (isAppPickerEnabled && !isEmpty(user) && appPickerItems.length > 0) {
      return (
        <Suspense fallback=''>
          <AppPickerBox user={user} {...appPickerProps} />
        </Suspense>
      );
    }
    return <></>;
  }, [user]);

  const handleSignupButtonMobileClick = async () => {
    await yieldToMain();
    logHeaderAction?.({ action: 'click', target: 'header-signup' });
    showLoginFlowModal('s1');
  };

  const preLoaders = useMemo(() => {
    return (
      <PreLoaders
        isLoggedIn={isLoggedIn}
        username={user?.username}
        avatarUrl={user?.avatarUrl}
        hideLoginButton={hideLoginButton}
        hideSignupButton={hideSignupButton}
        contactSalesAbTestVariation={contactSalesAbTestVariation}
        contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
      />
    );
  }, [isLoggedIn, user]);

  const LoginGroupRenderer = useCallback(() => {
    if (disableLoginFlow || replaceAuthButtons) return <></>;
    if (!loadLoginGroup) return preLoaders;
    return (
      <Suspense fallback={preLoaders}>
        <LoginGroup
          {...props}
          hostname={hostname}
          currentPath={currentPath}
          lazyLoadEvent={lazyLoadEvent}
          hideLoginButton={hideLoginButton}
          hideSignupButton={hideSignupButton}
          hideAccountBox={disableAccountBox}
          contactSalesAbTestVariation={contactSalesAbTestVariation}
          contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
        />
      </Suspense>
    );
  }, [loadLoginGroup, preLoaders]);

  const updateFavicon = dark => {
    const icon = `favicon-2021-${dark ? 'dark' : 'light'}.png`;
    const link = document.querySelector('[rel="shortcut icon"]');
    if (link) link.href = `https://cdn.jotfor.ms/assets/img/favicons/${icon}`;
  };

  useEffect(() => {
    if (isEnterprise) return;
    const mediaQuery = global.window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = e => updateFavicon(e.matches);

    if (mediaQuery) {
      updateFavicon(mediaQuery.matches);
      mediaQuery.addListener(handleChange);
    }

    return () => mediaQuery.addListener(handleChange);
  }, []);

  const showDeletedFormNotificationVisible = getUrlParameter('utm_campaign') === 'form-deleted';

  return (
    <HeaderBody
      user={user}
      theme={theme}
      bgColor={bgColor}
      language={language}
      isSticky={isSticky}
      logoProps={logoProps}
      fullWidth={fullWidth}
      isLoggedIn={isLoggedIn}
      currentPath={currentPath}
      isMobileScreen={isMobileScreen}
      customNavItems={customNavItems}
      disableLoginFlow={disableLoginFlow}
      hideLoginButton={hideLoginButton}
      hideSignupButton={hideSignupButton}
      logoSideLinkProps={logoSideLinkProps}
      isMobileMenuActive={isMobileMenuActive}
      replaceAuthButtons={replaceAuthButtons}
      replaceAuthButtonsLocalized={replaceAuthButtonsLocalized}
      onMobileMenuButtonClick={onMobileMenuButtonClick}
      onMobileSignupButtonClick={handleSignupButtonMobileClick}
      AppPickerRenderer={AppPickerRenderer}
      LoginGroupRenderer={LoginGroupRenderer}
      logHeaderAction={logHeaderAction}
      menuStyles={menuStyles}
      isEnterprise={isEnterprise}
      showAiAgentBanner={isAiAgentBannerAvailable(user)}
      contactSalesAbTestVariation={contactSalesAbTestVariation}
      contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
      hostname={hostname}
      isAcademyAppsCourseReleased={isAcademyAppsCourseReleased}
    >
      <>
        {!showDeletedFormNotificationVisible && !isMobileMenuActive && (
          <>
            {languageSwitcherVisible ? (
              <LanguageSwitcher
                isSticky={isSticky}
                languageSwitcher={languageSwitcher}
                setLanguageSwitcherVisible={setLanguageSwitcherVisible}
              />
            ) : (
              <GrowthAssetManager
                assetType="flyin"
                assetProps={{ isSticky, user }}
                campaignInfo={customCampaignInfo}
              />
            )}
            <GrowthAssetManager assetType="exit-modal" assetProps={{ accountTypeName: getUserAccountType(user), user }} />
          </>
        )}
        {!isMobileMenuActive && showDeletedFormNotificationVisible && (
          <DeletedFormNotification isSticky={isSticky} defaultVisible={true} />
        )}
      </>
    </HeaderBody>
  );
};

Header.defaultProps = {
  isAcademyAppsCourseReleased: ''
};

Header.propTypes = {
  logoProps: logoPropType.isRequired,
  logoSideLinkProps: logoSideLinkPropType.isRequired,
  customNavItems: navItemsPropType.isRequired,
  fullWidth: bool.isRequired,
  appPickerProps: object.isRequired,
  disableLoginFlow: bool.isRequired,
  hideLoginButton: bool.isRequired,
  hideSignupButton: bool.isRequired,
  theme: themePropType.isRequired,
  bgColor: string.isRequired,
  hideAccountBox: bool.isRequired,
  replaceAuthButtons: string.isRequired,
  replaceAuthButtonsLocalized: string.isRequired,
  language: string.isRequired,
  currentPath: string.isRequired,
  menuStyles: menuStylesPropType.isRequired,
  isEnterprise: bool.isRequired,
  languageSwitcher: object.isRequired,
  contactSalesAbTestVariation: string.isRequired,
  contactSalesAbTestVariationURL: string.isRequired,
  hostname: string.isRequired,
  stickyModeTheme: stickyModeThemePropType.isRequired,
  isAcademyAppsCourseReleased: string
};

export default Header;
