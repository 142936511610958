import { ABTestManager } from '@jotforminc/abtest-manager';
import { readCookie } from '../jotCookie';

export const initLanguageSwitcherTest = async (user, languageSwitcher, setLanguageSwitcherVisible) => {
  if (!languageSwitcher || !languageSwitcher.browserLanguage || !languageSwitcher.domainLanguage) return;

  const { pathname = '' } = global?.window?.location || {};
  if (!pathname.includes('/form-templates')) return;
  if (!document) return;

  const languagesSwitcherDiscarded = readCookie('LANGUAGE_SWITCHER:Flyin') === 'true';
  if (languagesSwitcherDiscarded) return;

  const languageList = Array.from(document.querySelectorAll('link'))
    .filter(link => link.getAttribute('hreflang') && link.getAttribute('hreflang') !== 'x-default')
    .map(lang => lang.getAttribute('hreflang'));

  const {
    browserLanguage, domainLanguage
  } = languageSwitcher;

  const isSwitchLanguageAvailable = languageList.find(lang => lang === browserLanguage.split('-')[0]);
  if (browserLanguage !== domainLanguage && isSwitchLanguageAvailable) {
    const abTestManager = new ABTestManager({
      user,
      isTestEnabled: true,
      testVariantCode: '28502',
      controlVariantCode: '28492',
      urlParam: 'languageSwitcherAB',
      testName: 'languageSwitcherFormTemplates'
    });
    try {
      const isTestVariantNewUser = await abTestManager.isTestVariant();
      if (isTestVariantNewUser) {
        const logAbTestActionLangSwitcher = abTestManager.registerABTestAction;
        global.window.logAbTestActionLangSwitcher = logAbTestActionLangSwitcher;
        setLanguageSwitcherVisible(true);
      }
    } catch (error) {
      //
    }
  }
};
