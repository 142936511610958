import React from 'react';
import { func } from 'prop-types';

import { t } from '@jotforminc/translation';

const SignupButtonMobile = ({ onClick }) => (
  <button
    type="button"
    className="jfRHeader--mobile-nav-signup-btn locale"
    onClick={onClick}
  >
    {t('Sign Up')}
  </button>
);

SignupButtonMobile.propTypes = {
  onClick: func
};

SignupButtonMobile.defaultProps = {
  onClick: f => f
};

export default SignupButtonMobile;
