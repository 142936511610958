import React, { useEffect, useRef } from 'react';
import { t } from '@jotforminc/translation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import cx from 'classnames';
import JFLogo from '../assets/svg/jfLogo.svg';
import Line from '../assets/svg/lineV2.svg';
import { CONTENTS_V2 } from '../constants';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import InfoCard from './InfoCard';
import { useEpOnboarding } from '../contexts';

const ContentV2 = () => {
  const { state: { user, currentStep } } = useEpOnboarding();
  const swiperRef = useRef(null);

  useEffect(() => {
    swiperRef.current.swiper.slideTo(currentStep);
  }, [currentStep]);

  const renderHeaderMessage = (data, userName) => {
    if (data.name === 'Hi') {
      return `Hi${userName ? ` ${userName}` : ''}!`;
    }
    return `${data.name}${userName ? ` ${userName}` : ''}`;
  };

  return (
    <Swiper
      className="allSteps--content-swiper"
      effect="fade"
      simulateTouch={false}
      autoplay={false}
      initialSlide={currentStep}
      breakpoints={{
        1024: {
          loop: true
        }
      }}
      modules={[EffectFade, Autoplay]}
      onSlideChange={swiper => {
        document.documentElement.setAttribute('style', `--jf-onboarding-color: ${CONTENTS_V2[swiper.realIndex]?.color}`);
      }}
      ref={swiperRef}
    >
      {CONTENTS_V2.map(data => (
        <SwiperSlide key={data.key}>
          <div
            className={cx('jfOnboarding--content-v2', data.wrapperClassName && `${data.wrapperClassName}`)}
          >
            {data?.title && (
              <h2 className="jfOnboarding--content-title v2">
                <span>
                  {renderHeaderMessage(data, user?.name)}
                </span>
                <strong>{t(data.title)}</strong>
              </h2>
            )}
            {data?.image && (
              <div className="jfOnboarding--content-image">
                <img src={data.image} alt={data.name} />
              </div>
            )}
            {data?.bg_image && (
              <div className="jfOnboarding--content-bg-image">
                <img src={data.bg_image} alt={t(data.name)} />
              </div>
            )}
            {(data?.isOrganizationStep || data?.username) && (
              <InfoCard
                name={user?.name}
                isTeam={data?.isTeam}
                isIndividual={data?.isIndividual}
                isHalfVisible={data?.isHalfVisible}
                isOrganizationStep={data?.isOrganizationStep}
                shadowImage={data?.shadow_image}
              />
            )}

            <div className="jfOnboarding--content-line" aria-hidden="true">
              <Line />
            </div>
            <div className="jfOnboarding--content-jflogo" aria-hidden="true">
              <JFLogo />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ContentV2;
