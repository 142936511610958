import { MENU_TYPES } from './menuTypes';

const INTEGRATIONS = {
  FEATURED_INTEGRATIONS: 'featuredIntegrations',
  CRM: 'crm',
  STORAGE: 'storage',
  PAYMENT: 'payment',
  EMAIL: 'email'
};

const featuredIntegrationsLinks = [
  {
    name: 'featuredMicrosoftTeams',
    title: 'Microsoft Teams',
    url: '/integrations/microsoft-teams',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/microsoft-teams.svg'
  },
  {
    name: 'featuredPaypal',
    title: 'PayPal',
    url: '/integrations/paypal-business',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paypal.svg'
  },
  {
    name: 'featuredSlack',
    title: 'Slack',
    url: '/integrations/slack',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/slack.svg'
  },
  {
    name: 'featuredGoogleSheets',
    title: 'Google Sheets',
    url: '/integrations/google-sheets',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-sheets.svg'
  },
  {
    name: 'featuredMailchimp',
    title: 'Mailchimp',
    url: '/integrations/mailchimp',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/mailchimp.svg'
  },
  {
    name: 'featuredZoom',
    title: 'Zoom',
    url: '/integrations/zoom',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/zoom.svg'
  },
  {
    name: 'featuredDropbox',
    title: 'Dropbox',
    url: '/integrations/dropbox',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/dropbox.svg'
  },
  {
    name: 'featuredGoogleCalendar',
    title: 'Google Calendar',
    url: '/integrations/google-calendar',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-calendar.svg'
  },
  {
    name: 'featuredSalesforce',
    title: 'Salesforce',
    url: '/integrations/salesforce',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/salesforce.svg'
  }
];

const crmLinks = [
  {
    name: 'salesforce',
    title: 'Salesforce',
    url: '/integrations/salesforce',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/salesforce.svg'
  },
  {
    name: 'hubspot',
    title: 'Hubspot',
    url: '/integrations/hubspot',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/hubspot.svg'
  },
  {
    name: 'zohoCrm',
    title: 'Zoho CRM',
    url: '/integrations/zoho-crm',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/zoho.svg'
  },
  {
    name: 'activeCampaign',
    title: 'Active Campaign',
    url: '/integrations/activeCampaign',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/active-campaign.svg'
  },
  {
    name: 'pipedrive',
    title: 'Pipedrive',
    url: '/integrations/pipedrive',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/pipedrive.svg'
  },
  {
    name: 'zendek',
    title: 'Zendesk',
    url: '/integrations/zendesk',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/zendesk.svg'
  },
  {
    name: 'insightly',
    title: 'Insightly',
    url: '/integrations/insightly',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/insightly.svg'
  },
  {
    name: 'keap',
    title: 'Keap',
    url: '/integrations/keap',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/keap.svg'
  },
  {
    name: 'engageBay',
    title: 'EngageBay',
    url: '/integrations/engageBay',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/engagebay.svg'
  }
];

const storageLinks = [
  {
    name: 'googleDrive',
    title: 'Google Drive',
    url: '/integrations/google-drive',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-drive.svg'
  },
  {
    name: 'dropbox',
    title: 'Dropbox',
    url: '/integrations/dropbox',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/dropbox.svg'
  },
  {
    name: 'oneDrive',
    title: 'OneDrive',
    url: '/integrations/onedrive',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/onedrive.svg'
  },
  {
    name: 'box',
    title: 'Box',
    url: '/integrations/box',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/box.svg'
  },
  {
    name: 'egnyte',
    title: 'Egnyte',
    url: '/integrations/egnyte',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/egnyte.svg'
  },
  {
    name: 'ftp',
    title: 'FTP',
    url: '/integrations/ftp',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/ftp.png'
  },
  {
    name: 'galleria',
    title: 'Galleria',
    url: '/integrations/galleria',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/galleria.png'
  }
];

const paymentLinks = [
  {
    name: 'paypalPersonal',
    title: 'Paypal Personal',
    url: '/integrations/paypal-personal',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paypal.svg'
  },
  {
    name: 'square',
    title: 'Square',
    url: '/integrations/square',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/square.svg'
  },
  {
    name: 'paypalBusiness',
    title: 'Paypal Business',
    url: '/integrations/paypal-business',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paypal.svg'
  },
  {
    name: 'stripe',
    title: 'Stripe',
    url: '/integrations/stripe',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/stripe.svg'
  },
  {
    name: 'authorizeNet',
    title: 'Authorize.Net',
    url: '/integrations/authorize-net',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/authorize-net-v2.svg'
  },
  {
    name: 'applePayandGooglePay',
    title: 'Apple Pay & Google Pay',
    url: '/integrations/apple-pay-google-pay',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/apple-pay-google-pay.svg'
  },
  {
    name: 'paysafe',
    title: 'Paysafe',
    url: '/integrations/paysafe',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paysafe.svg'
  },
  {
    name: 'mollie',
    title: 'Mollie',
    url: '/integrations/mollie',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/mollie.svg'
  },
  {
    name: 'venmo',
    title: 'Venmo',
    url: '/integrations/venmo',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/venmo.svg'
  }
];

const emailLinks = [
  {
    name: 'constantContact',
    title: 'Constant Contact',
    url: '/integrations/constant-contact',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/constant-contact.svg'
  },
  {
    name: 'mailchimp',
    title: 'Mailchimp',
    url: '/integrations/mailchimp',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/mailchimp.svg'
  },
  {
    name: 'activeCampaign',
    title: 'ActiveCampaign',
    url: '/integrations/activecampaign',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/active-campaign.svg'
  },
  {
    name: 'aweber',
    alt: 'AWeber Email Marketing Icon',
    title: 'AWeber',
    url: '/integrations/aweber',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/aweber.svg'
  },
  {
    name: 'campaignMonitor',
    title: 'Campaign Monitor',
    url: '/integrations/campaign-monitor',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/campaign-monitor.svg'
  },
  {
    name: 'getResponse',
    title: 'GetResponse',
    url: '/integrations/getresponse',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/get-response.svg'
  },
  {
    name: 'googleContacts',
    title: 'Google Contacts',
    url: '/integrations/google-contacts',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-contacts.svg'
  },
  {
    name: 'iContact',
    alt: 'Blue and Big I Image for iContact',
    title: 'iContact',
    url: '/integrations/icontact',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/icontact.svg'
  }
];

const integrationsMenu = {
  name: 'integrations',
  menuTitle: 'INTEGRATIONS',
  seeAll: {
    title: 'See all integrations',
    url: '/integrations/'
  },
  menuItems: [
    // featured
    {
      name: INTEGRATIONS.FEATURED_INTEGRATIONS,
      title: 'INTEGRATIONS',
      subTitle: 'FEATURED INTEGRATIONS',
      seeMore: {
        title: 'See more',
        url: '/integrations/'
      },
      subLinks: featuredIntegrationsLinks
    },
    // crm
    {
      name: INTEGRATIONS.CRM,
      title: 'CRM',
      subTitle: 'CRM',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/crm'
      },
      subLinks: crmLinks
    },
    // storage
    {
      name: INTEGRATIONS.STORAGE,
      title: 'Storage',
      subTitle: 'STORAGE',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/file-sharing-and-storage'
      },
      subLinks: storageLinks
    },
    // payment
    {
      name: INTEGRATIONS.PAYMENT,
      title: 'Payment',
      subTitle: 'PAYMENT',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/payment-processing'
      },
      subLinks: paymentLinks
    },
    // email
    {
      name: INTEGRATIONS.EMAIL,
      title: 'Email',
      subTitle: 'EMAIL',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/emailing'
      },
      subLinks: emailLinks
    }
  ]
};

export const integrationsNavItem = {
  name: 'integrations',
  menuProps: integrationsMenu,
  linkProps: {
    renderAs: 'button',
    title: 'Integrations',
    menuType: MENU_TYPES.INTEGRATIONS
  }
};
