import React, { useEffect, useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import {
  func, object, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import {
  PrefilledLeadFormAside, ALL_TEXTS, ASSET_IDENTIFIER, gaAttributesPropType, gaFormTrackingItemsPropType, userPropType,
  gaDataLayerPushClose
} from '@jotforminc/ep-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import '../../../../styles/advertisementLeadFlowModal.scss';
import Stars from '../../../../assets/svg/advertisementLeadFlowModal/visual.svg';

const AdvertisementLeadFlowModal = ({
  user,
  badgeText,
  modalProps,
  gaAttributes,
  gaAttributesClose,
  gaFormTrackingItems,
  onSeen,
  onClose,
  onCloseClick,
  onFormSubmit
}) => {
  const uikitModalRef = useRef(null);
  const { AB_TEST: { ADVERTISEMENT_LEAD_FLOW_MODAL } } = ASSET_IDENTIFIER;

  useEffect(() => {
    onSeen();
  }, []);

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);
    onClose();
  };

  const handleCloseClick = () => {
    uikitModalRef.current?.hide();
    onCloseClick();
  };

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={handleClose}
      {...modalProps}
    >
      <div className="row">
        <div className="left-col direct-visible">
          <span className='badge'>
            {t(badgeText)}
          </span>
          <h2 className='title'>
            {t(ALL_TEXTS.ADV_TITLE_1)}
          </h2>
          <Stars className="graph-preview" />
        </div>
        {/* aside */}
        <div className="right-col">
          <PrefilledLeadFormAside
            user={user}
            gaAttributes={gaAttributes}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(ALL_TEXTS.SHORT_LEAD_FORM_TITLE)}
            formUiModifier={ADVERTISEMENT_LEAD_FLOW_MODAL}
            onFormSubmit={onFormSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

AdvertisementLeadFlowModal.propTypes = {
  badgeText: string,
  user: userPropType.isRequired,
  modalProps: object,
  onClose: func,
  onCloseClick: func,
  gaAttributes: gaAttributesPropType.isRequired,
  gaAttributesClose: gaAttributesPropType.isRequired,
  gaFormTrackingItems: gaFormTrackingItemsPropType.isRequired,
  onSeen: func,
  onFormSubmit: func
};

AdvertisementLeadFlowModal.defaultProps = {
  badgeText: ALL_TEXTS.BEFORE_YOU_GO,
  modalProps: {},
  onClose: f => f,
  onCloseClick: f => f,
  onSeen: f => f,
  onFormSubmit: f => f
};

export default AdvertisementLeadFlowModal;
