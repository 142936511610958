import React from 'react';
import { t } from '@jotforminc/translation';
import { Radio } from '@jotforminc/magnet';
import cx from 'classnames';
import { TEXTS } from '../../constants';
import { Questions } from './Questions';
import { useEpOnboarding } from '../../contexts';

export const JobRoleStep = () => {
  const {
    state: { onboardData }, api: { setOnboardData, logAbTestAction }
  } = useEpOnboarding();

  const JOB_ROLES = [
    TEXTS.MARKETING_OPS,
    TEXTS.ENGINEERING,
    TEXTS.CUSTOMER_SUPPORT_OPS,
    TEXTS.HR_RECRUITING_OPS,
    TEXTS.PROJECT_MANAGEMENT,
    TEXTS.BUSINESS_OWNER,
    TEXTS.SALES_OPS,
    TEXTS.OTHER
  ];

  const MANAGEMENT_LEVELS = [
    TEXTS.INDIVIDUAL_CONTRIBUTOR,
    TEXTS.TEAM_LEAD,
    TEXTS.MANAGER,
    TEXTS.DIRECTOR,
    TEXTS.CEO_FOUNDER
  ];

  const handlePositionSelecet = e => {
    const { name, value } = e.target;

    logAbTestAction({ action: 'select', target: `${name}-${value}` });
    setOnboardData({ [name]: value });
  };

  return (
    <section>
      <Questions title={TEXTS.TELL_US_ABOUT_YOUR_ROLE} description={TEXTS.TELL_US_ABOUT_YOUR_ROLE_DESCRIPTION} />
      <div className='formItem'>
        <label className='label'>{t(TEXTS.JOB_ROLE)}</label>
        <div className='options-wrapper'>
          <div className='radio-wrapper'>
            {JOB_ROLES.map(role => (
              <Radio
                key={role}
                size='small'
                name='jobRole'
                label={t(role)}
                value={role}
                checked={onboardData.jobRole === role}
                className={cx('radio-item', {
                  selected: onboardData.jobRole === role
                })}
                onChange={handlePositionSelecet}
              />
            ))}
          </div>
        </div>
      </div>

      <div className='formItem'>
        <label className='label'>{t(TEXTS.MANAGEMENT_LEVEL)}</label>
        <div className='options-wrapper'>
          <div className='radio-wrapper'>
            {MANAGEMENT_LEVELS.map(managementLevel => (
              <Radio
                key={managementLevel}
                size='small'
                name='managementLevel'
                label={t(managementLevel)}
                value={managementLevel}
                checked={onboardData.managementLevel === managementLevel}
                className={cx('radio-item', {
                  selected: onboardData.managementLevel === managementLevel
                })}
                onChange={handlePositionSelecet}
              />
            )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
