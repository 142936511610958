import React, {
  useState, useContext, createContext, useMemo
} from 'react';
import { node, object, string } from 'prop-types';
import { ActionManager } from '@jotforminc/abtest-manager';

const AssetManagerContext = createContext({});

export const AssetManagerProvider = ({
  children,
  campaignInfo,
  assetInfo,
  assetType,
  user
}) => {
  const isDevelopment = window.JOTFORM_ENV === 'DEVELOPMENT';
  const actionManager = useMemo(() => new ActionManager({ user, projectName: 'growthAssetManager', enableDebugMode: isDevelopment }), [user, isDevelopment]);

  const [state] = useState({
    campaignInfo,
    assetInfo,
    assetType
  });

  return (
    <AssetManagerContext.Provider
      value={{
        state,
        actionManager
      }}
    >
      { children }
    </AssetManagerContext.Provider>
  );
};

AssetManagerProvider.propTypes = {
  children: node.isRequired,
  campaignInfo: object.isRequired,
  assetInfo: object.isRequired,
  assetType: string.isRequired,
  user: object.isRequired
};

export const useAssetManagerContext = () => {
  const { state, actionManager } = useContext(AssetManagerContext);

  return {
    ...state,
    actionManager
  };
};
