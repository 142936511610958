export const WEBINAR = {
  COST_CUTTING_WEBINAR_BANNER: 'costCuttingWebinarBanner',
  COST_CUTTING_WEBINAR_MODAL: 'costCuttingWebinarModal',
  ED_TECH_WEBINAR_MODAL: 'edTechWebinarModal',
  FIELD_SERVICE_WEBINAR_MODAL: 'fieldServiceWebinarModal',
  HR_WEBINAR_MODAL: 'hrWebinarModal',
  HEALTHCARE_TECHNOLOGY_MODAL: 'healthcareTechnologyModal',
  HEALTHCARE_AUTOMATION_STRATEGIES_WEBINAR_MODAL: 'hasWebinarModal',
  LUNCH_AND_LEARN_MODAL: 'lunchAndLearnModal',
  LUNCH_AND_LEARN_BANNER: 'lunchAndLearnBanner',
  NONPROFIT_WEBINAR_BANNER: 'nonProfitWebinarBanner',
  MAXIMUM_IMPACT_WEBINAR_MODAL: 'maximumImpactWebinarModal',
  HEALTHCARE_FUTURE_WEBINAR_MODAL: 'healthcareFutureWebinarModal',
  NONPROFIT_WEBINAR_MODAL: 'nonProfitWebinarModal',
  PRIVACY_CONCERN_WEBINAR_MODAL: 'privacyConcernWebinarModal'
};
