import { MENU_TYPES } from './menuTypes';

const templatesMenu = {
  name: 'templates',
  menuTitle: 'TEMPLATES',
  menuItems: [
    {
      name: 'formTemplates',
      title: 'Form Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/form.svg',
      url: '/form-templates/?classic'
    },
    {
      name: 'cardFormTemplates',
      title: 'Card Form Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/card-form.svg',
      url: '/form-templates/?cards'
    },
    {
      name: 'appTemplates',
      title: 'App Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/app.svg',
      url: '/app-templates/'
    },
    {
      name: 'storeBuilderTemplates',
      title: 'Store Builder Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/store-builder.svg?v=1.0',
      url: '/app-templates/category/online-store-templates'
    },
    {
      name: 'tableTemplates',
      title: 'Table Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/table.svg',
      url: '/table-templates/'
    },
    {
      name: 'workflowTemplates',
      title: 'Workflow Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/approval.svg',
      url: '/workflow-templates/',
      badge: {
        text: 'NEW'
      }
    },
    {
      name: 'pdfTemplates',
      title: 'PDF Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/pdf.svg',
      url: '/pdf-templates/'
    },
    {
      name: 'signTemplates',
      title: 'Sign Templates',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/templates/sign.svg',
      url: '/pdf-templates/e-sign/'
    }
  ]
};

export const templatesNavItem = {
  name: 'templates',
  menuProps: templatesMenu,
  linkProps: {
    renderAs: 'button',
    title: 'Templates',
    menuType: MENU_TYPES.TEMPLATES
  }
};
