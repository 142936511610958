const GA_PROJECT = 'enterprise-promotions';

const ASSET_TYPES = {
  MODAL: 'modal',
  BANNER: 'banner',
  SECTION: 'section',
  BUTTON: 'button',
  TOOLTIP: 'tooltip'
};

const kebabizeReplacer = (match, offset) => (offset ? '-' : '') + match.toLowerCase();

const kebabize = str => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, kebabizeReplacer);

const findAssetType = assetName => Object.values(ASSET_TYPES).find(type => assetName.toLowerCase().includes(type));

export const createGaAttributes = (assetName = '', suffix = '') => {
  const assetType = findAssetType(assetName);
  return {
    'data-ga': `${GA_PROJECT}-${assetType}`,
    'data-id': suffix ? `${kebabize(assetName)}-${kebabize(suffix)}` : `${kebabize(assetName)}`
  };
};

export const mapCallbacksToGaTrackingItems = (trackingItems, callbacks = []) => {
  const trackingItemsWithCb = trackingItems.map((item, i) => {
    const isFunc = typeof callbacks[i] === 'function';
    if (!isFunc) return { ...item };
    return { ...item, callback: callbacks[i] };
  });
  return trackingItemsWithCb;
};

export const gaDataLayerPushClose = (gaAttributes = {}) => {
  const dataId = gaAttributes['data-id'];
  const assetType = findAssetType(dataId);
  window.dataLayer?.push({
    event: `${assetType}_close`,
    'data-id': dataId
  });
};

export const GA_SUFFIXES = {
  APPLY_NOW: 'applyNow',
  CHECK_OUT_JOTFORM_ENTERPRISE: 'checkOutJotformEnterprise',
  CLOSE: 'close',
  CONTACT_SALES: 'contactSales',
  UPGRADE: 'upgrade',
  DISCOVER_MORE: 'discoverMore',
  DISMISS: 'dismiss',
  ENTERPRISE_LANDING: 'enterpriseLanding',
  ENTERPRISE_WHITE_LABELING: 'enterpriseWhiteLabeling',
  GET_CUSTOM_QUOTE: 'getCustomQuote',
  GET_INSIGHT_NOW: 'getInsightNow',
  GET_A_QUOTE: 'getAQuote',
  JOIN_NOW: 'joinNow',
  LEARN_MORE: 'learnMore',
  LEARN_DETAILS: 'learnDetails',
  NOT_INTERESTED: 'notInterested',
  NOT_NOW: 'notNow',
  READ_THE_REPORT: 'readTheReport',
  REQUEST_A_DEMO: 'requestADemo',
  SAVE_YOUR_SEAT: 'saveYourSeat',
  SAVE_YOUR_SPOT: 'saveYourSpot',
  SEE_DETAILS: 'seeDetails',
  SIGN_UP: 'signUp',
  UPGRADE_TO_JOTFORM_ENTERPRISE: 'upgradeToJotformEnterprise',
  SCHEDULE: 'schedule',
  JOTFORM_ENTERPRISE: 'jotformEnterprise',
  TRY_JOTFORM_ENTERPRISE: 'tryJotformEnterprise',
  UPGRADE_TO_ENTERPRISE: 'upgradeToEnterprise',
  GET_NOW: 'getNow',
  SCHEDULE_ZOOM_CALL: 'scheduleZoomCall',
  GET_PDF: 'getPdf'
};
