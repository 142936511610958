import React, { createContext } from 'react';
import { bool, node } from 'prop-types';

export const ModeContext = createContext();

const ModeProvider = ({ stickyMode, children }) => {
  return (
    <ModeContext.Provider value={stickyMode}>
      {children}
    </ModeContext.Provider>
  );
};

ModeProvider.propTypes = {
  stickyMode: bool,
  children: node
};

ModeProvider.defaultProps = {
  stickyMode: false,
  children: null
};

export default ModeProvider;
