import React, { useContext, useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { t } from '@jotforminc/translation';
import cx from 'classnames';
import CloseSvg from '../assets/svg/notifications/close.svg';
import { JotformActionsContext } from './Providers/JotformActionsProvider';
import { isEnterprise } from '../utils';

const DeletedFormNotification = ({
  defaultVisible, onClose, isSticky
}) => {
  const [isVisible, setIsVisible] = useState(defaultVisible);
  const [isAnimationVisible, setIsAnimationVisible] = useState(false);
  const { logHeaderAction } = useContext(JotformActionsContext);

  useEffect(() => {
    if (isVisible) {
      logHeaderAction?.({ action: 'seen', target: 'notification' });
      setIsVisible(true);
      setTimeout(() => {
        document.body.classList.add('jf-deleted-form-notification');
        setIsAnimationVisible(true);
      }, 500);
    }
  }, [isVisible]);

  const handleCloseClick = () => {
    logHeaderAction?.({ action: 'click', target: 'close-notification' });
    setIsAnimationVisible(false);
    document.body.classList.remove('jf-deleted-form-notification');
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
    onClose();
  };

  const handleClick = () => {
    logHeaderAction?.({ action: 'click', target: 'click-on-notification' });
    if (isEnterprise()) {
      global.window.open('/myforms/?action=createWizard', '_blank').focus();
      return;
    }
    global.window.open('/signup/', '_blank').focus();
  };

  const deletedFormClass = cx('jfHeader--deleted-form-notification', {
    isSticky
  });

  const deletedFormInnerClass = cx('jfHeader--deleted-form-notification-inner', {
    isAnimationVisible
  });

  const deletedFormContentClass = cx('jfHeader--deleted-form-notification-content', {
    '': isAnimationVisible
  });

  if (!isVisible) return null;

  return (
    <div className={deletedFormClass}>
      <div className={deletedFormInnerClass}>
        <div className={deletedFormContentClass}>
          <p>{t('Oops! The form you would like to see is deleted. What about creating your own?')}</p>
          <button
            type="button"
            onClick={handleClick}
            className="jfHeader--deleted-form-notification-button"
          >
            {t('Create a New Form')}
          </button>
        </div>
        <button
          type="button"
          onClick={handleCloseClick}
          className="jfHeader--deleted-form-notification-close-button"
        >
          <CloseSvg />
        </button>
      </div>
    </div>
  );
};

DeletedFormNotification.propTypes = {
  defaultVisible: bool,
  onClose: func,
  isSticky: bool
};

DeletedFormNotification.defaultProps = {
  defaultVisible: false,
  onClose: f => f,
  isSticky: false
};

export default DeletedFormNotification;
