import React from 'react';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';

const NavItemBadge = ({ text }) => {
  return (
    <strong className="jfRHeader--nav-menu-item-badge">
      {t(text)}
    </strong>
  );
};

NavItemBadge.propTypes = {
  text: string.isRequired
};

export default NavItemBadge;
