import React from 'react';
import {
  elementType, bool, node, string
} from 'prop-types';
import cx from 'classnames';
import { CONTACT_SALES_AB_TEST_VARIATIONS } from '@jotforminc/header-body';
import { PreLoaders } from '../PreLoaders';
import { ScHeaderNavigation } from '../Sc';

const Navigation = ({
  LoginGroupRenderer,
  children = null,
  isMobileScreen = false,
  isMobile = false,
  isLoggedIn = false,
  avatarUrl = undefined,
  username = undefined,
  disableLoginFlow = false,
  hideLoginButton,
  hideSignupButton,
  replaceAuthButtons,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL
}) => {
  const showLoginGroup = !disableLoginFlow && !replaceAuthButtons;
  const { CONTACT_SALES_AS_ITEM } = CONTACT_SALES_AB_TEST_VARIATIONS;

  // TODO: will this to props and remove hardcoded value
  const navEffectHover = true;

  return (
    <ScHeaderNavigation className={cx('jfRHeader--nav', {
      isMobile,
      isMobileScreen: !isMobile && isMobileScreen,
      'jfRHeader--nav-efc': navEffectHover
    })}
    >
      <ul className={cx('jfRHeader--nav-menu', {
        isMobile,
        'is-trio-button': contactSalesAbTestVariation === CONTACT_SALES_AS_ITEM
      })}
      >
        {children}
      </ul>
      {showLoginGroup && LoginGroupRenderer && <LoginGroupRenderer /> }
      {showLoginGroup && !LoginGroupRenderer && (
      <PreLoaders
        username={username}
        avatarUrl={avatarUrl}
        isLoggedIn={isLoggedIn}
        hideLoginButton={hideLoginButton}
        hideSignupButton={hideSignupButton}
        contactSalesAbTestVariation={contactSalesAbTestVariation}
        contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
      />
      )}
    </ScHeaderNavigation>
  );
};

Navigation.propTypes = {
  LoginGroupRenderer: elementType.isRequired,
  children: node,
  isMobileScreen: bool,
  isMobile: bool,
  isLoggedIn: bool,
  avatarUrl: string,
  username: string,
  disableLoginFlow: bool,
  hideLoginButton: bool.isRequired,
  hideSignupButton: bool.isRequired,
  replaceAuthButtons: string.isRequired,
  contactSalesAbTestVariation: string.isRequired,
  contactSalesAbTestVariationURL: string.isRequired
};

export default Navigation;
