import React from 'react';
import { bool, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { CONTACT_SALES_AB_TEST_VARIATIONS } from '../../constants';
import { shouldRenderSignupButton } from '../../utils';

const LoginGroupPreLoader = ({
  hideLoginButton,
  hideSignupButton,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL
}) => {
  const { CONTACT_SALES_AS_CTA, CONTACT_SALES_AS_ITEM } = CONTACT_SALES_AB_TEST_VARIATIONS;

  return (
    <ul className="jfRHeader--nav-action isNotLoggedIn">
      {/* A/B Test: contactSalesButtonOnHeader */}
      {/* contact sales as item */}
      {
        contactSalesAbTestVariation === CONTACT_SALES_AS_ITEM && (
        <li className="jfRHeader--nav-action-item">
          <a type="button" className="jfRHeader--nav-action-list-login locale" href={contactSalesAbTestVariationURL}>{t('Contact Sales')}</a>
        </li>
        )
      }
      {
        !hideLoginButton && (
        <li className="jfRHeader--nav-action-item">
          <a type="button" href="/login" className="jfRHeader--nav-action-list-login locale">{t('Login')}</a>
        </li>
        )
      }
      {/* A/B Test: contactSalesButtonOnHeader */}
      {
        shouldRenderSignupButton({ hideSignupButton, contactSalesAbTestVariation }) && (
          <li className="jfRHeader--nav-action-item">
            <a type="button" href="/signup" className="jfRHeader--nav-action-list-signup locale">{t('Sign Up for Free')}</a>
          </li>
        )
      }
      {/* A/B Test: contactSalesButtonOnHeader */}
      {/* contact sales as cta */}
      {
        contactSalesAbTestVariation === CONTACT_SALES_AS_CTA && (
          <li className="jfRHeader--nav-action-item">
            <a type="button" className="jfRHeader--contact-sales locale" href={contactSalesAbTestVariationURL}>{t('Contact Sales')}</a>
          </li>
        )
      }
    </ul>
  );
};

LoginGroupPreLoader.propTypes = {
  hideLoginButton: bool.isRequired,
  hideSignupButton: bool.isRequired,
  contactSalesAbTestVariation: string.isRequired,
  contactSalesAbTestVariationURL: string.isRequired
};

export default LoginGroupPreLoader;
