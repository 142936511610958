import React from 'react';
import ReactDOM from 'react-dom';
import { getRootElement } from './utils';
import { ENTERPRSE_PROMOTIONS_ROOT_EL_ID } from '../constants';

const renderModal = ({ Modal = null, onClose = f => f, ...props }) => {
  if (!Modal) return null;
  const rootEl = getRootElement(ENTERPRSE_PROMOTIONS_ROOT_EL_ID);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(rootEl);
    onClose();
  };

  ReactDOM.render(
    <Modal onClose={handleClose} {...props} />,
    rootEl
  );
};

export default renderModal;
