export const CONTENTS = [
  {
    name: 'Form Builder',
    title: 'Powerful forms get it done',
    image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding/form_builder.png',
    color: '255, 97, 0'
  },
  {
    name: 'Jotform Apps',
    title: 'Create Apps with No-Code App Builder',
    image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding/jotform_apps.png',
    color: '156, 77, 211'
  },
  {
    name: 'Jotform Approvals',
    title: 'Automate your approval process with ease',
    image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding/jotform_approvals.png',
    color: '0, 120, 98'
  },
  {
    name: 'Jotform Sign',
    title: 'E-signatures meet automation',
    image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding/jotform_sign.png',
    color: '123, 182, 15'
  },
  {
    name: 'Jotform PDF Editor',
    title: 'Automatically create polished, designed documents',
    image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding/jotform_pdf_editor.png',
    color: '62, 98, 200'
  },
  {
    name: 'Jotform Tables',
    title: 'When a spreadsheet isn’t enough for your team',
    image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding/jotform_tables.png',
    color: '4, 158, 56'
  }
];

// A/B Test: epSignupPageOnboarding
export const CONTENTS_V2 = [
  {
    key: 'firstStep',
    name: 'Hi',
    username: '{fullName}',
    title: 'Tell us a bit about yourself.',
    bg_image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding_2/step1.svg',
    isHalfVisible: true,
    color: '62, 98, 200'
  },
  {
    key: 'secondStep',
    bg_image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding_2/step2.png'
  },
  {
    key: 'thirdStep',
    bg_image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding_2/step3.png',
    isOrganizationStep: true
  },
  {
    key: 'fourthStep',
    name: 'Thank you,',
    username: '{fullName}',
    title: 'Your account is almost ready!',
    shadow_image: 'https://cdn.jotfor.ms/assets/img/signup_onboarding_2/step4-shadow.svg',
    isTeam: true,
    isIndividual: true,

    color: '62, 98, 200'
  }
];
