import { TEXTS } from './texts';

export const pricingsNavItem = {
  name: 'pricing',
  linkProps: {
    url: '/pricing/',
    title: TEXTS.PRICING,
    isPricingLink: true
  }
};
