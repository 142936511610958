export const loadByApplyingLazyIgnoredElements = ({ target }, loadFunction) => {
  const lazyLoadDelayedElements = [
    { selector: 'CookieBanner', type: 'id' },
    { selector: 'close-wp', type: 'class' },
    { selector: 'jfRHeader--hamburger', type: 'class' },
    { selector: 'jfRHeader--logo-link', type: 'class' },
    { selector: 'jfRHeader--mobile-nav-signup-btn', type: 'class' },
    { selector: 'logo-page', type: 'class' }
  ]
    .map(({ type, selector }) => ({
      type,
      selector,
      elements: Array.from(document.querySelectorAll(`${type === 'id' ? '#' : '.'}${selector}`))
    }))
    .filter(({ elements }) => elements.length > 0);

  const isTargetDelayed = (elements, selector, type) => {
    const { id, classList } = target;
    return elements.some(currentEl => currentEl === target
      || currentEl.contains(target)
      || (type === 'id' ? id === selector : classList?.contains(selector)));
  };

  const shouldDelayLazyLoad = lazyLoadDelayedElements.some(({ elements, selector, type }) => {
    return isTargetDelayed(elements, selector, type);
  });

  setTimeout(loadFunction, shouldDelayLazyLoad ? 1000 : 0);
};
