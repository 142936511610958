import { toggleSignupLoaders } from './toggleSignupLoaders';

export const createSocialTermsFlow = user => {
  const { pathname } = global.window.location;
  const preveventFlow = ['/login/', '/signup/'].includes(pathname);

  // If the pathname indicates that we're on the login or signup page, exit early.
  if (preveventFlow) return {};

  const socialTermsSecretManager = global.window.loginFlowHelper.createSocialTermsSecretManager();

  return {
    // When the Google/Facebook token arrives and Jotform API request triggers.
    onSocialLoginStart: ({ socialSignupThunk }) => {
      socialTermsSecretManager.set('socialSignupThunk', socialSignupThunk);
    },
    // When Jotform API returns a hash because it requires the user to accept terms and conditions.
    onSocialTermsRequired: ({
      socialLoginHash,
      socialUserProfile,
      idToken
    } = {}) => {
      socialTermsSecretManager.set('socialLoginHash', socialLoginHash);

      // Render terms and conditions modal.
      global.window.loginFlowHelper.renderStandaloneSocialTermsModal({
        user,
        socialUserProfile,
        onSocialTermsContinue: isTermsChecked => {
          if (isTermsChecked) {
            const hash = socialTermsSecretManager.get('socialLoginHash');
            const continueSocialSignup = socialTermsSecretManager.get('socialSignupThunk');
            if (typeof continueSocialSignup === 'function' && hash) {
              continueSocialSignup(hash, idToken);
            }
          }
        },
        onSocialTermsClose: () => {
          socialTermsSecretManager.removeAll();
          toggleSignupLoaders('hide');
        }
      });
    }
  };
};
