import Tracking from '@jotforminc/tracking';

// A/B Test: signupOnboardingIV
export const PROJECT_NAME = 'signupOnboardingIV';

// const isInOnePercent = () => Math.floor(Math.random() * 100) + 1 === 1;

export const identifyUserForFullstory = ({
  user,
  projectName,
  isEnabled = false,
  callback = f => f
}) => {
  if (!projectName || !isEnabled || !user?.username) return;
  if (!Tracking.FSisInitialized()) {
    Tracking.enableFS();
  }
  Tracking.identify(user?.username, {
    displayName: user?.username,
    [`${projectName}_bool`]: true
  });
  if (typeof callback === 'function') {
    callback();
  }
};
