import React, { createContext, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useIsMobileScreen } from '../../hooks';
import { useLockBodyScroll, yieldToMain } from '../../utils';

export const MobileMenuContext = createContext();

const MobileMenuProvider = ({ children }) => {
  const [isMobileScreen] = useIsMobileScreen();
  const { lockScroll, unlockScroll } = useLockBodyScroll();
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  useEffect(() => {
    if (!isMobileScreen) {
      setIsMobileMenuActive(false);
      return;
    }
    setIsMobileMenuActive(false);
  }, [isMobileScreen]);

  useEffect(() => {
    if (isMobileMenuActive) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isMobileMenuActive]);

  const onMobileMenuButtonClick = async () => {
    await yieldToMain();
    setIsMobileMenuActive(active => !active);
  };

  return (
    <MobileMenuContext.Provider value={{
      isMobileScreen,
      isMobileMenuActive,
      onMobileMenuButtonClick
    }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
};

MobileMenuProvider.propTypes = {
  children: node
};

MobileMenuProvider.defaultProps = {
  children: null
};

export default MobileMenuProvider;
