import styled from 'styled-components';

export const ScHeaderWrapper = styled.div`
  --jf-header-bg: ${props => props.menuStyles.headerBackground || 'transparent'};
  --jf-header-bg-sticky: ${props => props.menuStyles.headerBackgroundSticky || 'rgba(255, 255, 255, 0.9)'};
  --jf-header-logo-text: ${props => props.menuStyles.logoTextColor || '#0a224c'};
  --jf-header-nav-menu: ${props => props.menuStyles.navMenuColor || '#0a1551'};
  --jf-header-nav-menu-active: ${props => props.menuStyles.navMenuActiveColor || '#0075E3'};
  --jf-header-nav-menu-hover: ${props => props.menuStyles.navMenuHoverColor || '#0075E3'};
  --jf-hamburger-menu: ${props => props.menuStyles.hamburgerMenuColor || '#343C6A'};
  --jf-app-picker-border: ${props => props.menuStyles.appPickerBorderColor || '#E3E5F5'};
  --jf-header-size: ${props => props.menuStyles.headerHeight || '64px'};
  --bg-blue-500: #0075e3;
  --bg-blue-600: #0066c3;
  @media screen and (max-width: 1024px) {
    --jf-header-size: 45px;
  }
`;

export default ScHeaderWrapper;
