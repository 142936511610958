import React from 'react';
import {
  bool, string, object
} from 'prop-types';
import {
  THEME_MAP, userPropType, userTypePropType, logoPropType, themePropType, stickyModeThemePropType,
  logoDefaultProps, logoSideLinkDefaultProps, logoSideLinkPropType, navItemsPropType, menuStylesPropType
} from '@jotforminc/header-body';

import Header from '../Header';
import ModeProvider from './ModeProvider';
import UserProvider from './UserProvider';
import MobileMenuProvider from './MobileMenuProvider';
import JotformActionsProvider from './JotformActionsProvider';

const HeaderProvider = ({
  user,
  userType,
  isLoggedIn,
  language,
  isEnterprise,
  hostname,
  currentPath,
  customNavItems,
  logoProps,
  logoSideLinkProps,
  bgColor,
  fullWidth,
  stickyMode,
  menuStyles,
  theme,
  stickyModeTheme,
  hideAccountBox,
  hideLoginButton,
  hideSignupButton,
  disableLoginFlow,
  replaceAuthButtons,
  replaceAuthButtonsLocalized,
  appPickerProps,
  languageSwitcher,
  isAcademyAppsCourseReleased,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL
}) => {
  return (
    <UserProvider
      user={user}
      userType={userType}
      isLoggedIn={isLoggedIn}
    >
      <JotformActionsProvider>
        <MobileMenuProvider>
          <ModeProvider stickyMode={stickyMode}>
            <Header
              theme={theme}
              bgColor={bgColor}
              language={language}
              hostname={hostname}
              logoProps={logoProps}
              fullWidth={fullWidth}
              menuStyles={menuStyles}
              currentPath={currentPath}
              isEnterprise={isEnterprise}
              customNavItems={customNavItems}
              hideAccountBox={hideAccountBox}
              appPickerProps={appPickerProps}
              stickyModeTheme={stickyModeTheme}
              hideLoginButton={hideLoginButton}
              languageSwitcher={languageSwitcher}
              disableLoginFlow={disableLoginFlow}
              hideSignupButton={hideSignupButton}
              logoSideLinkProps={logoSideLinkProps}
              replaceAuthButtons={replaceAuthButtons}
              isAcademyAppsCourseReleased={isAcademyAppsCourseReleased}
              replaceAuthButtonsLocalized={replaceAuthButtonsLocalized}
              contactSalesAbTestVariation={contactSalesAbTestVariation}
              contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
            />
          </ModeProvider>
        </MobileMenuProvider>
      </JotformActionsProvider>
    </UserProvider>
  );
};

HeaderProvider.propTypes = {
  // user & env props
  user: userPropType,
  userType: userTypePropType,
  isLoggedIn: bool,
  language: string,
  isEnterprise: bool,
  hostname: string,
  currentPath: string,
  // logo & menu props
  customNavItems: navItemsPropType,
  logoProps: logoPropType,
  logoSideLinkProps: logoSideLinkPropType,
  // ui props
  bgColor: string,
  fullWidth: bool,
  stickyMode: bool,
  menuStyles: menuStylesPropType,
  theme: themePropType,
  stickyModeTheme: stickyModeThemePropType,
  // login flow props
  hideAccountBox: bool,
  hideLoginButton: bool,
  hideSignupButton: bool,
  disableLoginFlow: bool,
  replaceAuthButtons: string,
  replaceAuthButtonsLocalized: string,
  // other
  appPickerProps: object,
  languageSwitcher: object,
  contactSalesAbTestVariation: string,
  contactSalesAbTestVariationURL: string,
  isAcademyAppsCourseReleased: string
};

HeaderProvider.defaultProps = {
  // user & env props
  user: global.window?.__jfCommonHeaderProps?.user || global.window?.user,
  userType: global.window?.userType || global.window?.__jfCommonHeaderProps?.userType,
  isLoggedIn: global.window?.__jfCommonHeaderProps?.isLoggedIn,
  language: global.window?.__jfCommonHeaderProps?.language || 'en-US',
  isEnterprise: global.window?.__jfCommonHeaderProps?.isEnterprise || false,
  hostname: global.window?.__jfCommonHeaderProps?.hostname || (global.window?.location || global.location)?.hostname,
  currentPath: global.window?.__jfCommonHeaderProps?.currentPath || (global.window?.location || global.location)?.pathname,
  // logo & menu props
  customNavItems: global.window?.__jfCommonHeaderProps?.customNavItems,
  logoProps: global.window?.__jfCommonHeaderProps?.logoProps || logoDefaultProps,
  logoSideLinkProps: global.window?.__jfCommonHeaderProps?.logoSideLinkProps || logoSideLinkDefaultProps,
  // ui props
  bgColor: global.window?.__jfCommonHeaderProps?.bgColor || '',
  fullWidth: global.window?.__jfCommonHeaderProps?.fullWidth || false,
  stickyMode: global.window?.__jfCommonHeaderProps?.stickyMode || false,
  menuStyles: global.window?.__jfCommonHeaderProps?.menuStyles || {},
  theme: global.window?.__jfCommonHeaderProps?.theme || THEME_MAP.DARK,
  stickyModeTheme: global.window?.__jfCommonHeaderProps?.stickyModeTheme || '',
  // login flow props
  hideAccountBox: global.window?.__jfCommonHeaderProps?.hideAccountBox || false,
  hideLoginButton: global.window?.__jfCommonHeaderProps?.hideLoginButton || false,
  hideSignupButton: global.window?.__jfCommonHeaderProps?.hideSignupButton || false,
  disableLoginFlow: global.window?.__jfCommonHeaderProps?.disableLoginFlow || false,
  replaceAuthButtons: global.window?.__jfCommonHeaderProps?.replaceAuthButtons || '',
  replaceAuthButtonsLocalized: global.window?.__jfCommonHeaderProps?.replaceAuthButtonsLocalized || '',
  // other
  appPickerProps: global.window?.__jfCommonHeaderProps?.appPickerProps || {},
  languageSwitcher: global.window?.__jfCommonHeaderProps?.languageSwitcher || {},
  contactSalesAbTestVariation: global.window?.__jfCommonHeaderProps?.contactSalesAbTestVariation || '',
  contactSalesAbTestVariationURL: global.window?.__jfCommonHeaderProps?.contactSalesAbTestVariationURL || '',
  isAcademyAppsCourseReleased: global.window?.__jfCommonHeaderProps?.isAcademyAppsCourseReleased || ''
};

export default HeaderProvider;
