import { ASSET_IDENTIFIER } from './assetIdentifier';

export const LEAD_FORM_SOURCE_MODIFIERS = {
  ADWORDS: 'adwords'
};

export const AUTOMATED_NURTURING_SOURCES = {
  EDUCATION_REPORT_MODAL: 'Education Toolkit Gated Content',
  EDUCATION_BDM_FLOW_MODAL: 'Education BDM Flow Modal'
};

const { PRODUCT: { ENTERPRISE_TEAMS_MODAL } } = ASSET_IDENTIFIER;

export const AB_TEST_MODIFIER = {
  [ENTERPRISE_TEAMS_MODAL]: 'epTeamsModalWording'
};
