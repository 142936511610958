export const customRedirectionOnSignUp = () => {
  if (global.window.setCustomFunction_signup) {
    global.window.customFunction_signup();
    return true;
  }
  return false;
};

export const customRedirectionOnLogIn = () => {
  if (global.window.setCustomFunction_login) {
    global.window.customFunction_login();
    return true;
  }
  return false;
};
