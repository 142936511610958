import { Loading } from '@jotforminc/loading';
import React from 'react';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import {
  IconAngleLeft, IconAngleRight
} from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import JotformLogo from '../assets/svg/logo.svg';
import { ACTION_DATA, TEXTS } from '../constants';
import '../styles/allSteps.scss';
import { useEpOnboarding } from '../contexts';
import { EP_ONBOARDING_STEPS } from './EpOnboardingSteps';

const AllSteps = () => {
  const {
    state: { currentStep, ui: { isLoading } }, api: {
      handleNextClick, handleBackClick, canProceedNextStep, isSkippableStep, logAbTestAction
    }
  } = useEpOnboarding();
  const { LOGO_CLICK } = ACTION_DATA;

  const handleLogoClick = e => {
    e.preventDefault();
    logAbTestAction(LOGO_CLICK);
    handleCustomNavigation('/', '_self');
  };

  const handleSkipClick = () => {
    logAbTestAction({ action: 'click', target: 'skipButton' });
    handleNextClick();
  };

  const renderNextButtonState = () => {
    if (isLoading) {
      return (
        <Loading
          strokeColor="#FFF"
          strokeWidth="4"
          size="32px"
        />
      );
    }

    return currentStep < EP_ONBOARDING_STEPS.length - 1 ? t(TEXTS.NEXT) : t(TEXTS.FINISH);
  };

  const CurrentStep = EP_ONBOARDING_STEPS[currentStep];
  const progressSteps = Array.from({ length: 4 }, (_, i) => i).map((val, i) => <li key={val} className={currentStep >= i ? 'active' : ''} />);

  return (
    <div className='allSteps--wrapper'>
      <div className='allSteps--header-wrapper'>
        <a
          href="/"
          className="jfOnboarding--header-logo"
          onClick={handleLogoClick}
        >
          <JotformLogo />
        </a>
        <ul className='allSteps--pagination'>
          {progressSteps}
        </ul>
      </div>

      <div className='allSteps--question'>
        {currentStep > 0 ? (
          <Button
            variant="ghost"
            startIcon={IconAngleLeft}
            onClick={handleBackClick}
            className="mr-auto mb-2"
          >
            {t(TEXTS.BACK)}
          </Button>
        ) : null}
        <CurrentStep />
      </div>

      <div className='allSteps--proceed-btns'>
        {isSkippableStep()
          ? (
            <Button
              className='allSteps--proceed-btns-skip'
              variant='outline'
              colorStyle='secondary'
              onClick={handleSkipClick}
            >
              { t(TEXTS.SKIP)}
            </Button>
          ) : null}

        <Button
          className='allSteps--proceed-btns-next'
          variant='filled'
          colorStyle='primary'
          endIcon={!true ? IconAngleRight : undefined}
          onClick={handleNextClick}
          disabled={!canProceedNextStep()}
        >
          {renderNextButtonState()}
        </Button>
      </div>
    </div>
  );
};

export default AllSteps;
