import { useEffect, useState } from 'react';

const useLazyIntegrationImages = () => {
  const selector = '.jfRHeader .jfIntegrations img';
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadImage = el => el?.setAttribute('src', el?.dataset?.src);
    const loadImages = () => document.querySelectorAll(selector).forEach(loadImage);

    const handleLazyLoad = () => {
      if (isLoaded) return;
      setIsLoaded(true);
      loadImages();
    };

    document.addEventListener('mousemove', handleLazyLoad);
    document.addEventListener('touchstart', handleLazyLoad);

    return () => {
      document.removeEventListener('mousemove', handleLazyLoad);
      document.removeEventListener('touchstart', handleLazyLoad);
    };
  }, [isLoaded, selector]);
};

export default useLazyIntegrationImages;
