import { renderEttEducationGatedContentModal } from '@jotforminc/enterprise-promotions';

// Educator's Technology Toolkit Report Modal
export const initEpEttGatedContentModal = user => {
  const isEnabled = true;
  const urlParam = 'ettgcm';
  const urlParams = new URLSearchParams(global.window.location.search);
  const hasUrlParam = urlParams.has(urlParam);
  const shouldChangeButtonBehaviour = isEnabled || hasUrlParam;
  const downloadButtonEl = document.querySelector('.education-download[data-jfa="pdf-download-navigator-button"]');

  if (!shouldChangeButtonBehaviour || !downloadButtonEl) return;

  downloadButtonEl?.setAttribute('href', '#');

  downloadButtonEl?.addEventListener('click', e => {
    e.preventDefault();
    renderEttEducationGatedContentModal({ user });
  });
};
