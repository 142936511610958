import React from 'react';
import { t } from '@jotforminc/translation';
import {
  IconUserFilled, IconBuilding, IconUsersMoreFilled
} from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import cx from 'classnames';
import { TEXTS } from '../../constants';
import { useEpOnboarding } from '../../contexts';
import { Questions } from './Questions';

export const UsageTierStep = () => {
  const {
    state: { onboardData }, api: { setOnboardData, logAbTestAction }
  } = useEpOnboarding();

  const USAGE_TIER_MAP = [
    {
      usageTier: TEXTS.SIZE_JUST_ME,
      icon: IconUserFilled
    }, {
      usageTier: TEXTS.MY_TEAM,
      icon: IconUsersMoreFilled
    },
    {
      usageTier: TEXTS.MY_ORGANIZATION,
      icon: IconBuilding
    }
  ];

  const handleTierSelect = usageTier => {
    logAbTestAction({ action: 'select', target: `usageTier-${usageTier}` });
    setOnboardData({ usageTier });
  };

  return (
    <section>
      <Questions title={TEXTS.WHO_WILL_BE_USING} description={TEXTS.WHO_WILL_BE_USING_DESCRIPTION} />
      <div className='formItem'>
        <div className='options-wrapper size'>
          {USAGE_TIER_MAP.map(({ usageTier, icon }) => (
            <Button
              key={usageTier}
              size='large'
              variant="outline"
              startIcon={icon}
              className={cx('big-button', {
                selected: onboardData.usageTier === usageTier
              })}
              colorStyle='secondary'
              onClick={() => handleTierSelect(usageTier)}
            >
              {t(usageTier)}
            </Button>
          ))}
        </div>
      </div>
    </section>
  );
};
