import React, { useEffect, useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import { bool, func, string } from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  ALL_TEXTS, ASSET_IDENTIFIER, GA_FORM_TRACKING_ITEMS, gaFormTrackingItemsPropType, userPropType, GA_AD_FORM_TRACKING_ITEMS,
  PrefilledLeadFormAside,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, mapCallbacksToGaTrackingItems, hasAdwordsModeOn
} from '@jotforminc/ep-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import EnterpriseIcon from '../../../../assets/svg/enterpriseRocketMini.svg';
import '../../../../styles/educationDiscountModal.scss';

const EducationDiscountModal = (({
  user,
  onClose,
  logAbTestAction,
  from,
  isTestVariant,
  ...props
}) => {
  const uikitModalRef = useRef(null);
  const { AB_TEST: { EDUCATION_DISCOUNT_MODAL, EDUCATION_DISCOUNT_MODAL_FERPA_VIOLATION_EXAMPLES } } = ASSET_IDENTIFIER;
  const target = isTestVariant ? EDUCATION_DISCOUNT_MODAL_FERPA_VIOLATION_EXAMPLES : EDUCATION_DISCOUNT_MODAL;

  const isAdForm = hasAdwordsModeOn();
  let { [target]: gaFormTrackingItems } = isAdForm ? GA_AD_FORM_TRACKING_ITEMS : GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    const actionData = { action: 'seen', target: `${from ? `${from}-` : ''}${target}` };
    logAbTestAction(actionData);
  }, []);

  const handleCloseClick = () => {
    const actionData = { action: 'click', target: `closeButton-${target}` };
    uikitModalRef.current?.hide();
    logAbTestAction(actionData);
  };

  const handleClose = () => {
    const actionData = { action: 'close', target };
    gaDataLayerPushClose(gaAttributes);
    logAbTestAction(actionData);
    onClose();
  };

  const handleContactSalesClick = () => {
    const actionData = { action: 'click', target: `contactSalesButton-${target}` };
    logAbTestAction(actionData);
  };

  const handleFormSubmit = () => {
    const actionData = { action: 'formSubmit', target };
    logAbTestAction(actionData);
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleContactSalesClick]);

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={prps => <ContentRenderer {...gaAttributes} {...prps} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={handleClose}
    >
      <div className="row">
        <div className="left-col direct-visible">
          <span className='badge'>
            <EnterpriseIcon className="icon" />
            {t(ALL_TEXTS.JOTFORM_ENTERPRISE_CAPITALIZED)}
          </span>
          <p className='description'>
            {translationRenderer(ALL_TEXTS.APPLY_ENTERPRISE_TO_GET_DISCOUNT_ED_TECH_LINED)({
              renderer1: nowrapText => (
                <span className='new-line-on-desktop'>
                  {nowrapText}
                </span>
              )
            })}
          </p>
          <img src='//cdn.jotfor.ms/assets/img/enterprise/education-discount-modal/bg.png' alt={t(ALL_TEXTS.DEDICATED_SUPPORT_DESC)} className="graph-preview" />
        </div>
        {/* aside */}
        <div className="right-col">
          <PrefilledLeadFormAside
            user={user}
            gaAttributes={gaAttributes}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(ALL_TEXTS.SHORT_LEAD_FORM_TITLE)}
            formUiModifier={target}
            onFormSubmit={handleFormSubmit}
            isAdForm={isAdForm}
            {...props}
          />
        </div>
      </div>
    </Modal>
  );
});

EducationDiscountModal.propTypes = {
  user: userPropType.isRequired,
  gaFormTrackingItems: gaFormTrackingItemsPropType.isRequired,
  onClose: func,
  logAbTestAction: func,
  from: string,
  isTestVariant: bool.isRequired
};

EducationDiscountModal.defaultProps = {
  onClose: f => f,
  logAbTestAction: f => f,
  from: ''
};

export default EducationDiscountModal;
