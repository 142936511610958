import React from 'react';
import { func, node } from 'prop-types';
import { t } from '@jotforminc/translation';
import { ALL_TEXTS } from '@jotforminc/ep-utils';
import CloseIcon from '../../../../assets/svg/close_revised.svg';

export const DialogRenderer = ({ children }) => (<div className="jfBasicModal dem-wrapper">{children}</div>);

DialogRenderer.propTypes = { children: node };
DialogRenderer.defaultProps = { children: null };

export const ContentRenderer = ({ children, ...props }) => (
  <div {...props} className="jfBasicModal-content-wrapper">
    <div className="jfBasicModal-content dem-content">
      {children}
    </div>
  </div>
);

ContentRenderer.propTypes = { children: node };
ContentRenderer.defaultProps = { children: null };

export const HeaderRenderer = ({ onCloseClick, ...props }) => (
  <button
    {...props}
    type="button"
    className="modal-close"
    aria-label={t(ALL_TEXTS.CLOSE_MODAL)}
    onClick={onCloseClick}
  >
    <CloseIcon />
  </button>
);

HeaderRenderer.propTypes = { onCloseClick: func };
HeaderRenderer.defaultProps = { onCloseClick: f => f };
