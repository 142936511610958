import React from 'react';
import { t } from '@jotforminc/translation';
import {
  IconAngleDown
} from '@jotforminc/svg-icons';
import { Button, Radio } from '@jotforminc/magnet';
import cx from 'classnames';

import { Questions } from './Questions';
import { INDUSTRIES, TEXTS } from '../../constants';
import { useEpOnboarding } from '../../contexts';

export const IndustryStep = () => {
  const {
    state: { onboardData, ui }, api: { setOnboardData, showOtherIndustries, logAbTestAction }
  } = useEpOnboarding();

  const handleIndustrySelect = selectedIndustry => {
    logAbTestAction({ action: 'select', target: `industry-${selectedIndustry}` });
    setOnboardData({ industry: selectedIndustry });
  };

  return (
    <section>
      <Questions title={TEXTS.WHICH_INDUSTRY_YOU_WORK} description={TEXTS.WHICH_INDUSTRY_YOU_WORK_DESCRIPTION} />
      <div className='formItem'>
        <div className='options-wrapper'>
          <div className='radio-wrapper'>
            {(ui.showOtherIndustries ? INDUSTRIES : INDUSTRIES.slice(0, 8)).map(item => (
              <Radio
                key={item}
                id={item}
                label={t(item)}
                value={item}
                size='small'
                checked={onboardData.industry === item}
                className={cx('radio-item', {
                  selected: onboardData.industry === item
                })}
                onChange={() => handleIndustrySelect(item)}
              />
            ))}
          </div>
        </div>
      </div>

      {!ui.showOtherIndustries ? (
        <Button
          variant="ghost"
          endIcon={IconAngleDown}
          onClick={showOtherIndustries}
        >
          {t(TEXTS.SEE_OTHER_INDUSTRIES)}
        </Button>
      ) : null}
    </section>
  );
};
