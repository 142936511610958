import { renderBlogCaseStudies } from '@jotforminc/enterprise-promotions';
import { CASE_STUDIES } from './caseStudies';

export const initEpCaseStudyAssets = user => {
  const currentPage = global.window.location.pathname;
  const caseStudy = CASE_STUDIES[currentPage];
  if (!caseStudy) return;

  const params = new URLSearchParams(document.location.search);
  const hasParam = params.has(caseStudy.param);
  if (!hasParam) return;

  const enterprisePromotionsBannerRoot = document.getElementById('leadCaptureBanner');
  if (!enterprisePromotionsBannerRoot) return;

  renderBlogCaseStudies({
    user,
    ...caseStudy
  }, enterprisePromotionsBannerRoot);
};
