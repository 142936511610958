import { useEffect, useState } from 'react';

const MOBILE_BREAKPOINT = 1024;

const useIsMobileScreen = () => {
  const isMobileScreen = () => window?.innerWidth <= MOBILE_BREAKPOINT;
  const [isMobile, setIsMobile] = useState(isMobileScreen());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };
    handleResize();
    global.window.addEventListener('resize', handleResize);
    return () => {
      global.window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [isMobile];
};

export default useIsMobileScreen;
