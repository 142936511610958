import React from 'react';
import { cx } from 'class-variance-authority';

import '@jotforminc/jotform.css';

import { IconXmark } from '@jotforminc/svg-icons';
import { tagCVA, tagTextCVA, tagIconCVA } from './tag.cva';
import { TagProps, tagDefaultProps } from './tag.types';

export const Tag = (props: TagProps):JSX.Element => {
  const {
    className,
    children,
    colorStyle = tagDefaultProps.colorStyle,
    size = tagDefaultProps.size,
    icon: BadgeIcon,
    onClick,
    disabled,
    ...rest
  } = props;

  const hasChildren = !!children;
  const hasIcon = !!BadgeIcon;

  const tagClassName = cx(className, tagCVA({
    colorStyle,
    size,
    disabled: !!disabled
  }));

  const tagButtonClassName = cx('shrink-0 inline-flex items-center focus:outline radius-full', {
    'cursor-not-allowed': !!disabled
  });

  return (
    <span className={tagClassName} {...rest}>
      {hasIcon && <BadgeIcon className={tagIconCVA({ size })} />}
      {hasChildren && <span className={tagTextCVA({ size })}>{children}</span>}
      <button
        disabled={!!disabled}
        type="button"
        onClick={onClick}
        className={tagButtonClassName}
      >
        <IconXmark className={tagIconCVA({ size })} />
      </button>
    </span>
  );
};
