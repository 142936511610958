import startCase from 'lodash/startCase';

export const getComponentName = ({
  assetType,
  campaignType,
  assetsVersion,
  assetYear
}) => {
  if (!campaignType || !assetType) {
    return;
  }

  const sanitizedAssetsVersion = assetsVersion || 'v1';
  return [`y${assetYear}`, campaignType, assetType, sanitizedAssetsVersion].map(name => startCase(name.toLowerCase()).replace(/\s+/g, '')).join('');
};
