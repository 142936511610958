import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { bool, func, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { getDomainURL } from '@jotforminc/request-layer';
import { Loading } from '@jotforminc/loading';
import {
  ALL_TEXTS, SHORT_LEAD_FORM_IFRAME_ID,
  gaAttributesPropType, gaFormTrackingItemsPropType, userPropType, LEAD_FORM_MAP, SHOULD_SHOW_AD_FORM
} from '../constants';
import {
  getMandatoryPrefillValues,
  getUserPrefillValues, isDevelopment, prepareFormParams
} from '../utils';
import { useHubspotTracker } from '../hooks';
import FeedbackForm from './FeedbackForm';

const PrefilledLeadFormAside = ({
  user,
  title,
  formID: formIdProp,
  devFormID,
  classNames,
  formUiModifier,
  isIframeEmbed,
  gaAttributes,
  gaFormTrackingItems,
  customFormParamsPreparer,
  onFormSubmit,
  pageUrl,
  automatedNurturingSource,
  fullFormPath,
  isPrefillEnabled,
  isAdForm,
  isDynamicHeightEnabled,
  ...props
}) => {
  const [isFrameLoaded, setIsIframeLoaded] = useState(false);
  const isSubmissionLoggedRef = useRef(false);
  const isFormFillingLoggedRef = useRef(false);
  const isHubspotLoggedRef = useRef(false);
  const formRef = useRef(null);
  const userMandatoryData = useMemo(() => getMandatoryPrefillValues(user), [user]);
  const userData = useMemo(() => getUserPrefillValues(user), [user]);
  const formParamPreparer = customFormParamsPreparer || prepareFormParams;
  let formParams = '';
  if (isPrefillEnabled) {
    formParams = formParamPreparer({
      ...userData, formUiModifier, isIframeEmbed, pageUrl, automatedNurturingSource, ...props
    });
  } else {
    formParams = formParamPreparer({
      ...userMandatoryData, formUiModifier, isIframeEmbed, pageUrl, automatedNurturingSource, ...props
    });
  }

  const inferDevFormIdBasedOnAdwordsMode = () => {
    if (!SHOULD_SHOW_AD_FORM) {
      return devFormID;
    }

    return !isAdForm ? devFormID : LEAD_FORM_MAP.COMMON.AD_FORM_DEV;
  };

  const inferFormIdBasedOnAdwordsMode = () => {
    if (!SHOULD_SHOW_AD_FORM) {
      return formIdProp;
    }

    return !isAdForm ? formIdProp : LEAD_FORM_MAP.COMMON.AD_FORM_LIVE;
  };

  const formId = isDevelopment() ? inferDevFormIdBasedOnAdwordsMode() : inferFormIdBasedOnAdwordsMode();
  const iframeSrc = fullFormPath ? `${fullFormPath}${formParams}` : `${getDomainURL()}/${formId}${formParams}`;

  // hubspot tracking
  const _hsq = window._hsq || [];
  const [isHubspotTrackerLoaded] = useHubspotTracker({ isEnabled: true, user });
  const [iframeHeight, setIframeHeight] = useState('');

  // identify user in hubspot
  useEffect(() => {
    if (!isHubspotTrackerLoaded || isHubspotLoggedRef.current) return;
    _hsq.push(['identify', {
      email: user?.email
    }]);
    _hsq.push(['setPath', window.location.href]);
    _hsq.push(['trackPageView']);
    isHubspotLoggedRef.current = true;
  }, [isHubspotTrackerLoaded]);

  // listen submission events
  useEffect(() => {
    const handleMessage = event => {
      if (isDynamicHeightEnabled) {
        const [name, height] = event.data?.split(':');
        if (name.includes('setHeight')) {
          setIframeHeight(`${height}px`);
        }
      }
      if (
        // event.origin !== 'https://submit.jotform.com'
        event.data.action !== 'submission-completed'
      || isSubmissionLoggedRef.current) {
        return;
      }

      const jobTitle = formRef.current?.querySelector('input[name*="jobTitle"]')?.value || '';
      const country = formRef.current?.querySelector('select[name*="country"]')?.value || '';

      window.dataLayer?.push({
        event: 'form_submit_enterprise',
        'data-id': gaAttributes['data-id'],
        jobTitle,
        country,
        formId
      });
      onFormSubmit();
      isSubmissionLoggedRef.current = true;
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isFrameLoaded]);

  // listen form interactions
  const handleLoad = ({ target }) => {
    try {
      const iframeWindow = target?.contentWindow;
      const iframeDocument = iframeWindow?.document;
      const formElement = iframeDocument?.querySelector('.jotform-form');
      formRef.current = formElement;

      // form input
      formElement?.addEventListener('input', () => {
        if (isFormFillingLoggedRef.current) return;
        window.dataLayer?.push({
          event: 'form_start_filling_enterprise',
          'data-id': gaAttributes['data-id'],
          formId
        });
        isFormFillingLoggedRef.current = true;
      });

      // button clicks
      gaFormTrackingItems?.forEach((trackingItem = {}) => {
        const { selector = '', payload = '' } = trackingItem;
        if (!selector || !payload) return;
        const element = iframeDocument?.querySelector(selector);

        element?.addEventListener('click', () => {
          window.dataLayer?.push(payload); // ga log
          if (typeof trackingItem.callback === 'function') {
            trackingItem.callback(); // jotform actions log
          }
        });
      });
    } finally {
      setIsIframeLoaded(true);
    }
  };

  return (
    <>
      <Loading
        strokeColor="#6C73A8" style={{
          pointerEvents: 'none', zIndex: '1', width: '48px', height: '48px', transition: '.2s all', opacity: isFrameLoaded ? '0' : '1'
        }}
      />
      <iframe
        title={title}
        src={iframeSrc}
        style={{ height: isDynamicHeightEnabled ? `calc(${iframeHeight} + 37px)` : '', transition: '.2s all', opacity: isFrameLoaded ? '1' : '0' }}
        onLoad={handleLoad}
        className={classNames}
        id={SHORT_LEAD_FORM_IFRAME_ID}
      />
      <FeedbackForm user={user} formUiModifier={formUiModifier} />
    </>
  );
};

PrefilledLeadFormAside.propTypes = {
  user: userPropType.isRequired,
  customFormParamsPreparer: func,
  formID: string,
  devFormID: string,
  title: string,
  classNames: string,
  formUiModifier: string,
  isIframeEmbed: bool,
  gaAttributes: gaAttributesPropType.isRequired,
  gaFormTrackingItems: gaFormTrackingItemsPropType.isRequired,
  onFormSubmit: func,
  pageUrl: string,
  automatedNurturingSource: string,
  fullFormPath: string,
  isPrefillEnabled: bool,
  isAdForm: bool,
  isDynamicHeightEnabled: bool
};

PrefilledLeadFormAside.defaultProps = {
  formID: LEAD_FORM_MAP.COMMON.LIVE,
  devFormID: LEAD_FORM_MAP.COMMON.DEV,
  customFormParamsPreparer: undefined,
  title: t(ALL_TEXTS.SHORT_LEAD_FORM_TITLE),
  classNames: 'form',
  formUiModifier: '',
  isIframeEmbed: false,
  onFormSubmit: f => f,
  pageUrl: '',
  automatedNurturingSource: '',
  fullFormPath: '',
  isPrefillEnabled: true,
  isAdForm: false,
  isDynamicHeightEnabled: false
};

export default PrefilledLeadFormAside;
