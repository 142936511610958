import { ActionManager } from '@jotforminc/abtest-manager';
import { useEffect, useRef, useState } from 'react';
import { ACTION_PROJECT_NAMES } from '@jotforminc/ep-utils';

const useActionManager = (user, projectName = ACTION_PROJECT_NAMES.ENTERPRISE_PROMOTIONS, trackOnce = false) => {
  const [actionsLoaded, setActionsLoaded] = useState(false);
  const actionManagerRef = useRef(f => f);

  useEffect(() => {
    const actionManager = new ActionManager({
      user,
      trackOnce,
      projectName
    });
    actionManagerRef.current = actionManager.registerJotformAction;
    setActionsLoaded(loaded => !loaded);
  }, [user]);

  return [actionsLoaded, actionManagerRef.current];
};

export default useActionManager;
