import '@jotforminc/router-bridge/init';
import { Header } from '@jotforminc/header';

import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';

const jfRHeaderShadowHost = document.querySelector('#jf-common-header-host');
const jfRHeaderDocument = jfRHeaderShadowHost?.shadowRoot || document;
const jfRHeaderEl = jfRHeaderDocument ? jfRHeaderDocument.querySelector('#jfHeaderBodyWrapper') : undefined;
const rootEl = document.getElementById('root');

if (jfRHeaderEl) {
  hydrateRoot(jfRHeaderEl, <Header />);
} else {
  const root = createRoot(rootEl);
  root.render(<Header />);
}
