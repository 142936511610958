import { RequestLayer, Interceptors } from '@jotforminc/request-layer';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { dummyGuestUser, dummyFreeUser } from '../constants';

let isLoggedInBackend = false;

const requestLayer = new RequestLayer('/API', {
  interceptorConfig: {
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer],
    shouldCache: true,
    logCachePayload: {
      projectName: 'header'
    }
  }
});

export const dummyCombinedInfo = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      const user = !isLoggedInBackend ? dummyGuestUser : dummyFreeUser;
      resolve({ data: { content: { type: 'USER', credentials: user } } });
    }, 50);
  });
};

export const dummyLogin = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      isLoggedInBackend = true;
      resolve(dummyFreeUser);
    }, 50);
  });
};

const createCombinedInfoUrl = () => {
  let combinedInfoUrl = 'user/combinedinfo?loc=1&campaign=1';

  const { pathname } = global.window.location;
  const isMyForms = pathname.includes('/myforms/');

  if (isEnterprise()) {
    return combinedInfoUrl;
  }

  if (isMyForms) {
    const navLang = global.window.navigator.userLanguage || global.window.navigator.language;
    combinedInfoUrl += `&navLang=${navLang}`; // for lang offer notification
  }

  return combinedInfoUrl;
};

export const fetchUser = async () => requestLayer.get(createCombinedInfoUrl());

export const getUnpaidInvoices = async () => {
  const { mostOverdueInvoice = {} } = requestLayer.get('enterprise/invoice/getUnpaidInvoices');
  return Array.isArray(mostOverdueInvoice) ? mostOverdueInvoice[0] || {} : mostOverdueInvoice || {};
};

export const getEpUserSettings = async key => requestLayer.get(`ep/user/settings/${key}`);

// @ilkeradiguzel
//  In order to check if user has already seen the campaign or not, returns either:
//  => { enterprise2024HealthcareReportModalSeenAt: "1" } which indicates the campaign with the related key has been seen, thus "1",
//  => { content: false, ...props } which indicated the campaign with the related key has NOT been seen, thus content: false.
//  => we can rely on { content } for this specific purpose.
export const userSeenCampaign = async key => {
  const { content } = await getEpUserSettings(key);
  return content !== false;
};

export const initEnterpriseJobs = async () => requestLayer.get('ep/init-jobs');
