import { ASSET_IDENTIFIER } from './assetIdentifier';

const {
  ADMIN_CONSOLE_DARK_MODAL, ASSIGN_TO_ORGANIZATON_MODAL,
  CONTACT_DEDICATED_SUPPORT_MODAL, WHITE_LABELING_MODAL,
  ENTERPRISE_TEAMS_MODAL, TEAMS_MODAL, TEAM_CREATION_LIMIT_MODAL, HELP_DEDICATED_SUPPORT_MODAL,
  MY_ACCOUNT_USERS_SECTION_WITH_FORM, FOLDER_LIMIT_MODAL, APPEXCHANGE_MODAL, ENTERPRISE_DATA_CENTERS_MODAL, ENTERPRISE_BEGINNERS_BOOK_MODAL, PDF_DOWNLOAD_MODAL, ENTERPRISE_CASE_STUDY_MODAL
} = ASSET_IDENTIFIER.PRODUCT;

// For webinars
const {
  ED_TECH_WEBINAR_MODAL, HEALTHCARE_AUTOMATION_STRATEGIES_WEBINAR_MODAL,
  LUNCH_AND_LEARN_MODAL, MAXIMUM_IMPACT_WEBINAR_MODAL, HEALTHCARE_TECHNOLOGY_MODAL,
  HEALTHCARE_FUTURE_WEBINAR_MODAL
} = ASSET_IDENTIFIER.WEBINAR;

// For campaigns
const {
  ETT_EDUCATION_GATED_CONTENT_MODAL, ETT_EDUCATION_EXIT_INTENT_MODAL,
  FOMO_MODAL, FQHC_MODAL, HEALTHCARE_REPORT_MODAL
} = ASSET_IDENTIFIER.CAMPAIGN;

// For AB tests
const {
  ADD_COLLABORATOR_MODAL, AD_BANNER_MODAL, AD_FIRST_EXIT_INTENT_MODAL, AD_SECOND_EXIT_INTENT_MODAL,
  BLOG_BANNER_MODAL, DEDICATED_SUPPORT_MODAL, FASTER_SUPPORT_RESPONSE_MODAL, PRICING_TABLE_MODAL,
  PRICING_TABLE_ISOLATED_MODAL, SHARE_FOLDER_MODAL, SSO_PREFILL_MODAL, PROFESSIONAL_SERVICES_MODAL,
  ZOOM_SUPPORT_MODAL, ASSIGN_FORM_MODAL, BRONZE_PLAN_DISCOUNT_MODAL, SILVER_PLAN_DISCOUNT_MODAL,
  GOLD_PLAN_DISCOUNT_MODAL, ENTERPRISE_PLAN_DISCOUNT_MODAL, BLOG_CONTENT_SIDE_BANNER,
  ENTERPRISE_CHECKOUT_LIKE_SECTION, LOCAL_DATA_RESIDENCY_MODAL, MOBILE_FOOTER_CONTACT_BANNER,
  APPLY_REAL_TIME_SUPPORT_MODAL, EDUCATION_DISCOUNT_MODAL, TEAMS_WHITE_LABELING_MODAL,
  REQUIRE_SSO_MODAL, SYSTEM_LOGS_MODAL, SSO_PREFILL_DETAILED_MODAL, EDUCATION_DISCOUNT_MODAL_FERPA_VIOLATION_EXAMPLES,
  SINGLE_COLUMN_DEDICATED_SUPPORT_MODAL, SCHEDULE_ZOOM_MODAL, SOC2_COMPLIANCE_MODAL
} = ASSET_IDENTIFIER.AB_TEST;

// For automation
const {
  EDUCATION_MODAL, EDUCATION_MODAL_ALT, FIELD_SERVICE_MANAGEMENT_MODAL, GOVERNMENT_MODAL,
  HEALTHCARE_MODAL, NONPROFIT_MODAL, ENTERPRISE_DISCOUNT_MODAL, AU_DATA_RESIDENCY_MODAL,
  HK_DATA_RESIDENCY_MODAL, SG_DATA_RESIDENCY_MODAL, BLOG_GATED_CONTENT_MODAL
} = ASSET_IDENTIFIER.AUTOMATION;

export const GA_FORM_TRACKING_ITEMS = {
  // segmented modals
  // todo: update segmented modals selectors
  [GOVERNMENT_MODAL]: [
    { selector: '#input_99', payload: { event: 'contact-sales-click' } },
    { selector: '#id_100 a', payload: { event: 'learn-more-click' } }
  ],
  [HEALTHCARE_MODAL]: [
    { selector: '#input_99', payload: { event: 'apply-now-click' } },
    { selector: '#id_100 a', payload: { event: 'learn-more-click' } }
  ],
  [EDUCATION_MODAL]: [
    { selector: '#input_99', payload: { event: 'apply-now-click' } },
    { selector: '#id_100 a', payload: { event: 'learn-more-click' } }
  ],
  [FIELD_SERVICE_MANAGEMENT_MODAL]: [
    { selector: '#input_99', payload: { event: 'apply-now-click' } },
    { selector: '#id_100 a', payload: { event: 'learn-more-click' } }
  ],
  [NONPROFIT_MODAL]: [
    { selector: '#input_99', payload: { event: 'apply-now-click' } },
    { selector: '#id_100 a', payload: { event: 'learn-more-click' } }
  ],
  // segmented modals end
  [ADMIN_CONSOLE_DARK_MODAL]: [
    { selector: '#input_74', payload: { event: 'contact-sales-click' } },
    { selector: '#text_76 a', payload: { event: 'learn-more-click' } }
  ],
  [AU_DATA_RESIDENCY_MODAL]: [
    { selector: '#input_81', payload: { event: 'send-request-click' } },
    { selector: '#text_80 a', payload: { event: 'learn-more-click' } }
  ],
  [HK_DATA_RESIDENCY_MODAL]: [
    { selector: '#input_201', payload: { event: 'send-request-click' } },
    { selector: '#text_200 a', payload: { event: 'explore-more-click' } }
  ],
  [SG_DATA_RESIDENCY_MODAL]: [
    { selector: '#input_203', payload: { event: 'send-request-click' } },
    { selector: '#text_202 a', payload: { event: 'explore-more-click' } }
  ],
  [ASSIGN_TO_ORGANIZATON_MODAL]: [
    { selector: '#input_99', payload: { event: 'contact-sales-click' } },
    { selector: '#text_111 a', payload: { event: 'learn-more-click' } }
  ],
  [ENTERPRISE_TEAMS_MODAL]: [
    { selector: '#input_151', payload: { event: 'join-now-click' } },
    { selector: '#text_173 a', payload: { event: 'learn-more-click' } }
  ],
  [TEAM_CREATION_LIMIT_MODAL]: [
    { selector: '#input_173', payload: { event: 'contact-sales-click' } }, // It has been updated. The old one was "contact-sales-click"
    { selector: '#text_108 a', payload: { event: 'learn-more-click' } }
  ],
  [FOLDER_LIMIT_MODAL]: [
    { selector: '#input_174', payload: { event: 'contact-sales-click' } }, // It has been updated. The old one was "contact-sales-click"
    { selector: '#text_175 a', payload: { event: 'learn-more-click' } }
  ],
  [EDUCATION_MODAL_ALT]: [
    { selector: '#input_145', payload: { event: 'apply-now-click' } }
  ],
  [ENTERPRISE_DISCOUNT_MODAL]: [
    { selector: '#input_77', payload: { event: 'apply-now-click' } }
  ],
  [HEALTHCARE_REPORT_MODAL]: [
    { selector: '#input_33', payload: { event: 'get-insights-now-click' } }
  ],
  [PRICING_TABLE_ISOLATED_MODAL]: [
    { selector: '#input_115', payload: { event: 'contact-now-click' } }
  ],
  [PRICING_TABLE_MODAL]: [
    { selector: '#input_117', payload: { event: 'contact-now-click' } }
  ],
  [MY_ACCOUNT_USERS_SECTION_WITH_FORM]: [
    { selector: '#input_83', payload: { event: 'contact-sales-click' } }
  ],
  [BRONZE_PLAN_DISCOUNT_MODAL]: [
    { selector: '#input_77', payload: { event: 'apply-now-click' } }
  ],
  [SILVER_PLAN_DISCOUNT_MODAL]: [
    { selector: '#input_77', payload: { event: 'apply-now-click' } }
  ],
  [GOLD_PLAN_DISCOUNT_MODAL]: [
    { selector: '#input_77', payload: { event: 'apply-now-click' } }
  ],
  [ENTERPRISE_PLAN_DISCOUNT_MODAL]: [
    { selector: '#input_77', payload: { event: 'apply-now-click' } }
  ],
  [ADD_COLLABORATOR_MODAL]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } },
    { selector: '#text_225 a', payload: { event: 'learn-more-click' } }
  ],
  [HEALTHCARE_AUTOMATION_STRATEGIES_WEBINAR_MODAL]: [
    { selector: '#input_2', payload: { event: 'submit-click' } }
  ],
  [LUNCH_AND_LEARN_MODAL]: [
    { selector: '#input_2', payload: { event: 'submit-click' } }
  ],
  [MAXIMUM_IMPACT_WEBINAR_MODAL]: [
    { selector: '#input_2', payload: { event: 'submit-click' } }
  ],
  [HEALTHCARE_FUTURE_WEBINAR_MODAL]: [
    { selector: '#input_15', payload: { event: 'register-click' } },
    { selector: '#text_16', payload: { event: 'learn-more-click' } }
  ],
  [AD_FIRST_EXIT_INTENT_MODAL]: [
    { selector: '#input_129', payload: { event: 'send-request-click' } }
  ],
  [AD_SECOND_EXIT_INTENT_MODAL]: [
    { selector: '#input_129', payload: { event: 'send-request-click' } }
  ],
  [AD_BANNER_MODAL]: [
    { selector: '#input_129', payload: { event: 'send-request-click' } }
  ],
  [DEDICATED_SUPPORT_MODAL]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } }
  ],
  [FOMO_MODAL]: [
    { selector: '#input_127', payload: { event: 'send-request-click' } }
  ],
  [BLOG_CONTENT_SIDE_BANNER]: [
    { selector: '#input_128', payload: { event: 'contact-now-click' } }
  ],
  [FASTER_SUPPORT_RESPONSE_MODAL]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } }
  ],
  [ED_TECH_WEBINAR_MODAL]: [
    { selector: '#input_41', payload: { event: 'register-click' } },
    { selector: '#id_42 a', payload: { event: 'learn-more-click' } }
  ],
  [SSO_PREFILL_MODAL]: [
    { selector: '#input_99', payload: { event: 'contact-sales-click' } },
    { selector: '#text_144 a', payload: { event: 'learn-more-click' } }
  ],
  [ENTERPRISE_CHECKOUT_LIKE_SECTION]: [
    { selector: '#input_138', payload: { event: 'send-request-click' } }
  ],
  [BLOG_BANNER_MODAL]: [
    { selector: '#input_141', payload: { event: 'contact-now-click' } }
  ],
  [SHARE_FOLDER_MODAL]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } }
  ],
  [CONTACT_DEDICATED_SUPPORT_MODAL]: [
    { selector: '#input_115', payload: { event: 'contact-now-click' } }
  ],
  [ETT_EDUCATION_GATED_CONTENT_MODAL]: [
    { selector: '#input_2', payload: { event: 'get-insights-now-click' } }
  ],
  [ETT_EDUCATION_EXIT_INTENT_MODAL]: [
    { selector: '#input_2', payload: { event: 'get-insights-now-click' } }
  ],
  [HELP_DEDICATED_SUPPORT_MODAL]: [
    { selector: '#input_115', payload: { event: 'contact-now-click' } }
  ],
  [WHITE_LABELING_MODAL]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } }
  ],
  [TEAMS_WHITE_LABELING_MODAL]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } }
  ],
  [PROFESSIONAL_SERVICES_MODAL]: [
    { selector: '#input_154', payload: { event: 'apply-now-click' } },
    { selector: '#id_155 a', payload: { event: 'learn-more-click' } }
  ],
  [ZOOM_SUPPORT_MODAL]: [
    { selector: '#input_145', payload: { event: 'apply-now-click' } }
  ],
  [ASSIGN_FORM_MODAL]: [
    { selector: '#input_172', payload: { event: 'apply-now-click' } }
  ],
  [LOCAL_DATA_RESIDENCY_MODAL]: [
    { selector: '#input_176', payload: { event: 'apply-now-click' } }
  ],
  [MOBILE_FOOTER_CONTACT_BANNER]: [
    { selector: '#input_151', payload: { event: 'send-request-click' } }
  ],
  [APPLY_REAL_TIME_SUPPORT_MODAL]: [
    { selector: '#input_177', payload: { event: 'contact-now-click' } }
  ],
  [FQHC_MODAL]: [
    { selector: '#input_2', payload: { event: 'request-now-click' } }
  ],
  [EDUCATION_DISCOUNT_MODAL]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } }
  ],
  [EDUCATION_DISCOUNT_MODAL_FERPA_VIOLATION_EXAMPLES]: [
    { seletor: '#input_206', payload: { event: 'contact-sales-click' } }
  ],
  [ENTERPRISE_DATA_CENTERS_MODAL]: [
    { selector: '#input_181', payload: { event: 'contact-sales-click' } },
    { selector: '#text_182', payload: { event: 'learn-more-click' } }
  ],
  [REQUIRE_SSO_MODAL]: [
    { selector: '#input_189', payload: { event: 'apply-now-click' } },
    { selector: '#input_190', payload: { event: 'learn-more-click' } }
  ],
  [SYSTEM_LOGS_MODAL]: [
    { selector: '#input_191', payload: { event: 'contact-now-click' } }
  ],
  [SSO_PREFILL_DETAILED_MODAL]: [
    { selector: '#input_192', payload: { event: 'contact-sales-click' } },
    { selector: '#text_193 a', payload: { event: 'learn-more-click' } }
  ],
  [APPEXCHANGE_MODAL]: [
    { selector: '#input_194', payload: { event: 'contact-now-click' } }
  ],
  [TEAMS_MODAL]: [
    { selector: '#input_196', payload: { event: 'contact-sales-click' } }
  ],
  [HEALTHCARE_TECHNOLOGY_MODAL]: [
    { selector: '#text_16', payload: { event: 'learn-more-click' } },
    { selector: '#input_15', payload: { event: 'register-click' } },
    { selector: '#input_196', payload: { event: 'submit-application-click' } }
  ],
  [BLOG_GATED_CONTENT_MODAL]: [
    { selector: '#input_2', payload: { event: 'submit-click' } }
  ],
  [ENTERPRISE_BEGINNERS_BOOK_MODAL]: [
    { selector: '#input_33', payload: { event: 'download-now-click' } }
  ],
  [ENTERPRISE_CASE_STUDY_MODAL]: [
    { selector: '#input_2', payload: { event: 'get-the-guide-click' } }
  ],
  [SINGLE_COLUMN_DEDICATED_SUPPORT_MODAL]: [
    { selector: '#text_215 a', payload: { event: 'learn-more-click' } },
    { selector: '#input_216', payload: { event: 'apply-now-click' } }
  ],
  [SCHEDULE_ZOOM_MODAL]: [
    { selector: '#input_230', payload: { event: 'contact-now-click' } }
  ],
  [SCHEDULE_ZOOM_MODAL]: [
    { selector: '#input_230', payload: { event: 'contact-now-click' } }
  ],
  [PDF_DOWNLOAD_MODAL]: [
    { selector: '#input_98', payload: { event: 'get-pdf-now-click' } }
  ],
  [SOC2_COMPLIANCE_MODAL]: [
    { selector: '#text_239 a', payload: { event: 'learn-more-click' } },
    { selector: '#input_238', payload: { event: 'contact-sales-click' } }
  ]
};
