import React from 'react';
import ReactDOM from 'react-dom';
import { BlogEducationSideBanner } from '../components';

const renderFerpaBanner = ({
  rootEl = null,
  user = null,
  logAbTestAction = f => f,
  isTestVariant = false
}) => {
  if (!rootEl) return;

  ReactDOM.render(
    <BlogEducationSideBanner
      user={user}
      isFormVisible={false}
      logAbTestAction={logAbTestAction}
      isTestVariant={isTestVariant}
    />,
    rootEl
  );
};

export default renderFerpaBanner;
