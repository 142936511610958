import React, {
  useEffect, useMemo, useState
} from 'react';
import { IconMessageEllipsisPencilFilled, IconXmarkSm } from '@jotforminc/svg-icons';
import { checkUserType } from '@jotforminc/utils';
import cx from 'classnames';
import { Loading } from '@jotforminc/loading';
import { string } from 'prop-types';
import { userPropType } from '../constants';
import './feedbackSection.scss';
import {
  getUserPrefillValues
} from '../utils';

const FeedbackForm = ({ user, formUiModifier }) => {
  const isUserAdmin = checkUserType('ADMIN', user);
  if (!isUserAdmin) return null;

  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const feedbackFormClasses = cx('feedback-form-wrapper', {
    show: isFeedbackFormOpen
  });

  const memoizedIframeSrc = useMemo(() => {
    const { email, nameFirst, nameLast } = getUserPrefillValues(user);
    const pathName = window.location.pathname;
    return `https://form.jotform.com/242121390294955?feedbackEmbedButton&pathName=${pathName}&formUiModifier=${formUiModifier}&yourName[0]=${nameFirst}&yourName[1]=${nameLast}&email=${email}`;
  }, [user]);

  useEffect(() => {
    if (isFeedbackFormOpen) {
      setIsIframeLoaded(false);
      setIframeSrc(memoizedIframeSrc);
    } else {
      setIframeSrc('');
    }
  }, [isFeedbackFormOpen, memoizedIframeSrc]);

  return (
    <div className='prefill-asset-wrapper'>
      <div className='feedback-cta-wrapper'>
        <span className='explanation'>Admin Actions</span>
        <button
          id="feedback-form-open"
          className="feedback-cta"
          type="button"
          onClick={() => setIsFeedbackFormOpen(o => !o)}
          hover-tooltip="Give feedback to the enterprise asset"
        >
          <IconMessageEllipsisPencilFilled />
        </button>
      </div>

      <div
        id="feedback-form-container"
        className={feedbackFormClasses}
      >
        <Loading
          strokeColor="#6C73A8" style={{
            pointerEvents: 'none', zIndex: '1', width: '48px', height: '48px', transition: '.2s all', opacity: isIframeLoaded ? '0' : '1'
          }}
        />
        <button
          id="feedback-form-close"
          type='button'
          className="feedback-form-close"
          onClick={() => setIsFeedbackFormOpen(false)}
        >
          <IconXmarkSm />
        </button>
        <iframe
          id="feedback-form"
          title="Feedback Form"
          className="feedback-form"
          src={iframeSrc}
          onLoad={() => setIsIframeLoaded(true)}
        />
      </div>
    </div>
  );
};

FeedbackForm.propTypes = {
  user: userPropType.isRequired,
  formUiModifier: string.isRequired
};

export default FeedbackForm;
