import { renderProfServicesAsset } from '@jotforminc/enterprise-promotions';

const renderUserGuideBanner = (helpRoot, helpRootId) => {
  if (!helpRoot) return;

  renderProfServicesAsset({
    assetName: 'UserGuideProfessionalServicesBanner',
    rootElementId: helpRootId
  });
};

const renderFaqBanner = (faqRoot, faqRootId) => {
  if (!faqRoot) return;

  renderProfServicesAsset({
    assetName: 'GeneralFaqProfessionalServicesBanner',
    rootElementId: faqRootId
  });
};

export const initEpProfessionalServices = () => {
  const helpRootId = 'help-prof-services-banner'; // https://github.com/jotform/backend/blob/95dc3f04351536a5147c04e7c898fa39ce0ed878/projects/wp-blog/twig_templates/help/help-sidebar.twig.html#L18
  const faqRootId = 'faq-footer-prof-services-banner'; // https://github.com/jotform/backend/blob/95dc3f04351536a5147c04e7c898fa39ce0ed878/projects/wp-blog/twig_templates/faq/faq.twig.html#L19

  const helpRoot = document.querySelector(`#${helpRootId}`);
  const faqRoot = document.querySelector(`#${faqRootId}`);

  const isEnabled = true;
  const bannerIsAvailable = (helpRoot || faqRoot) && isEnabled;

  if (!bannerIsAvailable) return;

  renderUserGuideBanner(helpRoot, helpRootId);
  renderFaqBanner(faqRoot, faqRootId);
};
