import React from 'react';
import { string } from 'prop-types';

import { t } from '@jotforminc/translation';

// A/B Test: contactSalesButtonOnHeader
const ContactSalesMobile = ({ contactSalesAbTestVariationURL }) => {
  return (
    <a
      type="button"
      href={contactSalesAbTestVariationURL}
      className="jfRHeader--mobile-nav-signup-btn locale"
    >
      {t('Contact Sales')}
    </a>
  );
};

ContactSalesMobile.propTypes = {
  contactSalesAbTestVariationURL: string.isRequired
};

export default ContactSalesMobile;
