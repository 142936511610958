import { ALL_TEXTS } from './texts';

export const ENTERPRSE_PROMOTIONS_ROOT_EL_ID = 'enterprise-promotions-root';

export const ACTION_PROJECT_NAMES = {
  TEAMS: 'teams',
  AU_DATA_RESIDENCY_MODAL: 'auDataResidencyModalCampaign',
  ENTERPRISE_PROMOTIONS: 'enterprisePromotions' // always use this
  // dont use new project name for enterprise promotions assets
};

export const SHORT_LEAD_FORM_IFRAME_ID = 'enterprise-short-lead-form';

export const VARIANTS = {
  CONTROL_CODE: '8521',
  TEST_CODE: '8531'
};

export const govModalSeenAtUserSettingsKey = 'enterpriseGovernmentModalSeenAt';
export const nonProfitModalSeenAtUserSettingsKey = 'enterpriseNonProfitWebinarModalSeenAt';
export const nonProfitBannerSeenAtUserSettingsKey = 'enterpriseNonProfitWebinarBannerSeenAt';
export const healthcareReportModalSeenAtUserSettingsKey = 'enterprise2024HealthcareReportModalSeenAt';
export const healthcareReportBannerSeenAtUserSettingsKey = 'enterprise2024HealthcareReportBannerSeenAt';
export const professionalSolutionsBannerSeenAtKey = 'professionalSolutionsBannerSeenAt';
export const costCuttingWebinarModalSeenAtKey = 'costCuttingWebinarModalSeenAt';
export const costCuttingWebinarBannerSeenAtKey = 'costCuttingWebinarBannerSeenAt';
export const australianDataResidencyModalSeenAtKey = 'australianDataResidencyModalSeenAt';
export const fieldServiceWebinarModalSeenAtKey = 'fieldServiceWebinarModalSeenAt';
export const privacyConcernWebinarModalSeenAtKey = 'privacyConcernWebinarModalSeenAt';
export const milestoneHubspotModalKey = 'milestoneHubspotModalSeenAt';
export const earnedBadgesModalSeenAtKey = 'earnedBadgesModalSeenAt';
export const hasWebinarModalSeenAtKey = 'hasWebinarModalSeenAt';
export const ettEducationReportModalSeenAtKey = 'ettEducationReportModalSeenAt';
export const maximumImpactWebinarModalSeenAtKey = 'maximumImpactWebinarModalSeenAt';
export const healthcareFutureWebinarModalSeenAtKey = 'healthcareFutureWebinarModalSeenAt';

export const lunchAndLearnNotificationSeenAtKey = 'lunchAndLearnNotificationSession2SeenAt';
export const enterprisePriceAdjustmentNotificationSeenAtKey = 'fomoNotificationSeenAt';
export const enterprisePriceAdjustmentNotificationA1FirstSeenAtKey = 'fomoA1ThirdNotificationSeenAt';
export const fomoB1FirstModalSeenAtKey = 'fomoB1FirstModalSeenAt';
export const adTechWebinarModalSeenAtKey = 'edtechWebinarSept2023SeenAt';

export const COUNTDOWN_UNIT = {
  DAYS: 'DAYS',
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
  LIVE: 'LIVE'
};

// lunch and learn banner
// update these constants for the next session
export const lalbSession = 5;
export const lalbSessionName = `session${lalbSession}`;
export const lalbNeverShowKey = 'lunchAndLearnBannerSeenAt';
export const lalbSessionKey = `lunchAndLearnBannerSession${lalbSession}SeenAt`;

export const TEST_EVENT_DATE = {
  ANNOUNCEMENT_DATE_TIME: '2023-03-09T10:00:00.000+03:00',
  EVENT_DATE_TIME: '2023-03-10T14:00:00.000-05:00',
  EVENT_DURATION: '03:00'
};

export const LUNCH_AND_LEARN_SESSIONS = [{
  ANNOUNCEMENT_DATE_TIME: '2023-02-28T10:00:00.000+03:00', // UTC+3
  EVENT_DATE_TIME: '2023-03-02T12:00:00.000-05:00', // UTC-5
  EVENT_DURATION: '00:30',
  CONTENT: ALL_TEXTS.LUNCH_LEARN_CONTENT_SESSION_1
}, {
  ANNOUNCEMENT_DATE_TIME: '2023-03-13T10:00:00.000+03:00', // UTC+3
  EVENT_DATE_TIME: '2023-03-16T12:00:00.000-05:00', // UTC-5
  EVENT_DURATION: '00:30',
  CONTENT: ALL_TEXTS.LUNCH_LEARN_CONTENT_SESSION_2
}, {
  ANNOUNCEMENT_DATE_TIME: '2023-03-27T10:00:00.000+03:00', // UTC+3
  EVENT_DATE_TIME: '2023-03-30T12:00:00.000-05:00', // UTC-5
  EVENT_DURATION: '00:30',
  CONTENT: ALL_TEXTS.LUNCH_LEARN_CONTENT_SESSION_3
},
{
  ANNOUNCEMENT_DATE_TIME: '2023-04-10T10:00:00.000+03:00', // UTC+3
  EVENT_DATE_TIME: '2023-04-13T12:00:00.000-05:00', // UTC-5
  EVENT_DURATION: '00:30',
  CONTENT: ALL_TEXTS.LUNCH_LEARN_CONTENT_SESSION_4
},
{
  ANNOUNCEMENT_DATE_TIME: '2023-04-24T10:00:00.000+03:00', // UTC+3
  EVENT_DATE_TIME: '2023-04-27T12:00:00.000-05:00', // UTC-5
  EVENT_DURATION: '00:30',
  CONTENT: ALL_TEXTS.LUNCH_LEARN_CONTENT_SESSION_5
},
{
  ANNOUNCEMENT_DATE_TIME: '2023-05-08T10:00:00.000+03:00', // UTC+3
  EVENT_DATE_TIME: '2023-05-11T12:00:00.000-05:00', // UTC-5
  EVENT_DURATION: '00:30',
  CONTENT: ALL_TEXTS.LUNCH_LEARN_CONTENT_SESSION_6
}];

export const COST_CUTTING_WEBINAR_ASSET_TYPE = {
  MODAL: 'modal',
  BANNER: 'banner'
};

export const SEGMENTED_MODAL_TYPES = {
  GOVERNMENT: 'government',
  HEALTHCARE: 'healthcare',
  EDUCATION: 'education',
  FIELD_SERVICES_MANAGEMENT: 'fieldServicesManagement',
  NONPROFIT: 'nonprofit'
};

export const PRICING_TABLE_AB_TEST_VARIANTS = {
  CONTROL_A: '8701',
  TEST_B: '8711',
  TEST_C: '8721'
};

export const PRICING_TABLE_SOURCE_PAGES = {
  EDUCATION: 'education',
  NONPROFIT: 'nonprofit'
};

const { EDUCATION, NONPROFIT } = PRICING_TABLE_SOURCE_PAGES;

export const BRONZE_DM_DESCRIPTION = {
  [EDUCATION]: ALL_TEXTS.APPLY_BRONZE_EDUCATION_DISCOUNT,
  [NONPROFIT]: ALL_TEXTS.APPLY_BRONZE_NONPROFIT_DISCOUNT
};

export const SILVER_DM_DESCRIPTION = {
  [EDUCATION]: ALL_TEXTS.APPLY_SILVER_EDUCATION_DISCOUNT,
  [NONPROFIT]: ALL_TEXTS.APPLY_SILVER_NONPROFIT_DISCOUNT
};

export const GOLD_DM_DESCRIPTION = {
  [EDUCATION]: ALL_TEXTS.APPLY_GOLD_EDUCATION_DISCOUNT,
  [NONPROFIT]: ALL_TEXTS.APPLY_GOLD_NONPROFIT_DISCOUNT
};

export const ENTERPRISE_DM_DESCRIPTION = {
  [EDUCATION]: ALL_TEXTS.ENTERPRISE_DISCOUNT_EDUCATION_DESCRIPTION,
  [NONPROFIT]: ALL_TEXTS.ENTERPRISE_DISCOUNT_NONPROFIT_DESCRIPTION
};

export const leadFlowBannerUrlParam = 'epalf';
export const leadFlowBannerAutoVisibleLcStKey = 'leadFlowBannerAutoVisible';

export const eduBdmFlowInterctedLcStKey = 'eduBdmFlowBannerInteracted';
export const eduBdmFlowVisitCountLcStKey = 'eduBdmFlowBannerVisitCount';
export const eduBdmFlowAutoVisibleLcStKey = 'eduBdmFlowBannerAutoVisible';

export const CHECKOUT_LIKE_SECTION_STEPS = {
  FORM_STEP: 'FORM_STEP',
  THANK_YOU_STEP: 'THANK_YOU_STEP'
};

export const SHOULD_SHOW_AD_FORM = false;
