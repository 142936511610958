import { renderModal } from '@jotforminc/ep-utils';
import { EttEducationGatedContentModal } from '../components';

const renderEttEducationGatedContentModal = async props => {
  renderModal({
    Modal: EttEducationGatedContentModal,
    ...props
  });
};

export default renderEttEducationGatedContentModal;
