import { RequestLayer, Interceptors } from '@jotforminc/request-layer';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { ACTION_PROJECT_NAMES } from '../constants';
import { isProd } from '../utils';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

// set enterprise promotions user settings by key
export const setEpUserSettings = (key, value = 1) => {
  return layer.post('ep/user/settings',
    new URLSearchParams({ key, value }));
};

// get enterprise promotions user settings by key
export const getEpUserSettings = key => {
  return layer.get(`ep/user/settings/${key}`);
};

// get enterprise promotions user settings by key
export const getEpUserDetails = () => {
  return layer.get('ep/user-details');
};

// enterprise promotion assets
export const getFirstAvailableEnterprisePromotion = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return layer.get(`ep/user/promotion?${searchParams}`);
};

// enterprise promotion assets
export const getEnterprisePromotionByAssetName = asset => {
  const searchParams = new URLSearchParams(window.location.search);
  return layer.get(`ep/user/promotion/${asset}?${searchParams}`);
};

// save customer to hubspot as form submission
export const saveCustomerAsHubspotFormByUsername = params => {
  if (isEnterprise()) return;

  const searchParams = new URLSearchParams();

  const { lastProductAssetInteraction } = params;

  if (lastProductAssetInteraction) {
    searchParams.append('last_product_asset_interaction', lastProductAssetInteraction);
  }

  return layer.post('ep/user/hubspot-contact-form', searchParams);
};

export const setEPActions = ({
  asset, target, action, intent
}) => {
  if (isProd()) {
    const paramsObj = {
      project: ACTION_PROJECT_NAMES.ENTERPRISE_PROMOTIONS,
      asset,
      target,
      action
    };

    if (intent) {
      paramsObj.intent = intent;
    }

    const params = new URLSearchParams(paramsObj);

    return layer.post('ep/user/actions', params);
  }
};
