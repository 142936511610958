import { readCookie, isSalesforceEnv } from '@jotforminc/utils';
import { LEAD_FORM_SOURCE_MODIFIERS, SHOULD_SHOW_AD_FORM } from '../constants';
import { isGenericEmail } from './utils';

export const getAdwordsUtmsFromCookie = () => {
  let decodedAdwordsUtms = '';
  let parsedAdwordsUtms = {};
  const adwordsUtms = readCookie('adwords_utms');
  if (!adwordsUtms) return parsedAdwordsUtms;
  try {
    decodedAdwordsUtms = decodeURIComponent(adwordsUtms);
    parsedAdwordsUtms = JSON.parse(decodedAdwordsUtms);
  } catch (e) {
    console.warn(e);
  }

  return { ...parsedAdwordsUtms, urlParam: adwordsUtms };
};

const createParam = (key = '', value = '') => ((key && ![null, undefined, ''].includes(value)) ? `${key}=${value}` : '');

const mergeParams = (...params) => params.filter(param => param).join('&');

const createAdwordsUtmParams = () => {
  const {
    utm_source: utmSource = '',
    utm_campaign: utmCampaign = '',
    utm_medium: utmMedium = '',
    utm_term: utmTerm = '',
    utm_content: utmContent = '',
    utm_scp: utmScope = '',
    urlParam = ''
  } = getAdwordsUtmsFromCookie();

  const { ADWORDS } = LEAD_FORM_SOURCE_MODIFIERS;

  const urlParams = createParam('url_params', urlParam);
  const utmSourceParam = createParam('utm_source', utmSource);
  const utmCampaignParam = createParam('utm_campaign', utmCampaign);
  const utmMediumParam = createParam('utm_medium', utmMedium);
  const utmTermParam = createParam('utm_term', utmTerm);
  const utmContentParam = createParam('utm_content', utmContent);
  const utmScopeParam = createParam('utm_scp', utmScope);
  const adwordsGclidParam = createParam('gclid', readCookie('adwords_gclid'));
  const formLeadSourceModifierParam = createParam('formLeadSourceModifier', readCookie('adwords_mode') === 'on' ? ADWORDS : '');

  return mergeParams(urlParams, utmSourceParam, utmCampaignParam, utmMediumParam, utmTermParam, utmContentParam, utmScopeParam, adwordsGclidParam, formLeadSourceModifierParam);
};

const createUserInformationParams = ({
  username, nameFirst, nameLast, email, jobTitle, company, country
}) => {
  const usernameParam = createParam('userFullName', username);
  const nameParam = createParam('fullName[first]', nameFirst);
  const lastNameParam = createParam('fullName[last]', nameLast);
  const emailParam = createParam('userEmail', email);
  const jobTitleParam = createParam('jobTitle', jobTitle);
  const companyParam = createParam('organizationName', company);
  const countryParam = createParam('country', country);

  return mergeParams(usernameParam, nameParam, lastNameParam, emailParam, jobTitleParam, companyParam, countryParam);
};

// regular params
export const prepareFormParams = ({
  username, nameFirst, nameLast, email, bsgEmail, jobTitle, company, country,
  variantCode, formUiModifier, isIframeEmbed, pageUrl, automatedNurturingSource
}) => {
  const userInforParams = createUserInformationParams({
    username, nameFirst, nameLast, email, jobTitle, company, country
  });
  const adwordsUtmParams = createAdwordsUtmParams();
  const pageUrlParam = createParam('pageUrl', pageUrl);
  const abTestParam = createParam('wordingtestvariantcode', variantCode);
  const iframeEmbedParam = createParam('isIframeEmbed[0]', isIframeEmbed);
  const salesForceParam = createParam('isSalesforce[0]', isSalesforceEnv());
  const professionalServicesParam = createParam('professionalServicesNote', readCookie('professionalServicesIntent') === 'yes' ? 'professionalServices' : '');
  const jotformAiAgentNoteParam = createParam('jotformAiAgentNote', readCookie('jotformAiAgentNote'));
  const jotformGovernmentIntentParam = createParam('jotformGovernmentIntent', readCookie('jotformGovernmentIntent'));
  const formUiModifierParam = createParam('formUiModifier', formUiModifier);
  const automatedNurturingSourceParam = createParam('automated_email_nurturing_source', automatedNurturingSource);
  const abNoteParam = createParam('abNote', readCookie('abNote'));
  const isGenericEmailParam = createParam('isGenericEmail', isGenericEmail(bsgEmail));
  const bsgEmailParam = createParam('bsgEmail', bsgEmail);

  const allParams = mergeParams(
    userInforParams, adwordsUtmParams,
    formUiModifierParam, pageUrlParam, iframeEmbedParam,
    salesForceParam, professionalServicesParam, jotformAiAgentNoteParam,
    jotformGovernmentIntentParam, abTestParam, abNoteParam, isGenericEmailParam, bsgEmailParam,
    automatedNurturingSourceParam, 'nojump', 'noToolBar'
  );

  return `?${allParams}`;
};

// non-profit params
export const prepareNonprofitFormParams = ({
  username, email
}) => {
  const userNameParam = createParam('jotformUsername', username);
  const emailParam = createParam('emailAddress15', email);
  return `?${userNameParam}&${emailParam}&nojump&noToolBar`;
};

export const hasAdwordsModeOn = () => {
  if (!SHOULD_SHOW_AD_FORM) return false;
  return readCookie('adwords_mode') === 'on';
};
