import React, { ChangeEvent, MouseEvent } from 'react';
import { useListItem } from '@floating-ui/react';
import { cx } from 'class-variance-authority';
import { useDropdownContext, useDropdownGroupContext } from '../../contexts';
import { DropdownOptionProps } from './dropdown.types';
import { dropdownOptionCVA, dropdownOptionIconCVA } from './dropdown.cva';
import { Checkbox } from '../Checkbox';

export const DropdownOption = (props: DropdownOptionProps):JSX.Element => {
  const {
    value,
    children,
    icon: OptionIcon,
    disabled,
    tagStyles,
    tagColorStyle,
    onClick,
    onChange,
    className,
    id,
    ...rest
  } = props;

  const {
    activeIndex,
    selectedIndex,
    getItemProps,
    handleSelect,
    handleMultiSelect,
    size,
    theme,
    multiple,
    selectedOptionArray
  } = useDropdownContext();

  const {
    indent
  } = useDropdownGroupContext();

  const { ref, index } = useListItem({ label: value as string });
  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;
  const isChecked = !!selectedOptionArray?.find(option => option.value === value);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value: chValue } = e.target;

    handleMultiSelect({
      checked, value: chValue, tagStyles, tagColorStyle, children, icon: OptionIcon
    });

    if (onChange) onChange(e);
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    handleSelect({
      index, children, icon: OptionIcon, value
    });
    if (onClick) onClick(e);
  };

  const iconMargin = cx({
    'mr-2': size === 'small',
    'mr-3': size !== 'small'
  });

  const dropdownOptionClassName = cx(
    dropdownOptionCVA({
      size,
      isSelected: multiple ? isChecked : isSelected,
      isActive,
      theme,
      disabled: !!disabled,
      as: 'option',
      indent: !!indent
    }),
    className
  );

  if (multiple) {
    return (
      <label
        className={dropdownOptionClassName}
        htmlFor={id}
      >
        <Checkbox
          ref={ref}
          role="option"
          value={value}
          disabled={disabled}
          tabIndex={isActive ? 0 : -1}
          checked={isChecked}
          size={size}
          className={iconMargin}
          theme={theme}
          {...getItemProps({
            onChange: handleCheckboxChange
          })}
          {...rest}
        />
        {children}
      </label>
    );
  }

  return (
    <button
      ref={ref}
      role="option"
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      type="button"
      value={value}
      disabled={disabled}
      className={dropdownOptionClassName}
      {...getItemProps({
        onClick: handleClick
      })}
      {...rest}
    >
      {OptionIcon && <OptionIcon className={cx(dropdownOptionIconCVA({ size }))} />}
      {children}
    </button>
  );
};

DropdownOption.displayName = 'DropdownOption';
