import React, { createContext } from 'react';
import { userTypePropType } from '@jotforminc/header-body';
import {
  bool, node, shape
} from 'prop-types';
import { useUser } from '../../hooks';

export const UserContext = createContext();

const UserProvider = ({
  user: userAsProp,
  userType: userTypeAsProp,
  isLoggedIn: isLoggedinAsProp,
  children
}) => {
  const [user, isLoggedIn, userType] = useUser(userAsProp, userTypeAsProp, isLoggedinAsProp);

  return (
    <UserContext.Provider value={{ user, isLoggedIn, userType }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  user: shape({}),
  userType: userTypePropType,
  children: node,
  isLoggedIn: bool
};

UserProvider.defaultProps = {
  user: undefined,
  userType: undefined,
  isLoggedIn: undefined,
  children: null
};

export default UserProvider;
