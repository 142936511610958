import React from 'react';
import { bool, func } from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { IconBars, IconXmark } from '@jotforminc/svg-icons';

const HamburgerButton = ({ onClick, isActive }) => {
  return (
    <button
      aria-label={isActive ? t('Close Navigation') : t('Open Navigation')}
      type="button"
      onClick={onClick}
      className={cx('jfRHeader--hamburger', {
        isActive
      })}
    >
      {isActive ? <IconXmark /> : <IconBars />}
    </button>
  );
};

HamburgerButton.propTypes = {
  onClick: func,
  isActive: bool
};

HamburgerButton.defaultProps = {
  onClick: f => f,
  isActive: false
};

export default HamburgerButton;
