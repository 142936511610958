import styled from 'styled-components';

const ScHeaderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--jf-header-size);
  padding: 0 12px;
  &:not(.isMobileMenuActive) {
    @media screen and (min-width: 480px) {
      max-width: 608px;
      padding: 0 16px;
    }
    @media screen and (min-width: 640px) {
      max-width: 736px;
    }
    @media screen and (min-width: 768px) {
      max-width: 984px;
    }
    @media screen and (min-width: 1024px) {
      max-width: 1200px;
    }
    @media screen and (min-width: 1280px) {
      max-width: 1296px;
    }
  }
  &.mobileScreen {
    position: relative;
    z-index: 1;
  }
  &.isMobileMenuActive {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    .jfRHeader--mobile-nav-signup-btn {
      display: none;
    }
  }
`;

export default ScHeaderContainer;
