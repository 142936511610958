import { useEffect } from 'react';
import { identifyUserForFullstory } from '../utils';

const useFullstory = ({ user, ...props }) => {
  useEffect(() => {
    identifyUserForFullstory({ user, ...props });
  }, [user]);
};

export default useFullstory;
