import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import {
  element, shape
} from 'prop-types';
import { handleCustomNavigation } from '@jotforminc/utils';
import { deleteUserSetting, setUserSettings } from '../utils';
import { EP_ONBOARDING_TEST, INDUSTRIES, TEXTS } from '../constants';
import { useLogAbTestAction, useFullstory } from '../hooks';

const EpOnboardingContext = createContext();

// TODO(@ilkeradiguzel): refactor (especially the switch case part), introduce reducer
const EpOnboardingProvider = ({
  user,
  children,
  onboardingData
}) => {
  const shouldShowOtherIndustryFromPreviousAttempt = () => {
    if (!onboardingData.industry) return false;

    const industryBelongsToDisplayedOnes = INDUSTRIES.slice(0, 8).includes(onboardingData.industry);
    return !industryBelongsToDisplayedOnes;
  };

  const [state, setState] = useState(() => ({
    user,
    maxStep: Number(onboardingData.progress),
    currentStep: Number(onboardingData.progress),
    onboardData: {
      usageTier: onboardingData.usageTier,
      industry: onboardingData.industry?.replace('&amp;', '&'),
      organizationName: onboardingData.company,
      organizationWebsite: user.website,
      organizationSize: onboardingData.company_size,
      jobRole: onboardingData.jobRole,
      managementLevel: onboardingData.managementLevel
    },
    ui: {
      showOtherIndustries: shouldShowOtherIndustryFromPreviousAttempt(),
      isLoading: false
    }
  }));

  const {
    currentStep,
    maxStep,
    onboardData: {
      usageTier, industry, organizationName, organizationSize, organizationWebsite, jobRole, managementLevel
    }
  } = state;

  const [logAbTestAction] = useLogAbTestAction(user, true, true, EP_ONBOARDING_TEST);
  useFullstory({ user, projectName: EP_ONBOARDING_TEST, isEnabled: true });

  const isSkippableStep = () => currentStep > 1 && usageTier === TEXTS.SIZE_JUST_ME;

  const canProceedNextStep = () => {
    let proceed = true;
    switch (currentStep) {
      case 0:
        if (!usageTier) proceed = false;
        break;

      case 1:
        if (!industry) proceed = false;
        break;

      case 2:
        if (isSkippableStep()) break;
        if (!organizationName || !organizationWebsite || !organizationSize) proceed = false;
        break;

      case 3:
        if (isSkippableStep()) break;
        if (!jobRole || !managementLevel) proceed = false;
        break;

      default:
        break;
    }

    return proceed;
  };

  const saveCurrentProgress = async () => {
    const currentOnboardingData = {};
    switch (currentStep) {
      case 0:
        currentOnboardingData.usageTier = usageTier;
        break;

      case 1:
        currentOnboardingData.industry = industry;
        break;

      case 2:
        if (organizationName) currentOnboardingData.company = organizationName;
        if (organizationWebsite) currentOnboardingData.website = organizationWebsite;
        if (organizationSize) currentOnboardingData.company_size = organizationSize;
        break;

      case 3:
        if (jobRole) currentOnboardingData.jobRole = jobRole;
        if (managementLevel) currentOnboardingData.managementLevel = managementLevel;
        break;

      default:
        break;
    }

    if (currentStep + 1 > maxStep) {
      currentOnboardingData.onboardingProgress = currentStep + 1;
    }

    await setUserSettings(user.username, currentOnboardingData);
  };

  useEffect(() => {
    if (currentStep > 0) return;

    setUserSettings(user.username, { onboardingProgress: currentStep });
  }, []);

  useEffect(() => {
    logAbTestAction({ action: 'seen', target: `onboarding-step${currentStep + 1}` });
  }, [currentStep]);

  const handleNextClick = async () => {
    try {
      await saveCurrentProgress();
      // TODO(@ilkeradiguzel): meaning end of the step, refactor below to bind it to the step dynamically, currently it resolves the circular dependency issue..
      if (currentStep < 3) {
        setState(currentState => ({ ...currentState, currentStep: currentState.currentStep + 1, maxStep: Math.max(currentState.currentStep + 1, currentState.maxStep) }));
      } else {
        await deleteUserSetting('onboardingProgress');
        handleCustomNavigation('/myforms', '_self', true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBackClick = () => {
    if (currentStep < 1) return;

    setState(currentState => ({ ...currentState, currentStep: currentState.currentStep - 1 }));
  };

  const setOnboardData = data => {
    setState(currentState => ({ ...currentState, onboardData: { ...currentState.onboardData, ...data } }));
  };

  const showOtherIndustries = () => {
    setState(currentState => ({ ...currentState, ui: { ...currentState.ui, showOtherIndustries: true } }));
  };

  const api = {
    handleNextClick,
    handleBackClick,
    setOnboardData,
    canProceedNextStep,
    isSkippableStep,
    showOtherIndustries,
    logAbTestAction
  };

  return (
    <EpOnboardingContext.Provider
      value={{ state, api }}
    >
      {children}
    </EpOnboardingContext.Provider>
  );
};

EpOnboardingProvider.propTypes = {
  user: shape({}).isRequired,
  children: element,
  onboardingData: shape({})
};

EpOnboardingProvider.defaultProps = {
  children: null,
  onboardingData: {
    usageTier: null,
    industry: null,
    company: null,
    company_size: null,
    jobRole: null,
    managementLevel: null,
    progress: 0
  }
};

export default EpOnboardingProvider;
export const useEpOnboarding = () => useContext(EpOnboardingContext);
