import React from 'react';
import cx from 'classnames';
import {
  bool, string, func, number
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { MENU_TYPES } from '@jotforminc/constants';
import { GrowthAssetManager } from '@jotforminc/growth-asset-manager';

import SignMenuType from '../MenuTypes/SignMenuType';
import SupportMenuType from '../MenuTypes/SupportMenuType';
import DefaultMenuType from '../MenuTypes/DefaultMenuType';
import ProductsMenuType from '../MenuTypes/ProductsMenuType';
import PdfEditorMenuType from '../MenuTypes/PdfEditorMenuType';
import TemplatesMenuType from '../MenuTypes/TemplatesMenuType';
import IntegrationsMenuType from '../MenuTypes/IntegrationsMenuType';

import NavLink from './NavLink';
import { isSamePath } from '../../utils';
import NavItemBadge from './NavItemBadge';
import { linkPropType, menuPropType, userPropType } from '../../propTypes';

const menuTypes = {
  submenu: DefaultMenuType,
  templates: TemplatesMenuType,
  integrations: IntegrationsMenuType,
  products: ProductsMenuType,
  support: SupportMenuType,
  sign: SignMenuType,
  pdfEditor: PdfEditorMenuType
};

const NavItem = ({
  user,
  isPricing,
  isLoggedIn,
  openedMenu,
  currentPath,
  isEnterprise,
  setOpenedMenu,
  logHeaderAction,
  showAiAgentBanner,
  isMobileMenuActive,
  unreadSupportAnswerCount,
  linkProps,
  menuProps,
  onMobileMenuClose,
  hasAnyItemMatchingWithCurrentPath,
  isAcademyAppsCourseReleased
}) => {
  const {
    title, url, className, target, menuType, renderAs, icon, badge, selected
  } = linkProps;

  const MenuComponent = menuTypes[menuType];

  const showRedDot = isLoggedIn && unreadSupportAnswerCount > 0 && menuType === MENU_TYPES.SUPPORT && !isEnterprise;
  const isOpen = openedMenu === title;

  const defaultClassname = 'jfRHeader--nav-menu-item';
  const combinedClassname = cx(defaultClassname, {
    hasSubMenu: menuType,
    isBadge: !!badge,
    isPricing
  });
  const linkClassname = cx({
    isOpen,
    isCurrent: isSamePath(url, currentPath, selected, hasAnyItemMatchingWithCurrentPath),
    [className]: className
  });

  const handleClick = () => {
    setTimeout(() => logHeaderAction({ action: 'headerMenu-click', target: title, project: 'jfHeaderCTA' }), 0);
    setOpenedMenu(prev => (prev === title ? '' : title));

    if (url) {
      try {
        const targetUrl = new URL(url);
        // handle anchor links
        if (isMobileMenuActive && !!targetUrl.hash && currentPath === targetUrl.pathname) {
          onMobileMenuClose();
          setTimeout(() => {
            // eslint-disable-next-line @jotforminc/no-location-href-assignment
            window.location.href = targetUrl.hash;
          }, 10);
        }
      } catch (error) {
        console.error('click-link-error', error);
      }
    }
  };

  const handleMenuClose = () => {
    if (!isMobileMenuActive) {
      setOpenedMenu('');
    }
  };

  const getUrlWithUtms = () => {
    if (isPricing && user?.campaign?.utm_campaign && user.campaign.utm_campaign !== 'NONE') {
      const utmCampaign = user.campaign.utm_campaign;
      const utmSource = `${user.campaign.utm_campaign}-pricing-nav`;
      const utmMedium = 'website';
      const utmContent = currentPath !== '/' ? currentPath.replace(/\//g, '') : 'homepage';
      return `${url}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}&utm_campaign=${utmCampaign}`;
    }
    return url;
  };

  return (
    <li
      className={combinedClassname}
      onMouseLeave={handleMenuClose}
    >
      <NavLink
        url={getUrlWithUtms()}
        target={target}
        renderAs={renderAs}
        menuType={menuType}
        showRedDot={showRedDot}
        classNames={linkClassname}
        onClick={handleClick}
      >
        {isPricing && <GrowthAssetManager assetType="pricing-badge" />}
        {icon && <img className="jfRHeader--nav-menu-item-icon" src={icon} alt={title} />}
        <span className='locale'>{t(title)}</span>
        {badge && <NavItemBadge text={badge.text} />}
      </NavLink>
      {menuType && (
      <MenuComponent
        {...menuProps}
        isOpen={isOpen}
        isLoggedIn={isLoggedIn}
        currentPath={currentPath}
        isEnterprise={isEnterprise}
        showAiAgentBanner={showAiAgentBanner}
        isMobileMenuActive={isMobileMenuActive}
        unreadSupportAnswerCount={unreadSupportAnswerCount}
        isAcademyAppsCourseReleased={isAcademyAppsCourseReleased}
      />
      )}
    </li>
  );
};

NavItem.propTypes = {
  user: userPropType,
  isPricing: bool,
  isLoggedIn: bool,
  openedMenu: string.isRequired,
  currentPath: string.isRequired,
  isEnterprise: bool.isRequired,
  setOpenedMenu: func.isRequired,
  logHeaderAction: func,
  showAiAgentBanner: bool.isRequired,
  isMobileMenuActive: bool,
  unreadSupportAnswerCount: number,
  linkProps: linkPropType.isRequired,
  menuProps: menuPropType,
  onMobileMenuClose: func.isRequired,
  hasAnyItemMatchingWithCurrentPath: bool.isRequired,
  isAcademyAppsCourseReleased: string
};

NavItem.defaultProps = {
  user: undefined,
  isPricing: false,
  isLoggedIn: undefined,
  logHeaderAction: f => f,
  isMobileMenuActive: false,
  unreadSupportAnswerCount: 0,
  isAcademyAppsCourseReleased: ''
};

export default NavItem;
