export const AUTOMATION = {
  // segmented modals
  EDUCATION_MODAL: 'segmentedEducationModal', // old: educationModal
  EDUCATION_MODAL_ALT: 'segmentedEducationModalAlt', // old: educationModalAlt
  FIELD_SERVICE_MANAGEMENT_MODAL: 'segmentedFieldServiceManagementModal', // old: fieldServiceManagementModal
  GOVERNMENT_MODAL: 'segmentedGovernmentModal', // old: governmentModal
  HEALTHCARE_MODAL: 'segmentedHealthcareModal', // old: healthcareModal
  NONPROFIT_MODAL: 'segmentedNonprofitModal', // old: nonprofitModal
  // other modals
  AU_DATA_RESIDENCY_MODAL: 'australianDataResidencyModal',
  HK_DATA_RESIDENCY_MODAL: 'hongKongDataResidencyModal',
  SG_DATA_RESIDENCY_MODAL: 'singaporeDataResidencyModal',
  ENTERPRISE_DISCOUNT_MODAL: 'enterpriseDiscountModal',
  HUBSPOT_MODAL: 'milestoneHubspotModal',
  BLOG_GATED_CONTENT_MODAL: 'blogGatedContentModal'
};
