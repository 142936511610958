import { ASSET_IDENTIFIER } from './assetIdentifier';

const {
  FOLDER_LIMIT_MODAL, HELP_DEDICATED_SUPPORT_MODAL, WHITE_LABELING_MODAL, ASSIGN_TO_ORGANIZATON_MODAL, TEAMS_MODAL, TEAM_CREATION_LIMIT_MODAL, ADMIN_CONSOLE_DARK_MODAL, ENTERPRISE_DATA_CENTERS_MODAL,
  MY_ACCOUNT_USERS_SECTION_WITH_FORM, CONTACT_DEDICATED_SUPPORT_MODAL
} = ASSET_IDENTIFIER.PRODUCT;

// For webinars

// For campaigns

// For AB tests
const {
  EDUCATION_DISCOUNT_MODAL, EDUCATION_DISCOUNT_MODAL_FERPA_VIOLATION_EXAMPLES, SYSTEM_LOGS_MODAL,
  ADD_COLLABORATOR_MODAL, ENTERPRISE_CHECKOUT_LIKE_SECTION, SINGLE_COLUMN_DEDICATED_SUPPORT_MODAL, SCHEDULE_ZOOM_MODAL
} = ASSET_IDENTIFIER.AB_TEST;

// For automation
const { GOVERNMENT_MODAL } = ASSET_IDENTIFIER.AUTOMATION;

export const GA_AD_FORM_TRACKING_ITEMS = {
  // segmented modals
  // todo: update segmented modals selectors
  [GOVERNMENT_MODAL]: [
    { selector: '#input_129', payload: { event: 'contact-sales-click' } },
    { selector: '#text_130 a', payload: { event: 'learn-more-click' } }
  ],
  [EDUCATION_DISCOUNT_MODAL]: [
    { selector: '#input_104', payload: { event: 'contact-sales-click' } }
  ],
  [EDUCATION_DISCOUNT_MODAL_FERPA_VIOLATION_EXAMPLES]: [
    { seletor: '#input_106', payload: { event: 'contact-sales-click' } }
  ],
  [SYSTEM_LOGS_MODAL]: [
    { selector: '#input_107', payload: { event: 'contact-now-click' } }
  ],
  [FOLDER_LIMIT_MODAL]: [
    { selector: '#input_108', payload: { event: 'contact-sales-click' } }, // It has been updated. The old one was "contact-sales-click"
    { selector: '#text_109 a', payload: { event: 'learn-more-click' } }
  ],
  [HELP_DEDICATED_SUPPORT_MODAL]: [
    { selector: '#input_110', payload: { event: 'contact-now-click' } }
  ],
  [WHITE_LABELING_MODAL]: [
    { selector: '#input_111', payload: { event: 'contact-sales-click' } }
  ],
  [ADD_COLLABORATOR_MODAL]: [
    { selector: '#input_112', payload: { event: 'contact-sales-click' } }
  ],
  [ASSIGN_TO_ORGANIZATON_MODAL]: [
    { selector: '#input_113', payload: { event: 'contact-sales-click' } },
    { selector: '#text_114 a', payload: { event: 'learn-more-click' } }
  ],
  [TEAMS_MODAL]: [
    { selector: '#input_115', payload: { event: 'contact-sales-click' } }
  ],
  [TEAM_CREATION_LIMIT_MODAL]: [
    { selector: '#input_116', payload: { event: 'contact-sales-click' } }, // It has been updated. The old one was "contact-sales-click"
    { selector: '#text_117 a', payload: { event: 'learn-more-click' } }
  ],
  [ADMIN_CONSOLE_DARK_MODAL]: [
    { selector: '#input_118', payload: { event: 'contact-sales-click' } },
    { selector: '#text_119 a', payload: { event: 'learn-more-click' } }
  ],
  [ENTERPRISE_DATA_CENTERS_MODAL]: [
    { selector: '#input_121', payload: { event: 'contact-sales-click' } },
    { selector: '#text_120 a', payload: { event: 'learn-more-click' } }
  ],
  [MY_ACCOUNT_USERS_SECTION_WITH_FORM]: [
    { selector: '#input_122', payload: { event: 'contact-sales-click' } }
  ],
  [ENTERPRISE_CHECKOUT_LIKE_SECTION]: [
    { selector: '#input_131', payload: { event: 'send-request-click' } }
  ],
  [CONTACT_DEDICATED_SUPPORT_MODAL]: [
    { selector: '#input_133', payload: { event: 'contact-now-click' } }
  ],
  [SINGLE_COLUMN_DEDICATED_SUPPORT_MODAL]: [
    { selector: '#input_73', payload: { event: 'send-request-click' } }
  ],
  [SCHEDULE_ZOOM_MODAL]: [
    { selector: '#input_196', payload: { event: 'contact-sales-click' } }
  ]
};
