import {
  ComponentPropsWithoutRef,
  PropsWithChildren
} from 'react';

import { Icon } from '../../constants/common.types';

import {
  commonInputDefaults,
  DropdownGroupContextType,
  DropdownOptionType,
  FormControlContextType
} from '../../contexts';

type DropdownOptionNativeProps = Omit<ComponentPropsWithoutRef<'input'> & ComponentPropsWithoutRef<'button'>, 'size'>
type DropdownNativeProps = FormControlContextType & PropsWithChildren<Omit<ComponentPropsWithoutRef<'div'>, 'onChange'>>;
type DropdownGroupNativeProps = Pick<DropdownGroupContextType, 'indent'> & PropsWithChildren<ComponentPropsWithoutRef<'div'>>;

export type DropdownOptionProps = DropdownOptionType & DropdownOptionNativeProps

export type DropdownProps = {
  onChange?: ((options: string | string[] | number | number[]) => void)
  value?: string | string[] | number | number[],
  placeholderIcon?: Icon,
  searchable?: boolean | string,
  noResultFoundText?: string,
  multiple?: boolean
  expand?: boolean,
  ghost?: boolean,
  showCountOnly?: ((count: number) => string) | boolean
} & DropdownNativeProps;

export type DropdownGroupProps = {
  icon?: Icon
  id: string,
  title: string
} & DropdownGroupNativeProps & DropdownGroupContextType;

export const dropdownDefaultProps:Partial<DropdownProps> = commonInputDefaults;
