import React, { useEffect } from 'react';
import { handleCustomNavigation } from '@jotforminc/utils';
import { func } from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  ASSET_IDENTIFIER, ALL_TEXTS, CTA_URLS, SDR_SOURCES,
  GA_SUFFIXES, createGaAttributes, saveCustomerAsHubspotFormByUsername, setEPActions
} from '@jotforminc/ep-utils';
import '../../../styles/dedicatedSupportSection.scss';

const DedicatedSupportSection = ({ logAbTestAction }) => {
  const { PRODUCT: { DEDICATED_SUPPORT_SECTION: target } } = ASSET_IDENTIFIER;
  const {
    DEDICATED_SUPPORT_SECTION: { CONTACT_SALES_URL }
  } = CTA_URLS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesCs = createGaAttributes(target, GA_SUFFIXES.CONTACT_SALES);

  useEffect(() => {
    setEPActions({ asset: target, target, action: 'seen' });
  }, [logAbTestAction]);

  const handleContactSalesClick = async e => {
    e.preventDefault();

    try {
      await setEPActions({ asset: target, target: 'contactSalesButton', action: 'click' });
      await saveCustomerAsHubspotFormByUsername({ lastProductAssetInteraction: SDR_SOURCES.SUPPORT_MENU });
    } catch (error) {
      console.log('Something went wrong with setting ep actions or saving to hubspot.');
    } finally {
      handleCustomNavigation(CONTACT_SALES_URL, '_self', true);
    }
  };

  return (
    <div
      {...gaAttributes}
      className="dedicated-support-outline"
    >
      <div className="dedicated-support-visual">
        <span className="label-text">
          {translationRenderer('[1[Dedicated]] Support')({
            renderer1: text => <span className='dedicated'>{text}</span>
          })}
        </span>
      </div>
      <div className="dedicated-support-content">
        <p className="dedicated-support-text">{t(ALL_TEXTS.DEDICATED_SUPPORT_SECTION_DESCRIPTION)}</p>
        <a
          {...gaAttributesCs}
          className="dedicated-support-cta"
          href={CONTACT_SALES_URL}
          onClick={handleContactSalesClick}
        >
          {t(ALL_TEXTS.CONTACT_SALES)}
        </a>
      </div>
    </div>
  );
};

DedicatedSupportSection.propTypes = {
  logAbTestAction: func
};

DedicatedSupportSection.defaultProps = {
  logAbTestAction: f => f
};

export default DedicatedSupportSection;
