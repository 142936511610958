/* eslint-disable array-callback-return */
import React from 'react';
import { array, bool } from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { IconChevronRight } from '@jotforminc/svg-icons';
import { TEXTS } from '../../constants';
import NavItemBadge from '../Navigation/NavItemBadge';

const IntegrationsMenuType = ({ menuItems, isOpen }) => {
  const IntegrationsMenuTypeClasses = cx('jfRHeader--nav-sub-menu jfIntegrations', {
    isOpen
  });

  return (
    <>
      {menuItems.length > 0 && (
      <div className={IntegrationsMenuTypeClasses}>
        <ul className="jfSub-menu">
          {menuItems.map(({
            name, title, subTitle, seeMore, subLinks
          }) => (
            <li key={name} className="jfRHeader--nav-sub-menu-item">
              <button
                type='button'
                className={`locale ${name}`}
              >
                {t(title)}
              </button>
              <ul>
                <li className="jfRHeader--nav-sub-menu-text">
                  {t(subTitle)}
                </li>
                {subLinks.map(item => {
                  return (
                    <li className="jfRHeader--nav-sub-menu-item" key={item.name}>
                      <a href={item.url} className="jfRHeader--nav-sub-menu-item-link">
                        <img
                          alt={t(item.alt ? item.alt : item.title)}
                          data-src={item.icon} // image will be lazy laoded
                          className="jfRHeader--nav-sub-menu-item-icon"
                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        />
                        <span className='locale'>{t(item.title)}</span>
                        {item.badge && <NavItemBadge text={item.badge.text} />}
                      </a>
                    </li>
                  );
                })}
                <li className="jfRHeader--nav-sub-menu-item" key={seeMore.title}>
                  <a href={seeMore.url} className="jfRHeader--nav-sub-menu-item-link moreLink">
                    <span className="locale">{t(seeMore.title)}</span>
                    <IconChevronRight />
                  </a>
                </li>
              </ul>
            </li>
          ))}
          <li className="jfRHeader--nav-sub-menu-item">
            <a href="/integrations/" className="jfRHeader--nav-sub-menu-item-link moreLink">
              <span className="locale">{t(TEXTS.SEE_100_INTEGRATIONS)}</span>
              <IconChevronRight />
            </a>
          </li>
        </ul>
      </div>
      )}

    </>
  );
};

IntegrationsMenuType.propTypes = {
  menuItems: array.isRequired,
  isOpen: bool.isRequired
};

export default IntegrationsMenuType;
