import { customRedirectionOnLogIn, customRedirectionOnSignUp } from './customRedirects';
import { loadLoginFlow } from './loadLoginFlow';
import { ActionManager } from '../ActionManager';
import { LOGIN_FLOW_ACTIONS_PROJECT_NAME } from '../../constants';

export const openLoginFlow = async ({
  page,
  tracking,
  greetingMessage,
  greetingDescription,
  extraModalProps = {}
}) => {
  let screen;
  let prevRoute;

  const trackLoginFlow = new ActionManager({ user: global.window.user, projectName: LOGIN_FLOW_ACTIONS_PROJECT_NAME }).registerJotformAction;

  const loginFlowProps = {
    mode: 'modal',
    greetingDescription,
    greetingMessage,
    user: global.window.user,
    showLogoOnSignupOptions: false,
    includeConfirm: true,
    forceUser: true,
    noDefaultWrapper: true,
    appleSigninEnabled: true,
    appName: 'jfHeader',
    shrinkConfirmOnMobile: true,
    isReact17: true,
    onUserLogin: (user, { newUser }) => {
      const action = newUser ? 'signup' : 'login';
      trackLoginFlow(action, tracking ?? screen);
      return newUser ? customRedirectionOnSignUp() : customRedirectionOnLogIn();
    },
    onNavigationChange: nextRoute => {
      if (!prevRoute || ['signupOptions', 'loginOptions', 'signupWithEmail'].includes(prevRoute)) {
        if (nextRoute === 'signupOptions') {
          screen = 'login-modal-signup-link';
          trackLoginFlow('open', tracking ?? screen);
        } else if (nextRoute === 'loginOptions') {
          screen = 'signup-modal-login-link';
          trackLoginFlow('open', tracking ?? screen);
        }
      }

      if (nextRoute === 'signupWithEmail') {
        screen = 'signupWithEmail';
        trackLoginFlow('open', tracking ?? screen);
      }

      prevRoute = nextRoute;
    },
    onGoogleLoginClick: () => trackLoginFlow('open', 'google'),
    onFBLoginClick: () => trackLoginFlow('open', 'facebook'),
    onAppleLoginClick: () => trackLoginFlow('open', 'apple'),
    ...extraModalProps
  };

  const setButtonNames = type => ({
    microsoft: `modal-microsoft-${type}-jfheader`,
    google: `modal-google-${type}-jfheader`,
    facebook: `modal-facebook-${type}-jfheader`,
    apple: `modal-apple-${type}-jfheader`,
    emailLogin: 'modal-standart-login-button',
    emailSignup: 'modal-header-createmyaccount'
  });

  switch (page) {
    case 's1':
      screen = 'signup';
      loginFlowProps.initialScreen = 'signupWithSocialOpts';
      loginFlowProps.buttonNames = setButtonNames('signup');
      break;
    case 's2':
      screen = 'login';
      loginFlowProps.forceLogin = 'true';
      loginFlowProps.buttonNames = setButtonNames('login');
      break;
    case 's3':
      screen = 'signupWithEmail';
      loginFlowProps.initialScreen = 'signupWithEmail';
      loginFlowProps.buttonNames = setButtonNames('signup');
      break;
    case 's3b':
      screen = 'signupWithSocialOpts';
      loginFlowProps.showFormOnSignupOptions = true;
      loginFlowProps.initialScreen = screen;
      loginFlowProps.buttonNames = setButtonNames('signup');
      break;
    default:
      loginFlowProps.initialScreen = page;
  }

  loginFlowProps.swsoTestVariant = true;

  const loginFlowHelper = await loadLoginFlow();
  loginFlowHelper.init(loginFlowProps);
  setTimeout(() => trackLoginFlow({ action: 'open', target: tracking ?? screen }), 0);
};
