import React, { useEffect, useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import {
  func, bool, object, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import {
  PrefilledLeadFormAside, ASSET_IDENTIFIER, ALL_TEXTS, userPropType, GA_FORM_TRACKING_ITEMS, GA_AD_FORM_TRACKING_ITEMS,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, setEPActions, mapCallbacksToGaTrackingItems, hasAdwordsModeOn
} from '@jotforminc/ep-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import '../../../../styles/pdfDownloadModal.scss';

const PdfDownloadModal = ({
  user,
  onClose,
  pdfUrl,
  modalProps: {
    iconUrl,
    title,
    description
  }
}) => {
  const uikitModalRef = useRef(null);

  const { PRODUCT: { PDF_DOWNLOAD_MODAL: target } } = ASSET_IDENTIFIER;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  const isAdForm = hasAdwordsModeOn();
  let { [target]: gaFormTrackingItems } = isAdForm ? GA_AD_FORM_TRACKING_ITEMS : GA_FORM_TRACKING_ITEMS;

  useEffect(() => {
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleCloseClick = () => {
    uikitModalRef.current?.hide();

    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);
    setEPActions({ asset: target, target, action: 'close' });

    onClose();
  };

  const handleGetPdfNowClick = () => {
    setEPActions({ asset: target, target: 'getPdfNowButton', action: 'click' });
  };

  const handleFormSubmit = () => {
    setEPActions({ asset: target, target, action: 'formSubmit' });
    handleCustomNavigation(pdfUrl);
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleGetPdfNowClick]);

  return (
    <div className='form-visible'>
      <Modal
        ref={uikitModalRef}
        defaultVisible={true}
        closeOnEscPress={true}
        closeOnOutsideClick={true}
        DialogRenderer={DialogRenderer}
        ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
        HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
        onModalClose={handleClose}
      >
        <div className="row">
          <div className='form-wrapper'>
            <div className='header'>
              <img src={iconUrl} alt={title} className="visual" />
              <div>
                <span className='form-title'>{title}</span>
                <span className='form-description'>{description}</span>
              </div>
            </div>
            <div className='modal-content'>
              <PrefilledLeadFormAside
                user={user}
                formID={242663181689972}
                devFormID={242742102875960}
                gaAttributes={gaAttributes}
                formUiModifier={target}
                gaFormTrackingItems={gaFormTrackingItems}
                title={t(ALL_TEXTS.JOTFORM_ENTERPRISE_TITLE)}
                classNames="w-full border-0 md:min-h-120 md:px-5"
                onFormSubmit={handleFormSubmit}
                isAdForm={isAdForm}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

PdfDownloadModal.propTypes = {
  user: userPropType.isRequired,
  modalProps: object,
  defaultVisible: bool,
  onClose: func,
  logAbTestAction: func,
  iconUrl: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  pdfUrl: string.isRequired
};

PdfDownloadModal.defaultProps = {
  modalProps: {},
  defaultVisible: true,
  onClose: f => f,
  logAbTestAction: f => f
};

export default PdfDownloadModal;
