import React from 'react';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';

export const Questions = ({ title, description }) => (
  <div className='question-wrapper'>
    <h2 className='question-title'>{t(title)}</h2>
    <p className='question-description'>{t(description)}</p>
  </div>
);

Questions.propTypes = {
  title: string.isRequired,
  description: string.isRequired
};
