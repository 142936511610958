import { isGuest, isProduction } from '../jotform';
import { loadByApplyingLazyIgnoredElements } from '../scriptLoaders/loadByApplyingLazyIgnoredElements';
import { createSocialTermsFlow } from './createSocialTermsFlow';
import { customRedirectionOnLogIn, customRedirectionOnSignUp } from './customRedirects';
import { loadLoginFlow } from './loadLoginFlow';

const isOneTapAvailable = user => {
  const isGoogleOneTapPrevented = global.window.preventHeaderGoogleOneTap;
  const hasCustomCta = document.querySelector('.jfRHeader--custom-cta');
  return !isGoogleOneTapPrevented && global.window.GOOGLE_SIGNON && !hasCustomCta && isGuest(user) && isProduction();
};

export const initGoogleOneTap = (event, user) => {
  const logLoginFlowAction = f => f;

  if (!isOneTapAvailable(user)) return;

  loadByApplyingLazyIgnoredElements(event, () => {
    loadLoginFlow(() => {
      global.window.loginFlowHelper.initGoogleOneTap(
        {
          showLogoOnSignupOptions: false,
          renderValidationStepsAsModal: true,
          includeConfirm: true,
          forceUser: true,
          buttonNames: {
            googleOneTap: 'GoogleOnetap'
          },
          bypassDefault2faSocialInit: true,
          ...createSocialTermsFlow(user),
          onUserLogin: (loggedInUser, { newUser }) => {
            setTimeout(() => logLoginFlowAction?.({ action: newUser ? 'signup' : 'login', target: 'google-one-tap' }), 0);
            return newUser ? customRedirectionOnSignUp() : customRedirectionOnLogIn();
          },
          onGoogleOneTapPromptOpened: () => {
            setTimeout(() => logLoginFlowAction?.({ action: 'open', target: 'google-one-tap' }), 0);
          }
        },
        // 2fa callback
        f => f,
        // email auth response callback
        f => f
      );
    });
  });
};
