import { useMemo } from 'react';
import { ActionManager } from '@jotforminc/abtest-manager';
import { PROJECT_NAME } from '../utils';

const useLogAbTestAction = (user, isIncludedInAbTest = false, isDebugMode = false, projectName = PROJECT_NAME) => {
  const logAbTestAction = useMemo(() => {
    if ((!isDebugMode && !isIncludedInAbTest) || !user) return f => f;
    return new ActionManager({
      user,
      projectName,
      enableDebugMode: isDebugMode
    })?.registerJotformAction;
  }, [user, isIncludedInAbTest]);

  return [logAbTestAction];
};

export default useLogAbTestAction;
