export const enabledPages = [
  '/blog/healthcare/',
  '/pdf-templates/e-sign/healthcare',
  '/pdf-templates/healthcare',
  '/table-templates/category/healthcare',
  '/hipaa/templates/',
  '/hipaa/webinar/hipaa-webinar-for-healthcare-providers/',
  '/app-templates/category/healthcare-app-templates',
  '/hipaa/webinar/remote-healthcare-webinar/',
  '/hipaa/webinar/hipaa-compliance-mistakes-webinar/',
  '/what-is-hipaa-compliance/',
  '/form-templates/category/health',
  '/blog/how-to-organize-your-vaccine-distribution-with-jotform/',
  '/approval-templates/category/healthcare',
  '/blog/hipaa-violations/',
  '/hipaa/webinar/managing-vaccine-distribution/',
  '/blog/contactless-screening-for-covid-19/',
  '/blog/shared-decision-making-in-healthcare/',
  '/blog/what-is-a-baa/',
  '/blog/announcing-jotform-health-app/',
  '/form-templates/category/health-coach-forms',
  '/products/approvals/healthcare/',
  '/blog/hippa-compliant/',
  '/form-templates/category/physician-forms',
  '/electronic-signature-for-healthcare/',
  '/form-templates/category/psychologist-forms',
  '/blog/hipaa-compliant-survey-tools/',
  '/form-templates/category/dentist-forms',
  '/form-templates/category/nurse-forms',
  '/form-templates/category/dietitian-forms',
  '/form-templates/category/therapist-forms',
  '/blog/employee-health-screening/',
  '/form-templates/category/esthetician-forms',
  '/form-templates/category/pharmacist-forms',
  '/blog/patients-right-to-access-under-hipaa-webinar/',
  '/home-health-care-forms/',
  '/hipaa/',
  '/hipaa/best-hipaa-compliant-cloud-storage-solutions/',
  '/blog/best-hipaa-compliant-software-products/',
  '/hipaa/features/',
  '/newsletters/announcing-our-new-touchless-mobile-kiosk-and-jotform-health-app/',
  '/blog/best-hipaa-compliant-email-providers/',
  '/blog/when-do-you-need-to-use-hipaa-medical-records-release-form/',
  '/blog/case-study-body-brave/',
  '/blog/jotform-enterprise-one-to-one-health-spotlight/',
  '/enterprise/whitepapers/mastering-your-digital-healthcare-toolkit/',
  '/blog/healthcare-automation/',
  '/blog/healthcare-automation-strategies-webinar/',
  '/blog/case-study-orthoillinois/',
  '/blog/case-study-one-to-one-health/'
];
