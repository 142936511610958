import React from 'react';
import './styles/onboarding.scss';
import { EpOnboardingProvider } from './contexts';
import ContentV2 from './components/Content-V2';
import { AllSteps } from './components';

const OnboardingV2 = props => {
  document.body.classList.add('onboarding-loaded');

  return (
    <EpOnboardingProvider {...props}>
      <div className="allSteps">
        <AllSteps />
        <ContentV2 />
      </div>
    </EpOnboardingProvider>

  );
};

export default OnboardingV2;
