import React, {
  createContext, useContext,
  useMemo
} from 'react';
import { node } from 'prop-types';
import { ActionManager } from '../../utils';
import {
  ACTIONS_PROJECT_NAME, LOGIN_FLOW_ACTIONS_PROJECT_NAME
} from '../../constants';
import { UserContext } from './UserProvider';

export const JotformActionsContext = createContext({
  logHeaderAction: f => f,
  logLoginFlowAction: f => f
});

const JotformActionsProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const { registerJotformAction: logHeaderAction = f => f } = useMemo(() => new ActionManager({ user, projectName: ACTIONS_PROJECT_NAME }), [user]);
  const { registerJotformAction: logLoginFlowAction = f => f } = useMemo(() => new ActionManager({ user, projectName: LOGIN_FLOW_ACTIONS_PROJECT_NAME }), [user]);

  return (
    <JotformActionsContext.Provider value={{
      logHeaderAction,
      logLoginFlowAction
    }}
    >
      {children}
    </JotformActionsContext.Provider>
  );
};

JotformActionsProvider.propTypes = {
  children: node
};

JotformActionsProvider.defaultProps = {
  children: null
};

export default JotformActionsProvider;
