import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

export const setUserSettings = (username, data) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return layer.post(`user/${username}/settings`, formData);
};

export const deleteUserSetting = key => {
  return layer.delete(`user/settings/${key}`);
};
