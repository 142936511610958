export const attachObsoleteSignupLoadingFunctionToWindow = () => {
  const bannerSignupLoading = document.querySelector('.auth-section .signup-loading');
  const signupModalSignupLoading = document.querySelector('.modalpage.s1 .signup-loading');
  const loginModalLoading = document.querySelector('.modalpage.s2 .signup-loading');

  global.window.signupLoaderTimeout = null;

  global.window.handleSignupLoading = eventTarget => {
    if (!eventTarget) return;

    clearTimeout(global.window.signupLoaderTimeout);

    const parentClasses = eventTarget.parentNode?.className || '';
    const grandParentClasses = eventTarget.parentNode?.parentNode?.parentNode?.classList;

    if (parentClasses.includes('auth-section')) {
      bannerSignupLoading.style.display = 'block';
    } else if (grandParentClasses?.contains('s1')) {
      signupModalSignupLoading.style.display = 'block';
    } else if (grandParentClasses?.contains('s2')) {
      loginModalLoading.style.display = 'block';
    } else if (eventTarget.classList.contains('js-naked-social-signup')) {
      document.querySelectorAll('.signup-loading').forEach(el => {
        const elRef = el;
        elRef.style.display = 'block';
      });
    }
  };
};
