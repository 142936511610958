import React from 'react';
import { string, func } from 'prop-types';

import { t } from '@jotforminc/translation';

const defaultAvatar = 'https://cdn.jotfor.ms/assets/img/v4/avatar/Podo-Guest.png';

const AccountBoxAvatar = ({
  avatarUrl, onAccountBoxToggle, username
}) => {
  return (
    <div className="jfRHeader--nav-account-box">
      <div className="jfRHeader--nav-account-box-avatar">
        <button
          type="button"
          aria-label={t('Account Box')}
          onClick={onAccountBoxToggle}
        >
          <img
            src={avatarUrl}
            alt={username ? `User Profile: ${username}` : 'User Profile'}
            className={avatarUrl === defaultAvatar ? 'dummy' : ''}
          />
        </button>
      </div>
    </div>
  );
};

AccountBoxAvatar.propTypes = {
  avatarUrl: string,
  onAccountBoxToggle: func,
  username: string
};

AccountBoxAvatar.defaultProps = {
  avatarUrl: defaultAvatar,
  onAccountBoxToggle: f => f,
  username: undefined
};

export default AccountBoxAvatar;
