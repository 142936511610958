export const loadAndInsertAsFirstScript = (id, src, onload) => {
  if (document.getElementById(id)) {
    onload();
    return false;
  }

  const js = document.createElement('script');

  js.id = id;
  js.onload = onload;
  js.src = src;
  const fjs = document.getElementsByTagName('script')[0];
  if (fjs) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    document.getElementsByTagName('head')[0].appendChild(js);
  }
};
