import { isDevelopment } from './jotform';

export class ActionManager {
  constructor({
    user,
    projectName,
    trackOnce
  }) {
    this.user = user || {};
    this.projectName = projectName;
    this.trackOnce = trackOnce || false;
    this.JotFormActions = null;
    this.registerJotformAction = this.registerJotformAction.bind(this);
    this.sentEvents = {};
    this.loadScript();
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      if (document.querySelector('#jotform-actions-script')) return;

      const headEl = global.window.document.querySelector('head');
      const scriptEl = global.window.document.createElement('script');
      scriptEl.setAttribute('id', 'jotform-actions-script');
      scriptEl.src = 'https://js.jotform.com/actions.js';
      scriptEl.onload = resolve;
      scriptEl.onerror = reject;
      headEl.appendChild(scriptEl);
    });
  }

  setupJotFormActions() {
    const { JotFormActions } = global;
    if (typeof JotFormActions !== 'function') return;
    this.JotFormActions = JotFormActions(this.projectName);
  }

  registerJotformAction({ action, target } = {}) {
    if (!this.JotFormActions) {
      this.setupJotFormActions();
    }

    if (this.trackOnce && this.sentEvents[`${action}${target}`]) {
      return;
    }

    const { username: actor = null } = this.user;

    if (!this.JotFormActions || !actor) return;

    if (isDevelopment()) {
      console.log({ action, target });
    }

    this.JotFormActions.tick({ actor, action, target });

    if (this.trackOnce) {
      this.sentEvents[`${action}${target}`] = true;
    }
  }
}

export default ActionManager;
